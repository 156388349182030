// MUI
import { Grid, RadioGroup, Radio, FormControlLabel } from "@mui/material";

// React
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// CFarm Components
import CFTypography from "components/CFTypography";
import { updateLoading } from "store/reducers/mask";
import { popupError } from "utils/generic";
import { post } from "utils/api";
import { AGROFORESTRY_GET_REPORT } from "constants/api";

// Local Components
import CFTabView from "components/CFTabView";
import AgroforestryParcelTable from "./AgroforestryParcelTable";
import AgroforestryParcelGraph from "./AgroforestryParcelGraph";
import AgroforestryProjectGraph from "./AgroforestryProjectGraph";

function AgroforestryReport({ reportInfo, setReport }) {
  // User info
  const currentUser = useSelector((state) => state.user);

  const dispatch = useDispatch();

  // Fetch report data on initial render
  const [agroforestryReport, setAgroforestryReport] = useState([]);
  const fetchAgroforestryReport = () => {
    dispatch(updateLoading({ loading: true }));

    post(AGROFORESTRY_GET_REPORT, {
      UserId: currentUser.id,
      ScenarioName: "Baseline",
    })
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          dispatch(updateLoading({ loading: false }));
        } else {
          const reportGridData = JSON.parse(res.data.reportGridData);
          setAgroforestryReport(reportGridData);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateLoading({ loading: false }));
      })
      .finally(() => {
        dispatch(updateLoading({ loading: false }));
      });
  };
  useEffect(() => {
    if (reportInfo && reportInfo.length > 0) {
      setAgroforestryReport(reportInfo);
    } else fetchAgroforestryReport();
  }, []);

  // Overall project total emissions
  const ComputeProjectTotals = () => {
    if (agroforestryReport && agroforestryReport.length > 0) {
      const tempTotals = [];
      const isScenario = !!agroforestryReport[0].scenariodata;

      const currentTotal = {
        yearField: "currentYear",
        total: 0,
      };
      const tenTotal = {
        yearField: "tenYear",
        total: 0,
      };
      const twentyTotal = {
        yearField: "twentyYear",
        total: 0,
      };
      const thirtyTotal = {
        yearField: "thirtyYear",
        total: 0,
      };
      const fortyTotal = {
        yearField: "fortyYear",
        total: 0,
      };
      const fiftyTotal = {
        yearField: "fiftyYear",
        total: 0,
      };
      const emissionsYearly = {
        yearField: "emissionsYearly",
        total: 0,
      };

      if (isScenario) {
        // Iterate through each scenario
        agroforestryReport.forEach((scenario) => {
          // Iterate through each species
          scenario.scenariodata.forEach((species) => {
            // Current
            if (!species.invalidspeciesyear) {
              currentTotal.total += species.currentTotal;
            }
            // Ten
            if (!species.invalidspeciesyearTen) {
              tenTotal.total += species.futureTenTotal;
            }
            // Twenty
            if (!species.invalidspeciesyearTwenty) {
              twentyTotal.total += species.futureTwentyTotal;
            }
            // Thirty
            if (!species.invalidspeciesyearThirty) {
              thirtyTotal.total += species.futureThirtyTotal;
            }
            // Forty
            if (!species.invalidspeciesyearForty) {
              fortyTotal.total += species.futureFortyTotal;
            }
            // Fifty
            if (!species.invalidspeciesyearFifty) {
              fiftyTotal.total += species.futureFiftyTotal;
            }
            // Emissions Change
            if (
              !species.invalidspeciesyear &&
              !species.invalidspeciesyearFifty
            ) {
              const emissions =
                (species.futureFiftyTotal - species.currentTotal) / 50;
              emissionsYearly.total += emissions;
            }
          });
        });
      } else {
        // Iterate through each species
        agroforestryReport.forEach((species) => {
          // Current
          if (!species.invalidspeciesyear) {
            currentTotal.total += species.year2015.value;
          }
          // Ten
          if (!species.invalidspeciesyearTen) {
            tenTotal.total += species.year2025.value;
          }
          // Twenty
          if (!species.invalidspeciesyearTwenty) {
            twentyTotal.total += species.year2035.value;
          }
          // Thirty
          if (!species.invalidspeciesyearThirty) {
            thirtyTotal.total += species.year2045.value;
          }
          // Forty
          if (!species.invalidspeciesyearForty) {
            fortyTotal.total += species.year2055.value;
          }
          // Fifty
          if (!species.invalidspeciesyearFifty) {
            fiftyTotal.total += species.year2065.value;
          }
          // Emissions Change
          if (!species.invalidspeciesyear && !species.invalidspeciesyearFifty) {
            const emissions =
              (species.year2065.value - species.year2015.value) / 50;
            emissionsYearly.total += emissions;
          }
        });
      }

      tempTotals.push(currentTotal);
      tempTotals.push(tenTotal);
      tempTotals.push(twentyTotal);
      tempTotals.push(thirtyTotal);
      tempTotals.push(fortyTotal);
      tempTotals.push(fiftyTotal);
      tempTotals.push(emissionsYearly);

      return tempTotals;
    }

    return [];
  };
  const [projectTotals, setProjectTotals] = useState(ComputeProjectTotals());
  useEffect(() => {
    setProjectTotals(ComputeProjectTotals());

    // Update top level if report data is complete and new
    if (
      agroforestryReport &&
      agroforestryReport.length > 0 &&
      reportInfo !== agroforestryReport
    ) {
      setReport(agroforestryReport);
    }
  }, [agroforestryReport]);

  // Graph/Dual display swap
  const [display, setDisplay] = useState("dual");

  // Display tabs
  function CreateTabs() {
    const reportData =
      reportInfo && reportInfo.length > 0 ? reportInfo : agroforestryReport;

    const tempTabs = { TabsList: [] };

    if (reportData && reportData.length > 0) {
      reportData.forEach((parcel) => {
        tempTabs.TabsList.push({
          Tab: parcel.speciesname,
          Data: (
            <Grid py={0.5} px={2}>
              <Grid>
                <AgroforestryParcelTable
                  parcel={parcel}
                  projectTotals={projectTotals}
                />
              </Grid>
              <Grid>
                <AgroforestryParcelGraph parcel={parcel} />
              </Grid>
            </Grid>
          ),
        });
      });
    }

    if (tempTabs.TabsList.length === 0) {
      const defaultTab = {
        Tab: "Agroforestry Report Data",
        Data: (
          <Grid container direction="column" columns={1} alignItems="center">
            Loading...
          </Grid>
        ),
      };
      tempTabs.TabsList.push(defaultTab);
    }

    return tempTabs;
  }
  const [agroforestryTabs, setTabs] = useState(CreateTabs());
  useEffect(() => {
    setTabs(CreateTabs);
  }, [display, reportInfo, agroforestryReport]);

  return (
    <Grid container direction="column">
      {/* Title */}
      <Grid item container direction="column" alignItems="start" pl={1}>
        <Grid container direction="row" alignItems="center">
          <Grid container direction="row" alignItems="center" py={2}>
            <Grid item xs={8}>
              <CFTypography variant="h4">
                Agroforestry Report Summary
              </CFTypography>
            </Grid>
            <Grid
              item
              xs={4}
              container
              direction="row"
              justifyContent="end"
              alignItems="center"
            >
              <CFTypography variant="h6" fontWeight="regular" pr={2}>
                Report Display:
              </CFTypography>
              <RadioGroup
                defaultValue="dual"
                row
                onChange={(e) => setDisplay(e.target.value)}
              >
                <FormControlLabel
                  value="dual"
                  control={<Radio />}
                  label="Dual"
                />
                <FormControlLabel
                  value="graph"
                  control={<Radio />}
                  label="Graph"
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>
        {/* Units message */}
        <Grid pb={1} container direction="row" justifyContent="space-between">
          <CFTypography variant="h6" fontWeight="regular">
            {`Scenario Location: ${
              reportInfo && reportInfo.length > 0
                ? reportInfo[0].scenarioLocation
                : "Loading..."
            }`}
          </CFTypography>
          <CFTypography variant="h6" fontWeight="regular" fontStyle="italic">
            All estimates are presented in terms of metric tonnes of CO
            <sub>2</sub>-equivalent over the indicated decade in the fifty-year
            scenario period.
          </CFTypography>
        </Grid>
      </Grid>
      {/* Data table - we always render this even if it's hidden for the file download */}
      <Grid
        m={1}
        maxWidth="99%"
        width="100%"
        visibility={display === "dual" ? "" : "hidden"}
        height={display === "dual" ? "" : "0px"}
      >
        <CFTabView verbose>{agroforestryTabs}</CFTabView>
      </Grid>
      {display !== "dual" && (
        // Graph Selection and Display
        <Grid m={1} maxWidth="99%" width="100%" item>
          <AgroforestryProjectGraph agroforestryData={agroforestryReport} />
        </Grid>
      )}
    </Grid>
  );
}
export default AgroforestryReport;
