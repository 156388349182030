/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Card } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CFarmPage from "components/CFarmParts/CFarmPage";
import CFInput from "components/CFInput";
import CFButton from "components/CFButton";
import CFBox from "components/CFBox";
import CFTypography from "components/CFTypography";
import { submitLogin } from "actions/auth";
import { getHelperText, getErrorState } from "utils/auth";
import CFLink from "components/CFLink/CFLink";
import RecapchaDisclosure from "components/CFarmParts/CFarmPage/CFarmBottomBar/CFarmSocialMediaBottomBox/RecapchaDisclosure";
import { useLocation } from "react-router-dom";
import styles from "./Login.module.css";

function capitalizeFirstLetter(str) {
  if (typeof str !== "string" || str.length === 0) {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default function Login() {
  const dispatch = useDispatch();
  const validationState = useSelector((state) => state.validation.validations);
  const location = useLocation();
  const redirect = location?.state?.path;

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: e.target.email.value,
      password: e.target.password.value,
      redirect,
    };

    dispatch(submitLogin(data));
  };

  const helperText = (field) =>
    getHelperText(validationState, field) ||
    getHelperText(validationState, capitalizeFirstLetter(field));
  const errorState = (field) =>
    getErrorState(validationState, field) ||
    getErrorState(validationState, capitalizeFirstLetter(field));

  return (
    <CFarmPage>
      <CFBox className="cf-barn-bg ">
        <Card
          component="form"
          onSubmit={handleSubmit}
          autoComplete="off"
          className={styles.loginCardWrapper}
        >
          <div style={{ textAlign: "center" }}>
            <CFTypography variant="h2">Login</CFTypography>
            <CFTypography variant="text">Sign in to COMET-Farm</CFTypography>
          </div>
          {["email", "password"].map((field) => (
            <LoginInput
              key={`login_input_field_${field}`}
              field={field}
              helperText={helperText(field)}
              error={errorState(field)}
            />
          ))}
          <CFButton type="submit" label="Login" fullWidth />
          <div className="cf-flex">
            <CFLink label="Forgot Password?" to="/ForgotPassword" />
            <CFLink label="Create an Account" to="/register" />
          </div>
          <RecapchaDisclosure />
        </Card>
      </CFBox>
    </CFarmPage>
  );
}

function LoginInput({ field, ...rest }) {
  return (
    <CFInput
      id={field}
      name={field}
      type={field}
      label={capitalizeFirstLetter(field)}
      placeholder={capitalizeFirstLetter(field)}
      {...rest}
      fullWidth
    />
  );
}
