// React
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// MUI
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

// CFarm
import { fetchUser } from "actions/user";
import CFarmPage from "components/CFarmParts/CFarmPage";
import CFRoadmap from "components/CFarmParts/CFarmPage/CFRoadmap/Roadmap";
import CFButton from "components/CFButton";
import CFDialog from "components/CFDialog";
import {
  selectedActivities,
  getContinueText,
  HandleContinue,
} from "components/CFarmParts/CFarmPage/CFRoadmap/roadmap.config";

// Post
import {
  FORESTRY_FETCH_PROJECT,
  FORESTRY_CHECK_INCOMPLETE,
} from "constants/api";
import { updateLoading } from "store/reducers/mask";
import { popupError, isDemo } from "utils/generic";
import { post } from "utils/api";

// Forestry
import CFTypography from "components/CFTypography";
import StandManagement from "./StandManagement";

function Forestry() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user);
  const projectActivities = selectedActivities();
  const forestryId = 14;
  const [continueText, setContinueText] = useState("Continue");

  // Project changed flag
  const [projectChangedFlag, raiseProjectChangedFlag] = useState(true);

  // Project object, fetch when flag is raised
  const [forestryOperation, setForestryOperation] = useState({
    OperationStands: [],
  });
  const fetchForestryOperation = () => {
    dispatch(updateLoading({ loading: true }));

    post(FORESTRY_FETCH_PROJECT, {
      UserId: currentUser.id,
    })
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          dispatch(updateLoading({ loading: false }));
        } else {
          setForestryOperation({
            OperationStands: res.data.standLocations.map((location) => ({
              Id: location.id,
              Area: location.area,
              CountyName: location.countyName,
              StateCode: location.stateCode,
              ZipCode: location.zipCode,
              StandName: location.standName,
              LocationDetails: {
                LocationId: location.detailsModel.locationId,
                ForestType: {
                  Id: location.detailsModel.forestTypeId,
                  Name: location.detailsModel.forestTypeName,
                },
                Origin: {
                  Id: location.detailsModel.originId,
                  Name: location.detailsModel.originName,
                },
                StandAge: location.detailsModel.standAge,
                Volume: location.detailsModel.volume,
                MerchVolume: location.detailsModel.merchantableVolume,
                Size: location.detailsModel.size,
                Prescription: {
                  Id: location.detailsModel.prescriptionId,
                  Name: location.detailsModel.prescriptionName,
                },
                ActivityYears: location.detailsModel.activityYears,
              },
            })),
          });
          dispatch(fetchUser(currentUser));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateLoading({ loading: false }));
      })
      .finally(() => {
        raiseProjectChangedFlag(false);
        dispatch(updateLoading({ loading: false }));
      });
  };
  useEffect(() => {
    if (projectChangedFlag) {
      dispatch(fetchUser(currentUser));
      fetchForestryOperation();
    }
  }, [projectChangedFlag]);

  // Make sure all stands have info and run report
  const [localPopupState, updateLocalPopup] = useState({});
  const VerifyAndRunReport = () => {
    dispatch(updateLoading({ loading: true }));

    post(FORESTRY_CHECK_INCOMPLETE, {
      UserId: currentUser.id,
    })
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          dispatch(updateLoading({ loading: false }));
        } else if (res.data.length > 0) {
          updateLocalPopup({
            title: "Incomplete Management Information",
            children: [
              <Grid p={2} key={1}>
                <CFTypography variant="body2" fontWeight="regular">
                  The following stands are missing management information.
                  Please provide management details before continuing to the
                  report.
                </CFTypography>
                <Grid container direction="column" alignItems="center" pt={1}>
                  {res.data.map((stand) => (
                    <CFTypography
                      variant="body2"
                      color="secondary"
                      fontWeight="regular"
                      key={stand.Id}
                    >
                      {stand.standName}
                    </CFTypography>
                  ))}
                </Grid>
              </Grid>,
            ],
            showPopup: true,
            closeAction: () => {
              updateLocalPopup({ showPopup: false });
            },
          });
        } else {
          HandleContinue(projectActivities, navigate, forestryId);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateLoading({ loading: false }));
      })
      .finally(() => {
        dispatch(updateLoading({ loading: false }));
      });
  };
  useEffect(() => {
    if (projectActivities.length > 0) {
      setContinueText(getContinueText(projectActivities, forestryId));
    }
  }, [projectActivities]);

  // Demo info popup
  const [demoPopupShown, setDemoPopupShown] = useState(false);
  useEffect(() => {
    if (isDemo(14, currentUser) && !demoPopupShown) {
      setDemoPopupShown(true);
      updateLocalPopup({
        title: "Forestry Demo Project",
        style: {
          minWidth: "750px",
          maxHeight: "75vh",
          overflow: "auto",
        },
        children: [
          <Grid container direction="column" alignItems="center" p={2}>
            <CFTypography variant="h4" py={1}>
              Stand Management
            </CFTypography>
            <p>
              Information on each forest stand is entered. West and East Big
              Meadow are composed of Douglas-fir and Joe&apos;s Cut is composed
              of ponderosa pine. East Big Meadow is managed as a reserve, hence
              a prescription of no management activity, whereas the other two
              have clear cut prescriptions with their rotations described by
              activity years.
            </p>
          </Grid>,
        ],
        showPopup: true,
        closeAction: () => {
          updateLocalPopup({ showPopup: false });
        },
      });
    }
  }, [currentUser.activeProject]);

  return (
    <CFarmPage bgImage="" pageName="">
      {/* Roadmap */}
      <CFRoadmap />
      <Grid container direction="column" mb="5%" mt="1%" sx={{ width: "75%" }}>
        {/* Management Info */}
        <StandManagement
          managementTitle="Stand Management"
          forestryOp={forestryOperation}
          projectChanged={raiseProjectChangedFlag}
        />
        {/* Run Report */}
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          py={3}
        >
          <CFButton
            onClick={() => navigate("/map-forestry")}
            variant="gradient"
            color="secondary"
          >
            Back to Field Locations
          </CFButton>
          <CFButton
            onClick={VerifyAndRunReport}
            variant="gradient"
            color="progress"
          >
            {continueText}
          </CFButton>
        </Grid>
      </Grid>

      {localPopupState && localPopupState.showPopup && (
        <CFDialog
          {...localPopupState}
          updatePopup={(e) => updateLocalPopup(e)}
        />
      )}
    </CFarmPage>
  );
}
export default Forestry;
