// React
import { useEffect, useState } from "react";
import PropTypes, { array } from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// MUI
import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import colors from "assets/theme/base/colors";
import CFInput from "components/CFInput";
// Our Components
import CFBox from "components/CFBox";
import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";
import { updateLoading } from "store/reducers/mask";
import { post } from "utils/api";
import { popupError } from "utils/generic";
import { FETCH_HOUSING, UPDATE_HOUSING_SYSTEM_DETAILS } from "constants/api";
import CFLookupPicker from "components/CroplandsComponents/CFLookupPicker/CFLookupPicker";
import CFLoading from "components/CFLoading";
import { fontWeight, letterSpacing } from "@mui/system";
import { getTextInput } from "./AnimalAgCommonFunc";

// Constants

export default function AnimalHousing({
  id,
  yearValue,
  groupName,
  animalCategory,
  animalCategoryId,
  groupId,
  anagObject,
  popFlag,
  setPopFlag,
  fdlotPopFlag,
  setFdlotPopFlag,
  sheepPopFlag,
  setSheepPopFlag,
  housingChanged,
  verifyDone,
}) {
  const dispatch = useDispatch();

  const { info } = colors;
  const empty = { id: 0, name: "select" };
  const [loading, setLoading] = useState(false);
  const [freeStallCount, setFreeStallCount] = useState([]);
  const [pitStorageCount, setPitStorageCount] = useState([]);
  const [beddedPackCount, setBeddedPackCount] = useState([]);
  const [compostingCount, setCompostingCount] = useState([]);
  const [dryLotCount, setDryLotCount] = useState([]);
  const [pastureRangeCount, setPastureRangeCount] = useState([]);

  const [strgeMonthsList, setStrgeMonthsList] = useState([]);

  const [strgeTimeList, setStrgeTimeList] = useState([]);

  // const [monthsArr, setMonthsArr] = useState([]);
  const [freeStall1Area, setFreeStall1Area] = useState("");
  const [freeStall2Area, setFreeStall2Area] = useState("");
  const [freeStall3Area, setFreeStall3Area] = useState("");

  const [barnTemp1, setBarnTemp1] = useState("");
  const [barnTemp2, setBarnTemp2] = useState("");
  const [barnTemp3, setBarnTemp3] = useState("");

  const [strgeTime, setStrgeTime] = useState(empty);

  const [strgeTimeId, setStrgeTimeId] = useState(empty);

  const [coveredBarnCount, setCoveredBarnCount] = useState("1");

  const [strgeMonthId, setStrgeMonthId] = useState(empty);

  const [strgeMonth, setStrgeMonth] = useState(empty);

  const [pitStorage, setPitStorage] = useState(212450);
  const [pitStorageList, setPitStorageList] = useState([]);
  const [beddedPack, setBeddedPack] = useState(212450);

  const [beddedPackList, setBeddedPackList] = useState([]);

  const [saveSuccessMsg, setSaveSuccessMsg] = useState(false);

  const [primaryGrazing, setPrimaryGrazing] = useState(212450);
  const [primaryGrazingLst, setPrimaryGrazingLst] = useState([]);
  const defaultMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [monthsArr, setMonthsArr] =
    animalCategoryId === 21283 || animalCategoryId === 21284
      ? useState(["Jan"])
      : useState(defaultMonths);

  const freeStallDataExists = (dataObj) => {
    let fStallExists = false;
    const cvdBarnCount =
      dataObj !== undefined ? dataObj.coveredBarnCount : coveredBarnCount;
    const freeStallArea1 =
      dataObj !== undefined ? dataObj.freeStall1Area : freeStall1Area;
    const freeStallArea2 =
      dataObj !== undefined ? dataObj.freeStall2Area : freeStall2Area;
    const freeStallArea3 =
      dataObj !== undefined ? dataObj.freeStall3Area : freeStall3Area;
    const barn1Temp = dataObj !== undefined ? dataObj.barnTemp1 : barnTemp1;
    const barn2Temp = dataObj !== undefined ? dataObj.barnTemp1 : barnTemp2;
    const barn3Temp = dataObj !== undefined ? dataObj.barnTemp1 : barnTemp3;

    if (cvdBarnCount > 0) {
      if (cvdBarnCount === 1 || cvdBarnCount === "1") {
        if (freeStallArea1 > 0 && barn1Temp > 0) {
          fStallExists = true;
        }
      } else if (cvdBarnCount === 2 || cvdBarnCount === "2") {
        if (
          freeStallArea1 > 0 &&
          freeStallArea2 > 0 &&
          barn1Temp > 0 &&
          barn2Temp > 0
        ) {
          fStallExists = true;
        }
      } else if (cvdBarnCount === 3 || cvdBarnCount === "3") {
        if (
          freeStallArea1 > 0 &&
          freeStallArea2 > 0 &&
          freeStallArea3 > 0 &&
          barn1Temp > 0 &&
          barn2Temp > 0 &&
          barn3Temp > 0
        ) {
          fStallExists = true;
        }
      }
    }
    return fStallExists;
  };
  const detailsExists = () => {
    // if barn count exists check if any more additional details are required before enabling the save button
    let isValid = false;
    const freeStallExists =
      Object.values(freeStallCount).filter((x) => x > 0).length > 0;
    const pitStorageExists =
      Object.values(pitStorageCount).filter((x) => x > 0).length > 0;
    const beddedPackExists =
      Object.values(beddedPackCount).filter((x) => x > 0).length > 0;

    // not details for below barns, but still need to check this to enable save button

    const compostingExists =
      Object.values(compostingCount).filter((x) => x > 0).length > 0;
    const dryLotExists =
      Object.values(dryLotCount).filter((x) => x > 0).length > 0;

    const pastureRangeExists =
      Object.values(pastureRangeCount).filter((x) => x > 0).length > 0;

    const housingArr = [];
    if (freeStallExists) {
      if (freeStallDataExists() === true) {
        housingArr.push(true);
      } else {
        housingArr.push(false);
      }
    }
    if (pitStorageExists) {
      if (strgeTimeId !== 212450) {
        housingArr.push(true);
      } else {
        housingArr.push(false);
      }
    }
    if (beddedPackExists) {
      if (strgeMonthId !== 212450 && beddedPack !== 212450) {
        housingArr.push(true);
      } else {
        housingArr.push(false);
      }
    }
    if (pastureRangeExists) {
      if (animalCategoryId === 21281) {
        if (primaryGrazing !== 212450) {
          housingArr.push(true);
        } else {
          housingArr.push(false);
        }
      } else {
        housingArr.push(true);
      }
    }
    // not details for below barns, but still need to check this to enable save button

    if (compostingExists || dryLotExists) {
      housingArr.push(true);
    }

    isValid =
      housingArr.length === 0 ? false : !housingArr.some((x) => x === false);

    return isValid;
  };
  const invalidPercent = () => {
    // will have to fix this.. because doing this way means we have to enter housing data for all the months which is not always true.
    let totalPercentExists = false;
    const totalPercentArr = monthsArr.map(
      (month) =>
        Number(freeStallCount[`freeStall${month}`]) +
        Number(pitStorageCount[`pitStorage${month}`]) +
        Number(beddedPackCount[`beddedPack${month}`]) +
        Number(compostingCount[`composting${month}`]) +
        Number(dryLotCount[`dryLot${month}`]) +
        Number(pastureRangeCount[`pastureRange${month}`])
    );

    totalPercentExists = totalPercentArr.some((total) => total !== 100);

    return totalPercentExists;
  };

  const swineInvalidPercent = () => {
    let totalPercentExists = false;
    const totalPercentVal =
      Number(freeStallCount[`freeStall${"Jan"}`]) +
      Number(pitStorageCount[`pitStorage${"Jan"}`]) +
      Number(beddedPackCount[`beddedPack${"Jan"}`]) +
      Number(compostingCount[`composting${"Jan"}`]) +
      Number(dryLotCount[`dryLot${"Jan"}`]) +
      Number(pastureRangeCount[`pastureRange${"Jan"}`]);

    totalPercentExists = totalPercentVal !== 100;

    return totalPercentExists;
  };

  const calculateInvalidPercent = (catId) => {
    const totalPercentExists =
      catId === 21283 || catId === 21284
        ? swineInvalidPercent()
        : invalidPercent();

    return totalPercentExists;
  };
  const allDetailsExists = (catId) => {
    // logic to enable disbale save button for housing
    let percentAndDetailsExists = false;
    const totalPercentExists = calculateInvalidPercent(animalCategoryId);
    const animalDetailsExists = detailsExists(catId);
    if (animalDetailsExists && !totalPercentExists) {
      percentAndDetailsExists = true;
    }

    return percentAndDetailsExists;
  };

  const handleFreeStall1Area = (e) => {
    setFreeStall1Area(e.target.value);
  };
  const handleFreeStall2Area = (e) => {
    setFreeStall2Area(e.target.value);
  };
  const handleFreeStall3Area = (e) => {
    setFreeStall3Area(e.target.value);
  };

  const handleBarnTemp1Change = (e) => {
    setBarnTemp1(e.target.value);
  };
  const handleBarnTemp2Change = (e) => {
    setBarnTemp2(e.target.value);
  };
  const handleBarnTemp3Change = (e) => {
    setBarnTemp3(e.target.value);
  };
  const handleFreeStallChange = (e) => {
    const value = getTextInput(e.target.value);
    setFreeStallCount({
      ...freeStallCount,
      [e.target.name]: value,
    });
  };
  const handleCoveredBarnCount = (e) => {
    if (e.target.value === "0") {
      setCoveredBarnCount("1");
    } else {
      setCoveredBarnCount(e.target.value);
    }
  };
  const copyFreeStall = () => {
    if (monthsArr.length > 0) {
      const janFreeStall = getTextInput(
        freeStallCount[`freeStall${monthsArr[0]}`]
      );

      const freeStallMonthsArr = [];
      monthsArr.map((month) => freeStallMonthsArr.push([`freeStall${month}`]));
      const freeStallNewObj = {};
      freeStallMonthsArr.forEach((mon) => {
        freeStallNewObj[mon] = janFreeStall;
      });

      setFreeStallCount({ ...freeStallCount, ...freeStallNewObj });
    }
  };
  const freeStallCountNotZero = () => {
    let freeStallExists = false;
    const freeStallArr = monthsArr.map(
      (month) => freeStallCount[`freeStall${month}`] > 0
    );
    freeStallExists = freeStallArr.includes(true);

    return freeStallExists;
  };

  const swinePitStorageCountNotZero = () => {
    let pitStorageDetailExists = false;

    pitStorageDetailExists = pitStorageCount.pitStorageJan > 0;

    return pitStorageDetailExists;
  };

  const pitStorageCountNotZero = () => {
    let pitStorageExists = false;
    const pitStorageArr = monthsArr.map(
      (month) => pitStorageCount[`pitStorage${month}`] > 0
    );

    pitStorageExists = pitStorageArr.includes(true);

    return pitStorageExists;
  };
  const swineBeddedPackCountNotZero = () => {
    let beddedPackDetailExists = false;

    beddedPackDetailExists = beddedPackCount.beddedPackJan > 0;

    return beddedPackDetailExists;
  };
  const beddedPackCountNotZero = () => {
    let beddedPackExists = false;
    const beddedPackArr = monthsArr.map(
      (month) => beddedPackCount[`beddedPack${month}`] > 0
    );
    beddedPackExists = beddedPackArr.includes(true);

    return beddedPackExists;
  };
  const pastureCountNotZero = () => {
    let pastureExists = false;
    const pastureArr = monthsArr.map(
      (month) => pastureRangeCount[`pastureRange${month}`] > 0
    );
    pastureExists = pastureArr.includes(true);

    return pastureExists;
  };
  const compostCountNotZero = () => {
    let compostExists = false;
    const compostArr = monthsArr.map(
      (month) => compostingCount[`composting${month}`] > 0
    );
    compostExists = compostArr.includes(true);

    return compostExists;
  };

  const pitStorageNotZero = () => {
    let pitStorageDetailsExists = false;
    if (animalCategoryId === 21283 || animalCategoryId === 21284) {
      pitStorageDetailsExists = swinePitStorageCountNotZero();
    } else {
      pitStorageDetailsExists = pitStorageCountNotZero();
    }

    return pitStorageDetailsExists;
  };

  const beddedPackNotZero = () => {
    let beddedPackDetailsExists = false;
    if (animalCategoryId === 21283 || animalCategoryId === 21284) {
      beddedPackDetailsExists = swineBeddedPackCountNotZero();
    } else {
      beddedPackDetailsExists = beddedPackCountNotZero();
    }

    return beddedPackDetailsExists;
  };

  const handlePitStorageChange = (e) => {
    const value = getTextInput(e.target.value);
    setPitStorageCount({
      ...pitStorageCount,
      [e.target.name]: value,
    });
  };
  const copyPitStorage = () => {
    if (monthsArr.length > 0) {
      const janPitStorage = getTextInput(
        pitStorageCount[`pitStorage${monthsArr[0]}`]
      );

      const pitStorageMonthsArr = [];
      monthsArr.map((month) =>
        pitStorageMonthsArr.push([`pitStorage${month}`])
      );
      const pitStorageNewObj = {};
      pitStorageMonthsArr.forEach((mon) => {
        pitStorageNewObj[mon] = janPitStorage;
      });

      setPitStorageCount({ ...pitStorageCount, ...pitStorageNewObj });
    }
  };
  const handleBeddedPackChange = (e) => {
    const value = getTextInput(e.target.value);

    setBeddedPackCount({
      ...beddedPackCount,
      [e.target.name]: value,
    });
  };
  const copyBeddedPack = () => {
    if (monthsArr.length > 0) {
      const janBeddedPack = getTextInput(
        beddedPackCount[`beddedPack${monthsArr[0]}`]
      );

      const beddedPackMonthsArr = [];
      monthsArr.map((month) =>
        beddedPackMonthsArr.push([`beddedPack${month}`])
      );
      const beddedPackNewObj = {};
      beddedPackMonthsArr.forEach((mon) => {
        beddedPackNewObj[mon] = janBeddedPack;
      });

      setBeddedPackCount({ ...beddedPackCount, ...beddedPackNewObj });
    }
  };
  const handleCompostingChange = (e) => {
    const value = getTextInput(e.target.value);
    setCompostingCount({
      ...compostingCount,
      [e.target.name]: value,
    });
  };
  const copyComposting = () => {
    if (monthsArr.length > 0) {
      const janComposting = getTextInput(
        compostingCount[`composting${monthsArr[0]}`]
      );

      const compostingMonthsArr = [];
      monthsArr.map((month) =>
        compostingMonthsArr.push([`composting${month}`])
      );
      const compostingNewObj = {};
      compostingMonthsArr.forEach((mon) => {
        compostingNewObj[mon] = janComposting;
      });

      setCompostingCount({ ...compostingCount, ...compostingNewObj });
    }
  };

  const handleDryLotChange = (e) => {
    const value = getTextInput(e.target.value);
    setDryLotCount({
      ...dryLotCount,
      [e.target.name]: value,
    });
  };
  const copyDryLot = () => {
    if (monthsArr.length > 0) {
      const janDryLot = getTextInput(dryLotCount[`dryLot${monthsArr[0]}`]);

      const dryLotMonthsArr = [];
      monthsArr.map((month) => dryLotMonthsArr.push([`dryLot${month}`]));
      const dryLotNewObj = {};
      dryLotMonthsArr.forEach((mon) => {
        dryLotNewObj[mon] = janDryLot;
      });

      setDryLotCount({ ...dryLotCount, ...dryLotNewObj });
    }
  };

  const handlePastureRangeChange = (e) => {
    const value = getTextInput(e.target.value);
    setPastureRangeCount({
      ...pastureRangeCount,
      [e.target.name]: value,
    });
  };

  const copyPastureRange = () => {
    if (monthsArr.length > 0) {
      const janPastureRange = getTextInput(
        pastureRangeCount[`pastureRange${monthsArr[0]}`]
      );

      const pastureRangeMonthsArr = [];
      monthsArr.map((month) =>
        pastureRangeMonthsArr.push([`pastureRange${month}`])
      );
      const pastureRangeNewObj = {};
      pastureRangeMonthsArr.forEach((mon) => {
        pastureRangeNewObj[mon] = janPastureRange;
      });

      setPastureRangeCount({ ...pastureRangeCount, ...pastureRangeNewObj });
    }
  };

  const handleBeddedPackOptionsChange = (event) => {
    setBeddedPack(event.target.value);
  };

  const getPrimaryGrazing = (anagCat) => {
    let grazingText = "";
    grazingText =
      anagCat === 21268 ? "Sheeps are primarily grazing" : "Grazing Landscape:";
    return grazingText;
  };

  const handlePrimaryGrazingChange = (event) => {
    setPrimaryGrazing(event.target.value);
  };

  const getPitStorageInput = (month) => (
    <CFInput
      sx={{
        fieldset: {
          borderColor: info.dullFocus,
          borderStyle: "solid",
          borderWidth: "2px",
        },
      }}
      InputLabelProps={{
        shrink: true,
      }}
      type="number"
      color="primary"
      id={`pitStorageCount${month}`}
      name={`pitStorage${month}`}
      value={pitStorageCount[`pitStorage${month}`]}
      InputProps={{
        inputProps: {
          min: 0,
          max: 100,
          style: { padding: "10px 5px 10px 7px" },
        },
      }}
      defaultValue="0"
      onChange={handlePitStorageChange}
      placeholder=""
      fullWidth
    />
  );
  const getBeddedPackInput = (month) => (
    <CFInput
      sx={{
        fieldset: {
          borderColor: info.dullFocus,
          borderStyle: "solid",
          borderWidth: "2px",
        },
      }}
      type="number"
      color="primary"
      id={`beddedPackCount${month}`}
      name={`beddedPack${month}`}
      defaultValue="0"
      InputProps={{
        inputProps: {
          min: 0,
          max: 100,
          style: { padding: "10px 5px 10px 7px" },
        },
      }}
      value={beddedPackCount[`beddedPack${month}`]}
      onChange={handleBeddedPackChange}
      placeholder=""
      fullWidth
    />
  );
  const getCompostInput = (month) => (
    <CFInput
      sx={{
        padding: "10px",
        fieldset: {
          borderColor: info.dullFocus,
          borderStyle: "solid",
          borderWidth: "2px",
        },
      }}
      type="number"
      color="primary"
      id={`compostingCount${month}`}
      name={`composting${month}`}
      defaultValue="0"
      InputProps={{
        inputProps: {
          min: 0,
          max: 100,
          style: { padding: "10px 5px 10px 7px" },
        },
      }}
      value={compostingCount[`composting${month}`]}
      onChange={handleCompostingChange}
      placeholder=""
      fullWidth
    />
  );
  const getPastureRangeInput = (month) => (
    <CFInput
      sx={{
        fieldset: {
          borderColor: info.dullFocus,
          borderStyle: "solid",
          borderWidth: "2px",
        },
      }}
      InputProps={{
        inputProps: {
          min: 0,
          max: 100,
          style: { padding: "10px 5px 10px 7px" },
        },
      }}
      type="number"
      color="primary"
      id={`pastureRangeCount${month}`}
      name={`pastureRange${month}`}
      defaultValue="0"
      value={pastureRangeCount[`pastureRange${month}`]}
      onChange={handlePastureRangeChange}
      placeholder=""
      fullWidth
    />
  );
  const getMonths = (month) => (
    <CFTypography
      sx={{
        fontSize: "1.2em",

        color: info.dullFocus,
        fontWeight: "bold",
        padding: "9.5px",
      }}
    >
      {month}
    </CFTypography>
  );
  const getTotals = (month) => (
    <CFBox sx={{ display: "flex", marginTop: "1em" }}>
      <CFTypography
        sx={{
          fontSize: "16px",
          color: info.dullFocus,
          fontWeight: "bold",

          marginBottom: "0.5em",
          marginTop: "1em",
        }}
      >
        {Number(freeStallCount[`freeStall${month}`]) +
          Number(pitStorageCount[`pitStorage${month}`]) +
          Number(beddedPackCount[`beddedPack${month}`]) +
          Number(compostingCount[`composting${month}`]) +
          Number(dryLotCount[`dryLot${month}`]) +
          Number(pastureRangeCount[`pastureRange${month}`])}
      </CFTypography>

      <CFTypography
        sx={{
          fontSize: "16px",
          color: info.dullFocus,
          fontWeight: "bold",
          marginRight: "1.1em",
          marginBottom: "0.5em",
          marginTop: "1em",
        }}
      >
        %
      </CFTypography>
    </CFBox>
  );
  const fetchHousingPopulation = () => {
    setLoading(true);
    const popObj = {
      popid: id,
    };
    post(FETCH_HOUSING, popObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const housingBarnObj = res.data.housingBarnTypes;

          const freeStallMonthsJson = [];
          const pitStorageMonthsJson = [];
          const beddedPackMonthsJson = [];
          const compostingMonthsJson = [];
          const dryLotMonthsJson = [];
          const pastureRangeMonthsJson = [];
          const freeStallNewJson = {};
          const pitStorageNewJson = {};
          const beddedPackNewJson = {};
          const compostingNewJson = {};
          const dryLotNewJson = {};
          const pastureRangeNewJson = {};
          let freeStallExists = false;
          let beddedPackExists = false;
          let pitStorageExists = false;
          let compostExists = false;
          let dryLotExists = false;
          let pastureRangeExists = false;
          let pastureRangeCatExists = false;

          let freeStallDetailExists = false;
          let pitStorageDetailsExists = false;
          let beddedPackDetailsExists = false;

          housingChanged(false);

          if (
            animalCategoryId === 21260 ||
            animalCategoryId === 21261 ||
            animalCategoryId === 21262
          ) {
            setMonthsArr(res.data.dairyMonths);
          }
          if (animalCategoryId === 21247) {
            setMonthsArr(res.data.feedlotMonths);
          }
          if (animalCategoryId === 21267) {
            setMonthsArr(res.data.sheepMonths);
          }

          monthsArr.map((month) =>
            freeStallMonthsJson.push([`freeStall${month}`])
          );
          monthsArr.map((month) =>
            pitStorageMonthsJson.push([`pitStorage${month}`])
          );
          monthsArr.map((month) =>
            beddedPackMonthsJson.push([`beddedPack${month}`])
          );
          monthsArr.map((month) =>
            compostingMonthsJson.push([`composting${month}`])
          );
          monthsArr.map((month) => dryLotMonthsJson.push([`dryLot${month}`]));
          monthsArr.map((month) =>
            pastureRangeMonthsJson.push([`pastureRange${month}`])
          );

          freeStallMonthsJson.forEach((mon) => {
            const freeStallMonthName = mon.toString().replace("freeStall", "");
            freeStallNewJson[mon] = housingBarnObj.find(
              (freeStll) =>
                freeStll.month.toString() === freeStallMonthName.toString()
            ).freeStallCount;
          });
          setFreeStallCount({ ...freeStallCount, ...freeStallNewJson });
          freeStallExists = housingBarnObj.some((el) => el.freeStallCount > 0);

          pitStorageMonthsJson.forEach((mon) => {
            const pitStorageMonthName = mon
              .toString()
              .replace("pitStorage", "");
            pitStorageNewJson[mon] = housingBarnObj.find(
              (pitStrge) =>
                pitStrge.month.toString() === pitStorageMonthName.toString()
            ).pitStorageCount;
          });
          setPitStorageCount({ ...pitStorageCount, ...pitStorageNewJson });
          pitStorageExists = housingBarnObj.some(
            (el) => el.pitStorageCount > 0
          );

          beddedPackMonthsJson.forEach((mon) => {
            const beddedPackMonthName = mon
              .toString()
              .replace("beddedPack", "");
            beddedPackNewJson[mon] = housingBarnObj.find(
              (beddedPck) =>
                beddedPck.month.toString() === beddedPackMonthName.toString()
            ).beddedPackCount;
          });

          setBeddedPackCount({ ...beddedPackCount, ...beddedPackNewJson });
          beddedPackExists = housingBarnObj.some(
            (el) => el.beddedPackCount > 0
          );

          compostingMonthsJson.forEach((mon) => {
            const compostingMonthName = mon
              .toString()
              .replace("composting", "");
            compostingNewJson[mon] = housingBarnObj.find(
              (compsting) =>
                compsting.month.toString() === compostingMonthName.toString()
            ).compostingCount;
          });

          setCompostingCount({ ...compostingCount, ...compostingNewJson });
          compostExists = housingBarnObj.some((el) => el.compostingCount > 0);

          dryLotMonthsJson.forEach((mon) => {
            const dryLotMonthName = mon.toString().replace("dryLot", "");
            dryLotNewJson[mon] = housingBarnObj.find(
              (dryLt) => dryLt.month.toString() === dryLotMonthName.toString()
            ).dryLotCount;
          });
          setDryLotCount({ ...dryLotCount, ...dryLotNewJson });
          dryLotExists = housingBarnObj.some((el) => el.dryLotCount > 0);
          pastureRangeMonthsJson.forEach((mon) => {
            const pastureRangeMonthName = mon
              .toString()
              .replace("pastureRange", "");
            pastureRangeNewJson[mon] = housingBarnObj.find(
              (pastureRnge) =>
                pastureRnge.month.toString() ===
                pastureRangeMonthName.toString()
            ).pastureRangeCount;
          });
          setPastureRangeCount({
            ...pastureRangeCount,
            ...pastureRangeNewJson,
          });
          pastureRangeExists = housingBarnObj.some(
            (el) => el.pastureRangeCount > 0
          );
          if (animalCategoryId === 21281) {
            pastureRangeCatExists =
              pastureRangeExists && res.data.primaryGrazingId !== 212450;
          } else {
            pastureRangeCatExists = pastureRangeExists;
          }

          setFreeStall1Area(res.data.freeStall1Area);
          setFreeStall2Area(res.data.freeStall2Area);
          setFreeStall3Area(res.data.freeStall3Area);

          setBarnTemp1(res.data.barnTemp1);
          setBarnTemp2(res.data.barnTemp2);
          setBarnTemp3(res.data.barnTemp3);

          setStrgeMonthId(res.data.storageMonthsId);

          setStrgeTimeId(res.data.storageTimeId);

          setCoveredBarnCount(res.data.coveredBarnCount.toString());

          setPrimaryGrazing(res.data.primaryGrazingId);

          setStrgeMonthsList(res.data.storageMonthList);

          setStrgeTimeList(res.data.storageTimeList);
          setBeddedPackList(res.data.beddedPackTypeList);
          setPitStorageList(res.data.pitStorageTypeList);

          setPrimaryGrazingLst(res.data.primaryGrazingList);

          freeStallDetailExists = freeStallDataExists(res.data);
          pitStorageDetailsExists = res.data.storageTimeId !== 212450;
          beddedPackDetailsExists =
            res.data.storageMonthsId !== 212450 &&
            res.data.beddedPackTypeId !== 212450;

          const sTime = {
            id: res.data.storageTimeId,
            name: res.data.storageTimeName,
          };

          const sMonths = {
            id: res.data.storageMonthsId,
            name: res.data.storageMonthsName,
          };

          setStrgeMonth(sMonths);

          setStrgeTime(sTime);

          setPitStorage(res.data.pitStorageTypeId);
          setBeddedPack(res.data.beddedPackTypeId);

          if (
            (freeStallExists && freeStallDetailExists) ||
            (pitStorageExists && pitStorageDetailsExists) ||
            (beddedPackExists && beddedPackDetailsExists) ||
            compostExists ||
            dryLotExists ||
            pastureRangeCatExists
          ) {
            verifyDone("green");
          } else {
            verifyDone("grey");
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const updateHousing = () => {
    setLoading(true);
    if (Object.entries(pastureRangeCount).every((x) => x[1] === 100)) {
      housingChanged(false);
    } else {
      housingChanged(true);
    }

    const animalTypesObj = {
      freeStallObj: freeStallCount,
      pitStorageObj: pitStorageCount,
      beddedPackObj: beddedPackCount,
      compostingObj: compostingCount,
      dryLotObj: dryLotCount,
      pastureRangeObj: pastureRangeCount,
      freeStall1BarnArea: freeStall1Area,
      freeStall2BarnArea: freeStall2Area,
      freeStall3BarnArea: freeStall3Area,
      barn1Temp: barnTemp1,
      barn2Temp: barnTemp2,
      barn3Temp: barnTemp3,
      noOfCovBarns: coveredBarnCount,
      storageTime: strgeTimeId,
      storageMonth: strgeMonthId,
      pitStorageType: pitStorage,
      beddedPackType: beddedPack,
      pGrazing: primaryGrazing,
      months: monthsArr,
      popid: id,
    };

    post(UPDATE_HOUSING_SYSTEM_DETAILS, animalTypesObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          setSaveSuccessMsg(true);
          // fetchHousingPopulation();
          verifyDone("green");
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (
      animalCategoryId === 21260 ||
      animalCategoryId === 21261 ||
      animalCategoryId === 21262
    ) {
      setPopFlag(false);
    }
    if (animalCategoryId === 21247) {
      setFdlotPopFlag(false);
    }
    if (animalCategoryId === 21267) {
      setSheepPopFlag(false);
    }
    fetchHousingPopulation();
  }, [popFlag, fdlotPopFlag, sheepPopFlag, anagObject.activeAnimalCategories]);

  useEffect(() => {
    allDetailsExists(animalCategoryId);
  }, [
    coveredBarnCount,
    strgeTimeId,
    strgeMonthId,
    beddedPack,
    freeStall1Area,
    freeStall2Area,
    freeStall3Area,
    barnTemp1,
    barnTemp2,
    barnTemp3,
  ]);

  return (
    <CFBox width="100%">
      {loading && <CFLoading local />}
      <Grid container item>
        {/* Main form */}
        <CFBox width="100%">
          <Grid container direction="row" xs={12} sm={12} md={12}>
            <Grid container direction="row">
              {monthsArr.length > 0 && (
                <Grid
                  item
                  container
                  xs={12}
                  id="step2box"
                  display="flex"
                  alignItems="start"
                  justifyContent="start"
                >
                  <CFTypography
                    sx={{
                      fontSize: "0.7em",

                      color: info.dullFocus,

                      marginBottom: "0.7em",
                    }}
                  >
                    Enter the % of time spent in each housing type for each
                    month. This includes time spent in multiple barn types, time
                    spent on dry lot, and time spent on pasture.{" "}
                    <b>
                      The percentage of time spent each month must equal 100%.
                    </b>
                  </CFTypography>
                </Grid>
              )}

              {monthsArr.length > 0 && (
                <Grid item container xs={12} id="step2box" alignItems="center">
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    direction="row"
                  >
                    <Grid item xs={2.5}>
                      <CFTypography
                        sx={{
                          fontSize: "1em",
                          color: info.dullFocus,
                          fontWeight: "bold",
                          marginBottom: "0.5em",
                          marginTop: "0.5em",
                        }}
                      >
                        % Time Spent
                      </CFTypography>
                    </Grid>

                    <Grid item xs={9.5}>
                      <Stack
                        direction="row"
                        justifyContent="space-evenly"
                        gap={0.8}
                        style={{ marginLeft: "0.5em" }}
                      >
                        {animalCategoryId === 21283 ||
                        animalCategoryId === 21284
                          ? getMonths("")
                          : monthsArr.map((month) => getMonths(month))}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {monthsArr.length > 0 &&
                (animalCategoryId === 21260 ||
                  animalCategoryId === 21261 ||
                  animalCategoryId === 21262) && (
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    direction="row"
                  >
                    <Grid item xs={12}>
                      <Tooltip title="Copy data across months">
                        <ArrowCircleRightTwoToneIcon
                          onClick={() => copyFreeStall()}
                          id="copyFreeStall"
                          color="primary"
                          fontSize="medium"
                          style={{
                            cursor: "pointer",
                            marginLeft: "24%",

                            marginTop: "1%",
                          }}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}

              {monthsArr.length > 0 &&
                (animalCategoryId === 21260 ||
                  animalCategoryId === 21261 ||
                  animalCategoryId === 21262) && (
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    direction="row"
                  >
                    <Grid item xs={2.5}>
                      <CFTypography
                        sx={{
                          fontSize: "15px",
                          color: info.dullFocus,
                          fontWeight: "bold",

                          marginBottom: "0.5em",
                          marginTop: "0.5em",
                        }}
                      >
                        Covered Barn (Flushed, Scraped, Vacuum)
                      </CFTypography>
                    </Grid>
                    <Grid item xs={9.5}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}
                        style={{ marginLeft: "3%" }}
                      >
                        {monthsArr.map((month) => (
                          <CFInput
                            sx={{
                              fieldset: {
                                borderColor: info.dullFocus,
                                borderStyle: "solid",
                                borderWidth: "2px",
                              },
                            }}
                            type="number"
                            color="primary"
                            id={`freeStallCount${month}`}
                            name={`freeStall${month}`}
                            defaultValue="0"
                            InputProps={{
                              inputProps: {
                                min: 0,
                                max: 100,
                                style: { padding: "10px 5px 10px 7px" },
                              },
                            }}
                            value={freeStallCount[`freeStall${month}`]}
                            onChange={handleFreeStallChange}
                            placeholder=""
                            fullWidth
                          />
                        ))}
                      </Stack>
                    </Grid>
                  </Grid>
                )}

              {monthsArr.length > 0 &&
                (animalCategoryId === 21260 ||
                  animalCategoryId === 21261 ||
                  animalCategoryId === 21262) && (
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    direction="row"
                  >
                    <Grid item xs={12}>
                      <Tooltip title="Copy data across months">
                        <ArrowCircleRightTwoToneIcon
                          onClick={() => copyPitStorage()}
                          id="copyPitStorage"
                          color="primary"
                          fontSize="medium"
                          style={{
                            cursor: "pointer",
                            marginLeft: "24%",

                            marginTop: "1%",
                          }}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
              {monthsArr.length > 0 &&
                (animalCategoryId === 21260 ||
                  animalCategoryId === 21261 ||
                  animalCategoryId === 21262 ||
                  animalCategoryId === 21283 ||
                  animalCategoryId === 21284) && (
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    direction="row"
                  >
                    <Grid item xs={2.5}>
                      <CFTypography
                        sx={{
                          fontSize: "15px",

                          color: info.dullFocus,
                          fontWeight: "bold",

                          marginBottom: "0.5em",
                          marginTop: "0.5em",
                        }}
                      >
                        Covered with Pit Storage
                      </CFTypography>
                    </Grid>

                    <Grid item xs={9.5}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}
                        style={{ marginLeft: "3%" }}
                      >
                        {animalCategoryId === 21283 ||
                        animalCategoryId === 21284
                          ? getPitStorageInput("Jan")
                          : monthsArr.map((month) => getPitStorageInput(month))}
                      </Stack>
                    </Grid>
                  </Grid>
                )}
              {monthsArr.length > 0 &&
                (animalCategoryId === 21260 ||
                  animalCategoryId === 21261 ||
                  animalCategoryId === 21262 ||
                  animalCategoryId === 21247) && (
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    direction="row"
                  >
                    <Grid item xs={12}>
                      <Tooltip title="Copy data across months">
                        <ArrowCircleRightTwoToneIcon
                          onClick={() => copyBeddedPack()}
                          id="copyBeddedPack"
                          color="primary"
                          fontSize="medium"
                          style={{
                            cursor: "pointer",
                            marginLeft: "24%",

                            marginTop: "1%",
                          }}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
              {monthsArr.length > 0 &&
                (animalCategoryId === 21260 ||
                  animalCategoryId === 21261 ||
                  animalCategoryId === 21262 ||
                  animalCategoryId === 21283 ||
                  animalCategoryId === 21284 ||
                  animalCategoryId === 21247) && (
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    direction="row"
                  >
                    <Grid item xs={2.5}>
                      <CFTypography
                        sx={{
                          fontSize: "15px",
                          color: info.dullFocus,
                          fontWeight: "bold",

                          marginBottom: "0.5em",
                          marginTop: "0.5em",
                        }}
                      >
                        Covered with Deep Bedding
                      </CFTypography>
                    </Grid>

                    <Grid item xs={9.5}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}
                        style={{
                          marginLeft: "3%",
                          marginTop:
                            animalCategoryId === 21283 ||
                            animalCategoryId === 21284
                              ? "2%"
                              : "0%",
                        }}
                      >
                        {animalCategoryId === 21283 ||
                        animalCategoryId === 21284
                          ? getBeddedPackInput("Jan")
                          : monthsArr.map((month) => getBeddedPackInput(month))}
                      </Stack>
                    </Grid>
                  </Grid>
                )}
              {monthsArr.length > 0 &&
                (animalCategoryId === 21260 ||
                  animalCategoryId === 21261 ||
                  animalCategoryId === 21262 ||
                  animalCategoryId === 21247) && (
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    direction="row"
                  >
                    <Grid item xs={12}>
                      <Tooltip title="Copy data across months">
                        <ArrowCircleRightTwoToneIcon
                          onClick={() => copyComposting()}
                          id="copyComposting"
                          color="primary"
                          fontSize="medium"
                          style={{
                            cursor: "pointer",
                            marginLeft: "24%",

                            marginTop: "1%",
                          }}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
              {monthsArr.length > 0 &&
                (animalCategoryId === 21260 ||
                  animalCategoryId === 21261 ||
                  animalCategoryId === 21262 ||
                  animalCategoryId === 21283 ||
                  animalCategoryId === 21284 ||
                  animalCategoryId === 21247) && (
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    direction="row"
                  >
                    <Grid item xs={2.5}>
                      <CFTypography
                        sx={{
                          fontSize: "15px",
                          color: info.dullFocus,
                          fontWeight: "bold",

                          marginBottom: "0.5em",
                          marginTop: "0.5em",
                        }}
                      >
                        Covered Compost Barn
                      </CFTypography>
                    </Grid>

                    <Grid item xs={9.5}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}
                        style={{
                          marginLeft: "3%",
                          marginTop:
                            animalCategoryId === 21283 ||
                            animalCategoryId === 21284
                              ? "2%"
                              : "0%",
                        }}
                      >
                        {animalCategoryId === 21283 ||
                        animalCategoryId === 21284
                          ? getCompostInput("Jan")
                          : monthsArr.map((month) => getCompostInput(month))}
                      </Stack>
                    </Grid>
                  </Grid>
                )}

              {monthsArr.length > 0 &&
                (animalCategoryId === 21260 ||
                  animalCategoryId === 21261 ||
                  animalCategoryId === 21262 ||
                  animalCategoryId === 21247 ||
                  animalCategoryId === 21267 ||
                  animalCategoryId === 21268 ||
                  animalCategoryId === 21281) && (
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    direction="row"
                  >
                    <Grid item xs={12}>
                      <Tooltip title="Copy data across months">
                        <ArrowCircleRightTwoToneIcon
                          onClick={() => copyDryLot()}
                          id="copyDryLot"
                          color="primary"
                          fontSize="medium"
                          style={{
                            cursor: "pointer",
                            marginLeft: "24%",
                            marginTop: "1%",
                          }}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}

              {monthsArr.length > 0 &&
                (animalCategoryId === 21260 ||
                  animalCategoryId === 21261 ||
                  animalCategoryId === 21262 ||
                  animalCategoryId === 21247 ||
                  animalCategoryId === 21267 ||
                  animalCategoryId === 21268 ||
                  animalCategoryId === 21281) && (
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    direction="row"
                  >
                    <Grid item xs={2.5}>
                      <CFTypography
                        sx={{
                          fontSize: "15px",
                          color: info.dullFocus,
                          fontWeight: "bold",

                          marginBottom: "0.5em",
                          marginTop: "0.5em",
                        }}
                      >
                        Dry Lot
                      </CFTypography>
                    </Grid>

                    <Grid item xs={9.5}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}
                        style={{ marginLeft: "3%" }}
                      >
                        {monthsArr.map((month) => (
                          <CFInput
                            sx={{
                              padding: "10px",
                              fieldset: {
                                borderColor: info.dullFocus,
                                borderStyle: "solid",
                                borderWidth: "2px",
                              },
                            }}
                            color="primary"
                            id={`dryLotCount${month}`}
                            name={`dryLot${month}`}
                            InputProps={{
                              inputProps: {
                                min: 0,
                                max: 100,
                                style: { padding: "10px 5px 10px 7px" },
                              },
                            }}
                            type="number"
                            defaultValue="0"
                            value={dryLotCount[`dryLot${month}`]}
                            onChange={handleDryLotChange}
                            placeholder=""
                            fullWidth
                          />
                        ))}
                      </Stack>
                    </Grid>
                  </Grid>
                )}
              {monthsArr.length > 0 &&
                !(animalCategoryId === 21283 || animalCategoryId === 21284) && (
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    direction="row"
                  >
                    <Grid item xs={12}>
                      <Tooltip title="Copy data across months">
                        <ArrowCircleRightTwoToneIcon
                          onClick={() => copyPastureRange()}
                          id="copyPastureRange"
                          color="primary"
                          fontSize="medium"
                          style={{
                            cursor: "pointer",
                            marginLeft: "24%",

                            marginTop: "1%",
                          }}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}

              {monthsArr.length > 0 && (
                <Grid
                  item
                  container
                  xs={12}
                  alignItems="center"
                  direction="row"
                >
                  <Grid item xs={2.5}>
                    <CFTypography
                      sx={{
                        fontSize: "15px",
                        color: info.dullFocus,
                        fontWeight: "bold",

                        marginBottom: "0.5em",
                        marginTop: "0.5em",
                      }}
                    >
                      Pasture Range
                    </CFTypography>
                  </Grid>
                  <Grid item xs={9.5}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={1}
                      style={{
                        marginLeft: "3%",
                        marginTop:
                          animalCategoryId === 21283 ||
                          animalCategoryId === 21284
                            ? "2%"
                            : "0%",
                      }}
                    >
                      {animalCategoryId === 21283 || animalCategoryId === 21284
                        ? getPastureRangeInput("Jan")
                        : monthsArr.map((month) => getPastureRangeInput(month))}
                    </Stack>
                  </Grid>
                </Grid>
              )}

              {monthsArr.length > 0 && (
                <Grid
                  item
                  container
                  xs={12}
                  alignItems="center"
                  direction="row"
                >
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      style={{ marginLeft: "23%" }}
                      justifyContent="space-evenly"
                      alignItems="center"
                      gap={1.2}
                    >
                      {animalCategoryId === 21283 || animalCategoryId === 21284
                        ? getTotals("Jan")
                        : monthsArr.map((month) => getTotals(month))}
                    </Stack>
                  </Grid>
                </Grid>
              )}

              {monthsArr.length > 0 &&
                calculateInvalidPercent(animalCategoryId) && (
                  <Grid container xs={12} alignItems="center" direction="row">
                    <Grid item xs={12}>
                      <CFTypography
                        sx={{
                          fontSize: "16px",
                          color: "red",
                          fontWeight: "bold",
                          marginLeft: "20em",
                          marginBottom: "0.5em",
                          marginTop: "1em",
                        }}
                      >
                        The percentage of time spent each month must equal 100%.
                      </CFTypography>
                    </Grid>
                  </Grid>
                )}
            </Grid>
            {freeStallCountNotZero() && (
              <Grid container xs={12} alignItems="center" direction="row">
                <Grid item xs={12}>
                  <CFTypography
                    sx={{
                      fontSize: "20px",
                      color: info.dullFocus,
                      fontWeight: "bold",
                      fontStyle: "italic",
                      marginTop: "3em",
                    }}
                  >
                    Covered Barns (flushed, scraped,vacuum) :
                  </CFTypography>
                </Grid>
              </Grid>
            )}
            {freeStallCountNotZero() && (
              <Grid container xs={12} alignItems="center" direction="row">
                <Grid item xs={9}>
                  <CFTypography
                    sx={{
                      fontSize: "15px",
                      color: info.dullFocus,
                      fontWeight: "bold",

                      marginTop: "1em",
                    }}
                  >
                    Enter the number of Covered Barns (Flushed, Scraped,
                    Vacuumed) {groupName} occupy:
                  </CFTypography>
                </Grid>

                <Grid item xs={1}>
                  <CFInput
                    sx={{
                      fieldset: {
                        borderColor: info.dullFocus,
                        borderStyle: "solid",
                        borderWidth: "2px",
                      },
                    }}
                    style={{ marginTop: "1em" }}
                    defaultValue="1"
                    InputProps={{
                      inputProps: { min: 1, max: 3 },
                    }}
                    type="number"
                    color="primary"
                    id="idCoveredBarnCount"
                    name="coveredBarnCount"
                    value={coveredBarnCount}
                    onChange={handleCoveredBarnCount}
                    placeholder=""
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}
            {freeStallCountNotZero() &&
              (coveredBarnCount === "1" ||
                coveredBarnCount === "2" ||
                coveredBarnCount === "3") && (
                <Grid container xs={12} alignItems="center" direction="row">
                  <Grid item xs={8}>
                    <CFTypography
                      sx={{
                        fontSize: "15px",
                        color: info.dullFocus,
                        fontWeight: "bold",

                        marginTop: "3em",
                      }}
                    >
                      Enter the surface area of Barn 1 floor covered in{" "}
                      {groupName} manure:
                    </CFTypography>
                  </Grid>

                  <Grid item xs={2}>
                    <CFBox style={{ display: "flex" }}>
                      <CFInput
                        sx={{
                          fieldset: {
                            borderColor: info.dullFocus,
                            borderStyle: "solid",
                            borderWidth: "2px",
                          },
                        }}
                        style={{ marginTop: "2em" }}
                        color="primary"
                        id="idfreeStall1Area"
                        name="freeStall1Area"
                        value={freeStall1Area}
                        onChange={handleFreeStall1Area}
                        placeholder=""
                        fullWidth
                      />
                      <CFTypography
                        sx={{
                          fontSize: "15px",
                          color: info.dullFocus,
                          marginRight: "3em",
                          marginLeft: "1em",
                          marginTop: "4em",
                        }}
                        style={{ width: "10%" }}
                      >
                        sqft
                      </CFTypography>
                    </CFBox>
                  </Grid>
                </Grid>
              )}
            {freeStallCountNotZero() &&
              (coveredBarnCount === "1" ||
                coveredBarnCount === "2" ||
                coveredBarnCount === "3") && (
                <Grid container xs={12} alignItems="center" direction="row">
                  <Grid item xs={8}>
                    <CFTypography
                      sx={{
                        fontSize: "15px",
                        color: info.dullFocus,
                        fontWeight: "bold",

                        marginTop: "3em",
                      }}
                    >
                      What is the average daily temperature of Barn 1:
                    </CFTypography>
                  </Grid>

                  <Grid item xs={2}>
                    <CFBox style={{ display: "flex" }}>
                      <CFInput
                        sx={{
                          fieldset: {
                            borderColor: info.dullFocus,
                            borderStyle: "solid",
                            borderWidth: "2px",
                          },
                        }}
                        style={{ marginTop: "2em" }}
                        color="primary"
                        id="idBarnTemp1"
                        name="barnTemp1"
                        value={barnTemp1}
                        onChange={handleBarnTemp1Change}
                        placeholder=""
                        fullWidth
                      />
                      <CFTypography
                        sx={{
                          fontSize: "15px",
                          color: info.dullFocus,

                          marginLeft: "1em",
                          marginTop: "4em",
                        }}
                        style={{ width: "20%" }}
                      >
                        ° F
                      </CFTypography>
                    </CFBox>
                  </Grid>
                </Grid>
              )}

            {freeStallCountNotZero() &&
              (coveredBarnCount === "2" || coveredBarnCount === "3") && (
                <Grid container xs={12} alignItems="center" direction="row">
                  <Grid item xs={8}>
                    <CFTypography
                      sx={{
                        fontSize: "15px",
                        color: info.dullFocus,
                        fontWeight: "bold",

                        marginTop: "3em",
                      }}
                    >
                      Enter the surface area of Barn 2 floor covered in{" "}
                      {groupName} manure:
                    </CFTypography>
                  </Grid>

                  <Grid item xs={2}>
                    <CFBox style={{ display: "flex" }}>
                      <CFInput
                        sx={{
                          fieldset: {
                            borderColor: info.dullFocus,
                            borderStyle: "solid",
                            borderWidth: "2px",
                          },
                        }}
                        style={{ marginTop: "2em" }}
                        color="primary"
                        id="idfreeStall2Area"
                        name="freeStall2Area"
                        value={freeStall2Area}
                        onChange={handleFreeStall2Area}
                        placeholder=""
                        fullWidth
                      />
                      <CFTypography
                        sx={{
                          fontSize: "15px",
                          color: info.dullFocus,
                          marginLeft: "1em",
                          marginTop: "4em",
                        }}
                        style={{ width: "10%" }}
                      >
                        sqft
                      </CFTypography>
                    </CFBox>
                  </Grid>
                </Grid>
              )}
            {freeStallCountNotZero() &&
              (coveredBarnCount === "2" || coveredBarnCount === "3") && (
                <Grid container xs={12} alignItems="center" direction="row">
                  <Grid item xs={8}>
                    <CFTypography
                      sx={{
                        fontSize: "15px",
                        color: info.dullFocus,
                        fontWeight: "bold",

                        marginTop: "3em",
                      }}
                    >
                      What is the average daily temperature of Barn 2:
                    </CFTypography>
                  </Grid>

                  <Grid item xs={2}>
                    <CFBox style={{ display: "flex" }}>
                      <CFInput
                        sx={{
                          fieldset: {
                            borderColor: info.dullFocus,
                            borderStyle: "solid",
                            borderWidth: "2px",
                          },
                        }}
                        style={{ marginTop: "2em" }}
                        color="primary"
                        id="idBarnTemp2"
                        name="barnTemp2"
                        value={barnTemp2}
                        onChange={handleBarnTemp2Change}
                        placeholder=""
                        fullWidth
                      />
                      <CFTypography
                        sx={{
                          fontSize: "15px",
                          color: info.dullFocus,
                          marginLeft: "1em",
                          marginTop: "4em",
                        }}
                        style={{ width: "20%" }}
                      >
                        ° F
                      </CFTypography>
                    </CFBox>
                  </Grid>
                </Grid>
              )}

            {freeStallCountNotZero() && coveredBarnCount === "3" && (
              <Grid container xs={12} alignItems="center" direction="row">
                <Grid item xs={8}>
                  <CFTypography
                    sx={{
                      fontSize: "15px",
                      color: info.dullFocus,
                      fontWeight: "bold",

                      marginTop: "3em",
                    }}
                  >
                    Enter the surface area of Barn 3 floor covered in{" "}
                    {groupName} manure:
                  </CFTypography>
                </Grid>

                <Grid item xs={2}>
                  <CFBox style={{ display: "flex" }}>
                    <CFInput
                      sx={{
                        fieldset: {
                          borderColor: info.dullFocus,
                          borderStyle: "solid",
                          borderWidth: "2px",
                        },
                      }}
                      style={{ marginTop: "2em" }}
                      color="primary"
                      id="idfreeStall3Area"
                      name="freeStall3Area"
                      value={freeStall3Area}
                      onChange={handleFreeStall3Area}
                      placeholder=""
                      fullWidth
                    />
                    <CFTypography
                      sx={{
                        fontSize: "15px",
                        color: info.dullFocus,
                        marginRight: "3em",
                        marginLeft: "1em",
                        marginTop: "4em",
                      }}
                      style={{ width: "10%" }}
                    >
                      sqft
                    </CFTypography>
                  </CFBox>
                </Grid>
              </Grid>
            )}
            {freeStallCountNotZero() && coveredBarnCount === "3" && (
              <Grid container xs={12} alignItems="center" direction="row">
                <Grid item xs={8}>
                  <CFTypography
                    sx={{
                      fontSize: "15px",
                      color: info.dullFocus,
                      fontWeight: "bold",

                      marginTop: "3em",
                    }}
                  >
                    What is the average daily temperature of Barn 3 :
                  </CFTypography>
                </Grid>

                <Grid item xs={2}>
                  <CFBox style={{ display: "flex" }}>
                    <CFInput
                      sx={{
                        fieldset: {
                          borderColor: info.dullFocus,
                          borderStyle: "solid",
                          borderWidth: "2px",
                        },
                      }}
                      style={{ marginTop: "2em" }}
                      color="primary"
                      id="idBarnTemp3"
                      name="barnTemp3"
                      value={barnTemp3}
                      onChange={handleBarnTemp3Change}
                      placeholder=""
                      fullWidth
                    />
                    <CFTypography
                      sx={{
                        fontSize: "15px",
                        color: info.dullFocus,

                        marginLeft: "1em",
                        marginTop: "4em",
                      }}
                      style={{ width: "20%" }}
                    >
                      ° F
                    </CFTypography>
                  </CFBox>
                </Grid>
              </Grid>
            )}

            {pitStorageNotZero() && (
              <Grid container xs={12} alignItems="center" direction="row">
                <Grid item xs={12}>
                  <CFTypography
                    sx={{
                      fontSize: "20px",
                      color: info.dullFocus,
                      fontWeight: "bold",
                      fontStyle: "italic",
                      marginTop: "3em",
                    }}
                  >
                    Covered Barns with Pit Storage :
                  </CFTypography>
                </Grid>
              </Grid>
            )}

            {pitStorageNotZero() && (
              <Grid container xs={12} alignItems="center" direction="row">
                <Grid item xs={5}>
                  <CFTypography
                    sx={{
                      fontSize: "15px",
                      color: info.dullFocus,
                      fontWeight: "bold",

                      marginTop: "3em",
                    }}
                  >
                    Select the Storage Length :
                  </CFTypography>
                </Grid>

                <Grid item xs={2}>
                  <CFLookupPicker
                    key="storageTime"
                    name="storageTime"
                    required
                    style={{ marginTop: "2em" }}
                    value={strgeTime}
                    onChange={(event, newValue) => {
                      setStrgeTimeId(newValue.id);

                      setStrgeTime({
                        name: newValue.name,
                        id: newValue.id,
                      });
                    }}
                    options={strgeTimeList}
                  />
                </Grid>
              </Grid>
            )}

            {beddedPackNotZero() && (
              <Grid container xs={12} alignItems="center" direction="row">
                <Grid item xs={4}>
                  <CFTypography
                    sx={{
                      fontSize: "20px",
                      color: info.dullFocus,
                      fontWeight: "bold",
                      fontStyle: "italic",
                      marginTop: "3em",
                    }}
                  >
                    Covered Barns with Deep Bedding :
                  </CFTypography>
                </Grid>
              </Grid>
            )}

            {beddedPackNotZero() && (
              <Grid container xs={12} alignItems="center" direction="row">
                <Grid item xs={8}>
                  <CFBox
                    width="100%"
                    style={{
                      display: "flex",
                      marginTop: "1em",
                      marginBottom: "1em",
                    }}
                  >
                    <RadioGroup
                      row
                      name="beddedPackSelRB"
                      value={beddedPack}
                      onChange={handleBeddedPackOptionsChange}
                    >
                      {beddedPackList.map((bedpack) => (
                        <FormControlLabel
                          value={bedpack.id}
                          control={<Radio />}
                          label={bedpack.name}
                        />
                      ))}
                    </RadioGroup>
                  </CFBox>
                </Grid>
              </Grid>
            )}

            {beddedPackNotZero() && (
              <Grid container xs={12} alignItems="center" direction="row">
                <Grid item xs={5}>
                  <CFTypography
                    sx={{
                      fontSize: "15px",
                      color: info.dullFocus,
                      fontWeight: "bold",

                      marginTop: "2em",
                    }}
                  >
                    Select the Storage Length :
                  </CFTypography>
                </Grid>

                <Grid item xs={2}>
                  <CFLookupPicker
                    key="storageMonth"
                    name="storageMonth"
                    required
                    style={{ marginTop: "2em" }}
                    value={strgeMonth}
                    onChange={(event, newValue) => {
                      setStrgeMonthId(newValue.id);

                      setStrgeMonth({
                        name: newValue.name,
                        id: newValue.id,
                      });
                    }}
                    options={strgeMonthsList}
                  />
                </Grid>
              </Grid>
            )}
            {animalCategoryId === 21281 && pastureCountNotZero() && (
              <Grid
                item
                container
                xs={12}
                id="step2box"
                display="flex"
                alignItems="start"
                justifyContent="start"
              >
                <CFTypography
                  sx={{
                    fontSize: "0.7em",

                    color: info.dullFocus,
                    marginTop: "1em",
                    marginLeft: "1em",
                    marginBottom: "0.5em",
                  }}
                >
                  Select the landscape that the goats are grazing below.
                </CFTypography>
              </Grid>
            )}

            {animalCategoryId === 21281 && pastureCountNotZero() && (
              <Grid item xs={12}>
                <CFBox
                  sx={{
                    padding: "10px",
                    fontWeight: "bold",
                    display: "flex",
                    marginTop: "1em",
                  }}
                >
                  <CFTypography
                    sx={{
                      fontSize: "14px",
                      marginTop: ".5em",
                      marginRight: "1em",
                      color: info.dullFocus,
                      fontWeight: "bold",
                    }}
                  >
                    {getPrimaryGrazing(animalCategoryId)}
                  </CFTypography>
                  <RadioGroup
                    row
                    name="pmryGrazing"
                    value={primaryGrazing}
                    onChange={handlePrimaryGrazingChange}
                  >
                    {primaryGrazingLst.map((prigrazing) => (
                      <FormControlLabel
                        value={prigrazing.id}
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                                {
                                  color: "dimgray",
                                },
                            }}
                          />
                        }
                        label={prigrazing.name}
                      />
                    ))}
                  </RadioGroup>
                </CFBox>
              </Grid>
            )}

            <Grid container py={2} px={2} justifyContent="space-around">
              <Grid item xs={12} sm={12} md={12}>
                <CFButton
                  type="submit"
                  sx={{
                    float: "right",
                  }}
                  color="progress"
                  onClick={() => {
                    updateHousing();
                  }}
                  disabled={!allDetailsExists(animalCategoryId)}
                >
                  Save
                </CFButton>
              </Grid>
            </Grid>
            {saveSuccessMsg && (
              <Grid container justifyContent="space-around">
                <Grid item xs={2} sm={2} md={2} alignItems="center">
                  <CFBox id="housingSuccessMessage">
                    <CFTypography
                      sx={{
                        fontSize: "15px",
                        color: "green",

                        marginBottom: "0.5em",
                        marginTop: "0.5em",
                      }}
                    >
                      Save was successful.
                    </CFTypography>
                  </CFBox>
                </Grid>
              </Grid>
            )}
          </Grid>
        </CFBox>
      </Grid>
    </CFBox>
  );
}
