// React
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// MUI
import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";

import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import Tooltip from "@mui/material/Tooltip";
import { ElectricScooterSharp } from "@mui/icons-material";
import colors from "assets/theme/base/colors";
import CFDialog from "components/CFDialog";
import CFInput from "components/CFInput";
// Our Components
import CFBox from "components/CFBox";

import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";
import { updateLoading } from "store/reducers/mask";
import { post } from "utils/api";
import { popupError } from "utils/generic";
import {
  UPDATE_GRAZING_POPULATIONS,
  FETCH_GRAZING_POPULATION,
  FETCH_GENERIC_POPULATION,
  UPDATE_GENERIC_POPULATIONS,
} from "constants/api";
import CFLoading from "components/CFLoading";
// Constants

export default function GrazingCattlePopulation({
  id,
  yearValue,
  isCopy,
  animalCategory,
  animalCategoryId,
  groupId,
  anagObject,
  anagObjectChanged,
  verifyDone,
}) {
  const dispatch = useDispatch();

  const { info } = colors;

  const [population, setPopulation] = useState([]);
  const [avgBodyWeight, setAvgBodyWeight] = useState([]);
  const [saveSuccessMsg, setSaveSuccessMsg] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const monthsArr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [typeOfPop, setTypeOfPop] = useState("");

  const getTypeOfPop = () => {
    switch (animalCategoryId) {
      case 21250:
      case 21253:
      case 21255:
        setTypeOfPop("GRAZING");
        return "GRAZING";
      case 21280:
      case 21296:
      case 21297:
        setTypeOfPop("GENERIC_WEIGHT");
        return "GENERIC_WEIGHT";
      case 21290:
      case 21291:
      case 21295:
      case 21298:
        setTypeOfPop("GENERIC_NO_WEIGHT");
        return "GENERIC_NO_WEIGHT";
      default:
        return null;
    }
  };
  const handlePopulationChange = (e) => {
    setPopulation({
      ...population,
      [e.target.name]: e.target.value,
    });
  };
  const copyPopulation = () => {
    const janPop = population.popcountJan;

    const popMonthsArr = [];
    monthsArr.map((month) => popMonthsArr.push([`popcount${month}`]));
    const popNewObj = {};
    popMonthsArr.forEach((mon) => {
      popNewObj[mon] = janPop;
    });

    setPopulation({ ...population, ...popNewObj });
  };
  const handleAvgBodyWeightChange = (e) => {
    setAvgBodyWeight({
      ...avgBodyWeight,
      [e.target.name]: e.target.value,
    });
  };
  const copyAvgBodyWeight = () => {
    const janAvgBodyWeight = avgBodyWeight.avgbodyweightcountJan;

    const avgBodyWeightMonthsArr = [];
    monthsArr.map((month) =>
      avgBodyWeightMonthsArr.push([`avgbodyweightcount${month}`])
    );
    const avgBodyWeightNewObj = {};
    avgBodyWeightMonthsArr.forEach((mon) => {
      avgBodyWeightNewObj[mon] = janAvgBodyWeight;
    });

    setAvgBodyWeight({ ...avgBodyWeight, ...avgBodyWeightNewObj });
  };

  const validateGrazingInputs = () => {
    let isComplete = false;
    const pExists =
      anagObject.selectedScenario === "Baseline" ||
      (anagObject.selectedScenario !== "Baseline" && !isCopy)
        ? Object.entries(population).some((x) => x[1] > 0)
        : Object.entries(population).some((x) => x[1] >= 0);
    const bwExists =
      anagObject.selectedScenario === "Baseline" ||
      (anagObject.selectedScenario !== "Baseline" && !isCopy)
        ? Object.entries(avgBodyWeight).some((x) => x[1] > 0)
        : Object.entries(avgBodyWeight).some((x) => x[1] >= 0);

    switch (animalCategoryId) {
      case 21250:
      case 21253:
      case 21255:
      case 21280:
      case 21296:
      case 21297:
        isComplete = pExists && bwExists;
        break;
      case 21290:
      case 21291:
      case 21295:
      case 21298:
        isComplete = pExists;
        break;
      default:
        isComplete = false;
    }
    setSaveEnabled(isComplete);
  };
  const validateUserInputs = (pExists, bwExists) => {
    switch (animalCategoryId) {
      case 21250:
      case 21253:
      case 21255:
      case 21280:
      case 21296:
      case 21297:
        return pExists && bwExists;

      case 21290:
      case 21291:
      case 21295:
      case 21298:
        return pExists;
      default:
        return false;
    }
  };
  const setFetchedValues = (pop) => {
    const popMonthsJson = [];
    const avgBodyWeightMonthsJson = [];

    const popNewJson = {};
    const avgBodyWeightNewJson = {};
    let popExists = false;
    let avrgeBWExists = false;

    monthsArr.map((month) => popMonthsJson.push([`popcount${month}`]));
    monthsArr.map((month) =>
      avgBodyWeightMonthsJson.push([`avgbodyweightcount${month}`])
    );

    popMonthsJson.forEach((mon) => {
      const monthName = mon.toString().replace("popcount", "");

      popNewJson[mon] = pop.find(
        (popln) => popln.Month.toString() === monthName.toString()
      ).PopCount;
    });

    setPopulation({ ...population, ...popNewJson });
    popExists =
      anagObject.selectedScenario === "Baseline" ||
      (anagObject.selectedScenario !== "Baseline" && !isCopy)
        ? pop.some((el) => el.PopCount > 0)
        : pop.some((el) => el.PopCount >= 0);
    avgBodyWeightMonthsJson.forEach((mon) => {
      const lbwMonthName = mon.toString().replace("avgbodyweightcount", "");
      avgBodyWeightNewJson[mon] = pop.find(
        (popln) => popln.Month.toString() === lbwMonthName.toString()
      ).AvgBodyWeightCount;
    });
    setAvgBodyWeight({ ...avgBodyWeight, ...avgBodyWeightNewJson });

    avrgeBWExists =
      anagObject.selectedScenario === "Baseline" ||
      (anagObject.selectedScenario !== "Baseline" && !isCopy)
        ? pop.some((el) => el.AvgBodyWeightCount > 0)
        : pop.some((el) => el.AvgBodyWeightCount >= 0);

    if (validateUserInputs(popExists, avrgeBWExists)) {
      verifyDone("green");
      setSaveEnabled(true);
    } else {
      verifyDone("grey");
      setSaveEnabled(false);
    }
  };

  const fetchGenericPopulation = () => {
    setLoading(true);
    const popObj = {
      popid: id,
    };
    post(FETCH_GENERIC_POPULATION, popObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const pop = JSON.parse(res.data);
          setFetchedValues(pop);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const fetchGrazingCattlePopulation = () => {
    setLoading(true);
    const popObj = {
      popid: id,
    };
    post(FETCH_GRAZING_POPULATION, popObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const json = JSON.parse(res.data);
          const pop = json.GrazingCattlePopMonths;
          setFetchedValues(pop);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const updateGenericPops = () => {
    setLoading(true);
    const animalTypesObj = {
      popObj: population,
      avgBodyWghtObj: avgBodyWeight,
      popid: id,
    };
    post(UPDATE_GENERIC_POPULATIONS, animalTypesObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
        } else {
          setSaveSuccessMsg(true);
          verifyDone("green");
        }
        // anagObjectChanged(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const updateGrazingCattlePops = () => {
    setLoading(true);
    const animalTypesObj = {
      popObj: population,
      avgBodyWghtObj: avgBodyWeight,
      popid: id,
    };
    post(UPDATE_GRAZING_POPULATIONS, animalTypesObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          setSaveSuccessMsg(true);
          verifyDone("green");
          // fetchGrazingCattlePopulation();
        }
        // anagObjectChanged(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const updateClicked = () => {
    if (typeOfPop === "GRAZING") {
      updateGrazingCattlePops();
    } else if (typeOfPop.includes("GENERIC")) {
      updateGenericPops();
    }
  };
  useEffect(() => {
    const type = getTypeOfPop();
    if (type === "GRAZING") {
      fetchGrazingCattlePopulation();
    } else if (type.includes("GENERIC")) {
      fetchGenericPopulation();
    }
  }, [anagObject.activeAnimalCategories]);

  useEffect(() => {
    validateGrazingInputs();
  }, [population, avgBodyWeight]);

  return (
    <CFBox width="100%">
      {loading && <CFLoading local />}
      <Grid container item>
        {/* Main form */}
        <CFBox width="100%">
          <Grid container direction="row" xs={12} sm={12} md={12}>
            <Grid container direction="row">
              {(animalCategoryId === 21290 ||
                animalCategoryId === 21291 ||
                animalCategoryId === 21295 ||
                animalCategoryId === 21298) && (
                <Grid item container xs={12}>
                  <CFTypography
                    sx={{
                      fontSize: "0.7em",

                      color: info.dullFocus,

                      marginBottom: "0.7em",
                    }}
                  >
                    Enter the monthly animal population for {animalCategory}.
                    Use arrows to copy data across months.
                  </CFTypography>
                </Grid>
              )}
              {(animalCategoryId === 21280 ||
                animalCategoryId === 21296 ||
                animalCategoryId === 21297 ||
                animalCategoryId === 21250 ||
                animalCategoryId === 21253 ||
                animalCategoryId === 21255) && (
                <Grid item container xs={12}>
                  <CFTypography
                    sx={{
                      fontSize: "0.7em",

                      color: info.dullFocus,

                      marginBottom: "0.7em",
                    }}
                  >
                    Enter the monthly animal population and average live body
                    weight for {animalCategory}. Use arrows to copy data across
                    months.
                  </CFTypography>
                </Grid>
              )}
              <Grid item container xs={12} id="step2box" alignItems="center">
                <Grid
                  item
                  container
                  xs={12}
                  alignItems="center"
                  direction="column"
                >
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      justifyContent="space-evenly"
                      gap={1}
                      style={{ marginLeft: "12%" }}
                    >
                      {monthsArr.map((month) => (
                        <CFTypography
                          sx={{
                            fontSize: "1.2em",

                            color: info.dullFocus,
                            fontWeight: "bold",
                            padding: "9.5px",
                          }}
                        >
                          {month}
                        </CFTypography>
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  alignItems="center"
                  direction="row"
                >
                  <Grid item xs={12}>
                    <Tooltip title="Copy data across months">
                      <ArrowCircleRightTwoToneIcon
                        onClick={() => copyPopulation()}
                        id="copyPopulation"
                        color="primary"
                        fontSize="medium"
                        style={{
                          cursor: "pointer",
                          marginLeft: "20%",
                          marginTop: "1%",
                        }}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  alignItems="center"
                  direction="row"
                >
                  <Grid item xs={2}>
                    <CFTypography
                      sx={{
                        fontSize: "15px",
                        color: info.dullFocus,
                        fontWeight: "bold",

                        marginBottom: "0.5em",
                        marginTop: "0.5em",
                      }}
                    >
                      Population
                    </CFTypography>
                  </Grid>
                  <Grid item xs={10}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={1}
                      style={{ marginLeft: "3%" }}
                    >
                      {monthsArr.map((month) => (
                        <CFInput
                          sx={{
                            padding: "10px",
                            fieldset: {
                              borderColor: info.dullFocus,
                              borderStyle: "solid",
                              borderWidth: "2px",
                            },
                          }}
                          color="primary"
                          id={`popCount${month}`}
                          name={`popcount${month}`}
                          defaultValue="0"
                          value={population[`popcount${month}`]}
                          onChange={handlePopulationChange}
                          placeholder=""
                          fullWidth
                        />
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
                {typeOfPop === "GRAZING" || typeOfPop === "GENERIC_WEIGHT" ? (
                  <>
                    <Grid
                      item
                      container
                      xs={12}
                      alignItems="center"
                      direction="row"
                    >
                      <Grid item xs={12}>
                        <Tooltip title="Copy data across months">
                          <ArrowCircleRightTwoToneIcon
                            onClick={() => copyAvgBodyWeight()}
                            id="copyAvgBodyWeight"
                            color="primary"
                            fontSize="medium"
                            style={{
                              cursor: "pointer",
                              marginLeft: "20%",
                              marginTop: "1%",
                            }}
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      alignItems="center"
                      direction="row"
                    >
                      <Grid item xs={2}>
                        <CFTypography
                          sx={{
                            fontSize: "15px",

                            color: info.dullFocus,
                            fontWeight: "bold",

                            marginBottom: "0.5em",
                            marginTop: "0.5em",
                          }}
                        >
                          Average live body weight (lbs)
                        </CFTypography>
                      </Grid>
                      <Grid item xs={10}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap={1}
                          style={{ marginLeft: "3%" }}
                        >
                          {monthsArr.map((month) => (
                            <CFInput
                              sx={{
                                padding: "10px",
                                fieldset: {
                                  borderColor: info.dullFocus,
                                  borderStyle: "solid",
                                  borderWidth: "2px",
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              color="primary"
                              id={`avgBodyWeightCount${month}`}
                              name={`avgbodyweightcount${month}`}
                              value={
                                avgBodyWeight[`avgbodyweightcount${month}`]
                              }
                              defaultValue="0"
                              onChange={handleAvgBodyWeightChange}
                              placeholder=""
                              fullWidth
                            />
                          ))}
                        </Stack>
                      </Grid>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </Grid>
            <Grid container py={2} px={2} justifyContent="space-around">
              <Grid item xs={12} sm={12} md={12}>
                <CFButton
                  type="submit"
                  color="progress"
                  sx={{
                    float: "right",
                  }}
                  onClick={updateClicked}
                  disabled={!saveEnabled}
                  // disabled={showNameError}
                >
                  Save
                </CFButton>
              </Grid>
            </Grid>
            {saveSuccessMsg && (
              <Grid container justifyContent="space-around">
                <Grid item xs={2} sm={2} md={2} alignItems="center">
                  <CFBox id="successMessage">
                    <CFTypography
                      sx={{
                        fontSize: "15px",
                        color: "green",

                        marginBottom: "0.5em",
                        marginTop: "0.5em",
                      }}
                    >
                      Save was successful.
                    </CFTypography>
                  </CFBox>
                </Grid>
              </Grid>
            )}
          </Grid>
        </CFBox>
      </Grid>
    </CFBox>
  );
}
