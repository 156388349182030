/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect, useRef } from "react";
import {
  Checkbox,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
} from "@mui/icons-material";
import { post, upload } from "utils/api";
import { updateLoading } from "store/reducers/mask";
import {
  PROJECTS_FETCH_ALL,
  BULK_EXPORT_PROJECTS,
  DELETE_PROJECTS,
  IMPORT_MANAGEMENT,
} from "constants/api";

import JSZip from "jszip";

import Checked from "assets/icons/Checked.png";
import Unchecked from "assets/icons/Unchecked.png";
import CFarmPage from "components/CFarmParts/CFarmPage";
import CFBox from "components/CFBox";
import CFTabView from "components/CFTabView";
import CFButton from "components/CFButton";
import CFDialog from "components/CFDialog";
import { popupError } from "utils/generic";
import UserGuidePopup from "components/CFUserGuidePopup/UserGuidePopup";
import CFTypography from "components/CFTypography";
import CFInput from "components/CFInput";
import NewProject from "../NewProject";
import ProjectCard from "./ProjectCard";
import { displayedActivities } from "./constants";

function ChooseActivities() {
  const dispatch = useDispatch();

  // Projects list
  const [sortedProjectList, setSortedProjectList] = useState([]);
  const [dateSortAscendingDescending, switchDateSort] = useState(false);
  const [nameSortAscendingDescending, setNameSortAscDesc] = useState(false);
  const [selectedSort, setSelectedSort] = useState("date");
  const [arrowDirection, setArrowDirection] = useState(
    <ArrowDropDownOutlined />
  );
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [projectNames, setProjectNames] = useState([]);

  // State
  const currentUser = useSelector((state) => state.user);
  const [localPopupState, updateLocalPopup] = useState({});

  // Display
  const [expanded, setExpanded] = useState("");
  const [bulkAction, setBulkAction] = useState(false);

  // Modals
  const [editProjectIsOpen, setEditProjectIsOpen] = useState(false);
  const [projectToEdit, setProjectToEdit] = useState(null);
  const inputFile = useRef(null);

  // Retrieve all projects for active user on initial render
  const fetchAllProjects = () => {
    dispatch(updateLoading({ loading: true }));
    setSelectedProjects([]);
    post(PROJECTS_FETCH_ALL, currentUser)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          dispatch(updateLoading({ loading: false }));
        } else {
          setBulkAction(false);
          if (res.data.length) {
            res.data.sort(
              (a, b) => new Date(b.dateAccessed) - new Date(a.dateAccessed)
            );
            setSortedProjectList(
              res.data.map((project) => ({
                ...project,
                dateAccessed: new Date(project.dateAccessed),
              }))
            );
          } else {
            setSortedProjectList([]);
          }
          switchDateSort(false);
          setSelectedSort("date");
          if (res.data[0]) setExpanded(res.data[0].name);
        }

        dispatch(updateLoading({ loading: false }));
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateLoading({ loading: false }));
      });
  };
  useEffect(() => {
    fetchAllProjects();
  }, []);

  // Open Project modal for new/edit project
  const [isDemo, setIsDemo] = useState(false);
  function OpenNewProjectWindow() {
    setProjectNames(sortedProjectList.map((p) => p.name));
    setIsDemo(false);
    setProjectToEdit(null);
    setEditProjectIsOpen(true);
  }
  const OpenEditProjectWindow = (id) => {
    const project = sortedProjectList.find((p) => p.id === id);
    const filteredList = sortedProjectList.filter((p) => p.id !== id);
    setProjectNames(filteredList.map((p) => p.name));
    setIsDemo(false);
    setProjectToEdit(project);
    setEditProjectIsOpen(true);
  };
  function OpenDemoProjectWindow() {
    setProjectNames(sortedProjectList.map((p) => p.name));
    setIsDemo(true);
    setProjectToEdit(null);
    setEditProjectIsOpen(true);
  }
  // Import File changing
  const onChangeFile = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const file = e.target.files[0];

    dispatch(updateLoading({ loading: true }));
    console.log(file);

    const formData = new FormData();
    formData.append("Files", file);
    // formData.append("projectId", id);
    formData.append("userId", currentUser.id);

    upload(IMPORT_MANAGEMENT, formData)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
        } else {
          // reset project list
          if (res.data.length) {
            res.data.sort(
              (a, b) => new Date(b.dateAccessed) - new Date(a.dateAccessed)
            );
            setSortedProjectList(
              res.data.map((project) => ({
                ...project,
                dateAccessed: new Date(project.dateAccessed),
              }))
            );
          }
          switchDateSort(false);
          setSelectedSort("date");
          if (res.data[0]) setExpanded(res.data[0].name);

          // Inform user
          updateLocalPopup({
            title: "Success",
            message: "Project has been successfully imported.",
            type: "OK",
            showPopup: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(updateLoading({ loading: false }));
      });
  };

  function doImport() {
    inputFile.current.click();
  }

  // Bulk select actions
  function bulkConfirmDeleteClicked(plist) {
    updateLocalPopup({
      showPopup: false,
    });
    dispatch(updateLoading({ loading: true }));

    post(DELETE_PROJECTS, plist)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
        } else {
          fetchAllProjects();
          updateLocalPopup({
            title: "Projects deleted.",
            message: res.Data,
            type: "OK",
            showPopup: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(updateLoading({ loading: false }));
      });
  }
  // Project export
  const [exportDestination, setExportDestination] = useState("local");
  const [alternateEmail, setAlternateEmail] = useState("");
  function BulkProjectExport() {
    dispatch(updateLoading({ loading: true }));

    // Dismiss the popup, if it's still there
    if (localPopupState && localPopupState.showPopup) {
      updateLocalPopup({
        showPopup: false,
      });
    }

    // Get list of the finished projects from the list of selected
    const onlyFinishedProjectList = sortedProjectList
      .filter((x) => selectedProjects.indexOf(x.id) > -1 && x.exportReady)
      .map((x) => x.id);

    post(BULK_EXPORT_PROJECTS, {
      userId: currentUser.id,
      projects: onlyFinishedProjectList,
      destination: exportDestination,
      email: alternateEmail,
    })
      .then(async (res) => {
        if (res.error) {
          popupError(res.error, dispatch);
        } else {
          updateLocalPopup({
            // popup is simple so can use the global popup
            title: "Project export completed!",
            message: res.data.message,
            type: "OK",
            showPopup: true,
          });

          // Put all the files into a zip folder
          if (exportDestination === "local") {
            const zip = new JSZip();
            const fileZip = zip.folder(
              `COMET-Farm_Bulk_Export_${currentUser?.id}`
            );

            // Add each file to the zip folder
            const fileList = res.data.files;
            await Promise.all(
              fileList.map(async (file) => {
                const fileName = file.name;
                const fileData = file.data;

                // Create a blob of each file and add it to the zip folder
                const blob = new Blob([fileData], { fileData });

                fileZip.file(fileName, blob);
              })
            );

            // Download the zip folder
            zip.generateAsync({ type: "blob" }).then((content) => {
              const a = document.createElement("a");
              a.style.display = "none";
              document.body.appendChild(a);
              // Set the HREF to a Blob representation of the data to be downloaded
              a.href = window.URL.createObjectURL(content);
              // Use download attribute to set desired file name
              a.setAttribute(
                "download",
                `COMET-Farm_Bulk_Export_${currentUser?.id}`
              );

              // Trigger the download by simulating click
              a.click();

              // Cleanup
              window.URL.revokeObjectURL(a.href);
              document.body.removeChild(a);
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(updateLoading({ loading: false }));
      });
  }
  useEffect(() => {
    if (localPopupState && localPopupState.showPopup) {
      updateLocalPopup({
        title: "Bulk Project Export",
        children: [
          <Grid container px={2} key={exportDestination}>
            <CFTypography
              variant="body2"
              color="secondary"
              fontWeight="regular"
              pt={2}
            >
              Select a location for the exported project(s) to be sent:
            </CFTypography>
            <Grid container direction="column" alignItems="center" py={2}>
              <RadioGroup
                value={exportDestination}
                onChange={(e) => {
                  setExportDestination(e.target.value);
                }}
              >
                <FormControlLabel
                  value="local"
                  control={<Radio />}
                  label="Download locally to this computer"
                />
                <FormControlLabel
                  value="accountEmail"
                  control={<Radio />}
                  label="Use the email associated with my account"
                />
                <Grid container direction="row" alignItems="center">
                  <FormControlLabel
                    value="otherEmail"
                    control={<Radio />}
                    label="Use an alternate email:"
                  />
                  <CFInput
                    label="Alternate Email"
                    color="primary"
                    placeholder="Alternate Email"
                    size="small"
                    disabled={exportDestination !== "otherEmail"}
                    value={alternateEmail}
                    onChange={(e) => {
                      setAlternateEmail(e.target.value);
                    }}
                  />
                </Grid>
              </RadioGroup>
            </Grid>
          </Grid>,
        ],
        button1: {
          text: "Export",
          onClick: () => {
            BulkProjectExport();

            setExportDestination("local");
            setAlternateEmail("");
            updateLocalPopup({ showPopup: false });
          },
        },
        showPopup: true,
        closeAction: () => {
          setExportDestination("local");
          setAlternateEmail("");
          updateLocalPopup({ showPopup: false });
        },
      });
    }
  }, [exportDestination, alternateEmail]);
  function bulkExportClicked() {
    updateLocalPopup({
      title: "Bulk Project Export",
      children: [
        <Grid container px={2} key={exportDestination}>
          <CFTypography
            variant="body2"
            color="secondary"
            fontWeight="regular"
            pt={2}
          >
            Select a location for the exported project(s) to be sent:
          </CFTypography>
          <Grid container direction="column" alignItems="center" py={2}>
            <RadioGroup
              value={exportDestination}
              onChange={(e) => {
                setExportDestination(e.target.value);
              }}
            >
              <FormControlLabel
                value="local"
                control={<Radio />}
                label="Download locally to this computer"
              />
              <FormControlLabel
                value="accountEmail"
                control={<Radio />}
                label="Use the email associated with my account"
              />
              <Grid container direction="row" alignItems="center">
                <FormControlLabel
                  value="otherEmail"
                  control={<Radio />}
                  label="Use an alternate email:"
                />
                <CFInput
                  label="Alternate Email"
                  color="primary"
                  placeholder="Alternate Email"
                  size="small"
                  disabled={exportDestination !== "otherEmail"}
                  value={alternateEmail}
                  onChange={(e) => {
                    setAlternateEmail(e.target.value);
                  }}
                />
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>,
      ],
      button1: {
        text: "Export",
        onClick: () => {
          BulkProjectExport();

          setExportDestination("local");
          setAlternateEmail("");
          updateLocalPopup({ showPopup: false });
        },
      },
      showPopup: true,
      closeAction: () => {
        setExportDestination("local");
        setAlternateEmail("");
        updateLocalPopup({ showPopup: false });
      },
    });
  }

  function bulkDeleteConfirmChange(event, plist) {
    const nameTarget = "delete";
    const typedTarget = event.target.value.toLowerCase().trim();

    if (nameTarget === typedTarget) {
      updateLocalPopup({
        title: `Are you sure you want to delete these ${plist.length} projects?`,
        component: {
          type: "input",
          label: `Delete is enabled`,

          defaultValue: "DELETE",
          disabled: true,
        },
        button2: {
          onClick: () => bulkConfirmDeleteClicked(plist),
          text: `Delete ${plist.length} projects`,
          variant: "gradient",
          color: "error",
        },

        showPopup: true,
      });
    }
  }
  function bulkActionDelete(plist) {
    updateLocalPopup({
      title: `Are you sure you want to delete these ${plist.length} projects?`,
      message: `Type DELETE to enable the delete button`,
      component: {
        type: "input",
        label: `DELETE`,
        onChange: (e) => bulkDeleteConfirmChange(e, plist),
      },

      button2: {
        text: `Delete ${plist.length} projects`,
        variant: "gradient",
        color: "info",
        disabled: true,
      },

      // type: "OK",
      showPopup: true,
    });
  }
  function OpenBulkActionDialog() {
    const OnlyGoodThings = Object.values(selectedProjects).filter((x) => x);
    if (OnlyGoodThings.length > 0) {
      updateLocalPopup({
        message: `Perform bulk actions on the ${OnlyGoodThings.length} selected project(s). Only projects with fully-generated reports can be exported.`,
        title: `Bulk Action on ${OnlyGoodThings.length} Project(s)`,
        button1: {
          onClick: () => bulkExportClicked(),
          text: `Export Selected Projects`,
          variant: "gradient",
          color: "progress",
        },
        button2: {
          onClick: () => bulkActionDelete(OnlyGoodThings),
          text: `Delete Selected Projects`,
          variant: "gradient",
          color: "progress",
        },

        showPopup: true,
      });
    } else {
      updateLocalPopup({
        message: `Use this window to export or delete any number of selected projects.`,
        title: `Project Bulk Action`,
        showPopup: true,
      });
    }
  }
  function BulkActionToggle() {
    setSelectedProjects([]);
    setBulkAction(!bulkAction);
  }
  const switchArrowDirection = (direction) => {
    if (direction === "asc") setArrowDirection(<ArrowDropUpOutlined />);
    else setArrowDirection(<ArrowDropDownOutlined />);
  };

  // Project list sorting
  const SwitchTheSort = () => {
    setSelectedSort("date");
    switchDateSort(!dateSortAscendingDescending);
    if (dateSortAscendingDescending) {
      setSortedProjectList(
        sortedProjectList.sort((a, b) => a.dateAccessed - b.dateAccessed)
      );
      switchArrowDirection("asc");
    } else {
      setSortedProjectList(
        sortedProjectList.sort((a, b) => b.dateAccessed - a.dateAccessed)
      );
      switchArrowDirection("desc");
    }
  };
  const SortByName = () => {
    setSelectedSort("name");
    setNameSortAscDesc(!nameSortAscendingDescending);
    if (nameSortAscendingDescending) {
      setSortedProjectList(
        sortedProjectList.sort((a, b) => a.name.localeCompare(b.name))
      );
      switchArrowDirection("asc");
    } else {
      setSortedProjectList(
        sortedProjectList.sort((a, b) => a.name.localeCompare(b.name) * -1)
      );
      switchArrowDirection("desc");
    }
  };

  // Bulk select project
  const projectCheckChanged = (e, projId) => {
    // Stop card from expanding
    e.stopPropagation();

    // New selected projects array
    let newSelected = [...selectedProjects];

    // Remove or add project from project list
    if (newSelected.includes(projId)) {
      newSelected = newSelected.filter((project) => project !== projId);
    } else {
      newSelected.push(projId);
    }

    // Update selectAll
    if (newSelected.length !== sortedProjectList.length) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }

    // Update state value
    setSelectedProjects(newSelected);
  };
  const OnSelectAll = () => {
    // Reset selected projects to empty
    setSelectedProjects([]);

    // If switching from false to true, add all projects to selectedProjects
    if (!selectAll) {
      const newSelected = [];
      sortedProjectList.map((project) => newSelected.push(project.id));
      setSelectedProjects(newSelected);
    }

    // Flip selectAll
    setSelectAll(!selectAll);
  };

  // Project card expand
  const expandToggle = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : "");
  };

  // Project list display
  function MakeHeader() {
    return (
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        direction="row"
      >
        {/* Bulk action check */}
        {bulkAction && (
          <Grid item container xs={1} pl={1} sx={{ justifyContent: "center" }}>
            <CFBox
              sx={{
                justifyContent: "center",
                alignItems: "center",
                height: "auto",
              }}
            >
              <Checkbox
                icon={<img src={Unchecked} alt="" />}
                checkedIcon={<img src={Checked} alt="" />}
                checked={selectAll && selectedProjects.length > 0}
                onClick={() => OnSelectAll()}
                style={{
                  transform: "scale(.35)",
                  height: "3.8em",
                }}
              />
            </CFBox>
          </Grid>
        )}

        {/*  Name */}
        <Grid item container xs={bulkAction ? 3 : 4} pl={bulkAction ? 0.25 : 2}>
          <CFBox
            sx={{
              textAlign: "left",
              margin: "1em",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            display="flex"
            onClick={SortByName}
          >
            Name{" "}
            <CFBox pl={0.75} sx={{ paddingTop: ".25em" }}>
              {selectedSort === "name" ? arrowDirection : ""}{" "}
            </CFBox>
          </CFBox>
        </Grid>

        {/*  Activities */}
        <Grid item container xs={4} pl={bulkAction ? 5 : 7}>
          <CFBox
            sx={{ textAlign: "left", margin: "1em", fontWeight: "bold" }}
            display="flex"
          >
            <UserGuidePopup
              label="Activities"
              sx={{ fontWeight: "bold" }}
              content={displayedActivities.map((activityType) => (
                <Grid key={activityType.name}>
                  <CFBox>
                    <Grid container direction="row" alignItems="center">
                      <img
                        src={activityType.icon}
                        alt={activityType.name}
                        style={{
                          width: "2em",
                        }}
                      />
                      <CFTypography variant="h6" fontWeight="bold">
                        {` ${activityType.name}:`}
                      </CFTypography>
                    </Grid>
                    <CFTypography variant="h6" fontWeight="regular">
                      {`${activityType.message}\n\n`}
                    </CFTypography>
                  </CFBox>
                </Grid>
              ))}
            />
          </CFBox>
        </Grid>

        {/*  Date accessed */}
        <Grid item container xs={3} sx={{ justifyContent: "end" }}>
          <CFBox
            sx={{
              textAlign: "end",
              margin: "1em",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            display="flex"
            onClick={SwitchTheSort}
          >
            Date Accessed{" "}
            <CFBox pl={0.75} sx={{ paddingTop: ".25em" }}>
              {selectedSort === "date" ? arrowDirection : ""}
            </CFBox>
          </CFBox>
        </Grid>
      </Grid>
    );
  }
  function MakeProjectTable() {
    return (
      /* Grid with two rows: the project list header, and the project list */
      <Grid
        container
        justifyContent="space-between"
        mx="auto"
        textAlign="center"
        direction="row"
        sx={{ width: "100%" }}
      >
        {/* projectList && projectList[0] && MakeProjectCard(projectList[0], 0) */}
        <CFBox sx={{ width: "100%" }}>{MakeHeader()}</CFBox>
        <CFBox sx={{ width: "100%", maxHeight: 600, overflow: "auto" }}>
          {sortedProjectList.map((project) => (
            <ProjectCard
              project={project}
              checked={selectedProjects.includes(project.id)}
              projectCheckChanged={projectCheckChanged}
              editProject={OpenEditProjectWindow}
              localPopupState={localPopupState}
              updateLocalPopup={updateLocalPopup}
              fetchAllProjects={fetchAllProjects}
              expanded={expanded}
              expandToggle={expandToggle}
              bulkAction={bulkAction}
              key={`project${project.id}`}
            />
          ))}
        </CFBox>
      </Grid>
    );
  }

  // Main component
  const TabsAndData = {
    TabsList: [
      {
        Tab: `${currentUser.firstName}'s Projects`,
        Data: (
          <CFBox>
            {sortedProjectList &&
              sortedProjectList.length > 0 &&
              MakeProjectTable()}

            {sortedProjectList && sortedProjectList.length === 0 && (
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ height: 250, width: 750 }}
              >
                <CFTypography
                  variant="h4"
                  fontWeight="regular"
                  color="primary"
                >{`Select "New Project" to Begin`}</CFTypography>
              </Grid>
            )}

            {currentUser?.isVerifiedByEmail && (
              <CFButton // Bulk action select button
                variant="gradient"
                color={bulkAction ? "secondary" : "primary"}
                onClick={() => BulkActionToggle()}
                sx={{ float: "left", margin: "1em" }}
              >
                {bulkAction ? "CANCEL" : "BULK ACTION"}
              </CFButton>
            )}
            {bulkAction && (
              <CFButton // Bulk action button
                variant="gradient"
                color="primary"
                onClick={() => OpenBulkActionDialog()}
                sx={{ float: "left", margin: "1em" }}
              >
                ACTIONS
              </CFButton>
            )}

            {currentUser?.isVerifiedByEmail && (
              <CFButton // New project window
                variant="gradient"
                color="progress"
                onClick={() => OpenNewProjectWindow()}
                disabled={bulkAction}
                sx={{ float: "right", margin: "1em" }}
              >
                NEW PROJECT
              </CFButton>
            )}
            {currentUser?.isVerifiedByEmail && (
              <CFButton
                variant="gradient"
                color="primary"
                onClick={() => OpenDemoProjectWindow()}
                disabled={bulkAction}
                sx={{
                  float: "right",
                  margin: "1em",
                }}
              >
                DEMO PROJECT
              </CFButton>
            )}
            {currentUser?.isVerifiedByEmail && (
              <CFButton
                variant="gradient"
                color="primary"
                onClick={() => doImport()}
                disabled={bulkAction}
                sx={{
                  float: "right",
                  margin: "1em",
                }}
              >
                IMPORT PROJECT
              </CFButton>
            )}
            <input
              type="file"
              id="file"
              ref={inputFile}
              style={{ display: "none" }}
              onChange={onChangeFile}
            />
          </CFBox>
        ),
      },
    ],
  };

  return (
    <CFarmPage>
      <CFBox sx={{ maxWidth: "1200px", marginTop: "3%", marginBottom: "3%" }}>
        <CFTabView>{TabsAndData}</CFTabView>
      </CFBox>

      <NewProject
        isOpen={editProjectIsOpen}
        setIsOpen={setEditProjectIsOpen}
        fetchAllProjects={fetchAllProjects}
        projectNames={projectNames}
        project={projectToEdit}
        isDemo={isDemo}
      />

      {localPopupState && localPopupState.showPopup && (
        <CFDialog
          {...localPopupState}
          updatePopup={(e) => updateLocalPopup(e)}
        />
      )}
    </CFarmPage>
  );
}
export default ChooseActivities;
