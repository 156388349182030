// React
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// MUI
import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";

import ArrowCircleDownTwoToneIcon from "@mui/icons-material/ArrowCircleDownTwoTone";

// Our Components

import Tooltip from "@mui/material/Tooltip";

import CFTypography from "components/CFTypography";
import CFBox from "components/CFBox";
import CFInput from "components/CFInput";

import colors from "assets/theme/base/colors";
import CFButton from "components/CFButton";

import { post } from "utils/api";
import { popupError } from "utils/generic";
import {
  GET_GRAZING_FEEDS,
  UPDATE_GRAZING_FEEDS,
  GET_GRAZING_FEEDS_LOOKUPS,
} from "constants/api";
import CFLookupPicker from "components/CroplandsComponents/CFLookupPicker/CFLookupPicker";
import UserGuidePopup from "components/CFUserGuidePopup/UserGuidePopup";
import CFLoading from "components/CFLoading";

import { getTextInput, getAdditiveUnits } from "./AnimalAgCommonFunc";

// Constants

export default function GrazingCattleFeeds({
  id,
  yearValue,
  animalCategory,
  animalCategoryId,
  groupId,
  anagObject,
  anagObjectChanged,
  grPopFlag,
  setGrPopFlag,
  verifyDone,
}) {
  const dispatch = useDispatch();

  const { info } = colors;

  const [feedQualityList, setFeedQualityList] = useState([]);

  const [forageCount, setForageCount] = useState([]);
  const [dosageCount, setDosageCount] = useState([]);

  const [saveSuccessMsg, setSaveSuccessMsg] = useState(false);

  const [loading, setLoading] = useState(false);
  const [grFeedsSaveEnable, setGrFeedsSaveEnable] = useState(false);
  const [additiveTypeList, setAdditiveTypeList] = useState([]);

  const [monthsArr, setMonthsArr] = useState([]);

  const setUnitDefaults = (mArr) => {
    const unitsMonthsArr = [];
    mArr.map((month) => unitsMonthsArr.push([`units${month}`]));
    const unitsNewObj = {};

    unitsMonthsArr.forEach((mon) => {
      unitsNewObj[mon] = "";
    });

    return unitsNewObj;
  };
  const [additiveUnits, setAdditiveUnits] = useState(
    setUnitDefaults(monthsArr)
  );
  const setAddtveDefaults = (mArr) => {
    const addTypeMonthsArr = [];
    mArr.map((month) => addTypeMonthsArr.push([`additiveType${month}`]));
    const addTypeNewObj = {};

    addTypeMonthsArr.forEach((mon) => {
      addTypeNewObj[mon] = { id: 212450, name: "Select" };
    });

    return addTypeNewObj;
  };

  const [additiveTypeVal, setAdditiveTypeVal] = useState(
    setAddtveDefaults(monthsArr)
  );

  const setAdditiveType = (mon, val) => {
    setAdditiveTypeVal({
      ...additiveTypeVal,
      [`additiveType${mon}`]: { id: val.id, name: val.name },
    });
    const addUnits = getAdditiveUnits(val.id);
    if (val.id === 215203 || val.id === 215204 || val.id === 215206) {
      setDosageCount({
        ...dosageCount,
        [`dosage${mon}`]: 0,
      });
    }

    setAdditiveUnits({ ...additiveUnits, [`units${mon}`]: addUnits });
  };

  const setDefaults = (mArr) => {
    const feedQualityMonthsArr = [];
    mArr.map((month) => feedQualityMonthsArr.push(month));
    const feedQualityNewObj = {};

    feedQualityMonthsArr.forEach((mon) => {
      feedQualityNewObj[mon] = { id: 212450, name: "Select" };
    });

    return feedQualityNewObj;
  };

  const [feedQualityCount, setFeedQualityCount] = useState(
    setDefaults(monthsArr)
  );

  const copyFeedQuality = () => {
    if (monthsArr.length > 0) {
      const feedQualityNewObj = setDefaults(monthsArr);

      monthsArr.forEach((mon) => {
        feedQualityNewObj[mon].id = feedQualityCount[`${monthsArr[0]}`]?.id;
        feedQualityNewObj[mon].name = feedQualityCount[`${monthsArr[0]}`]?.name;
      });

      setFeedQualityCount({ ...feedQualityCount, ...feedQualityNewObj });
    }
  };
  const setFeedQuality = (mon, val) => {
    setFeedQualityCount({
      ...feedQualityCount,
      [mon]: { id: val.id, name: val.name },
    });
  };

  const handleForageChange = (e) => {
    const value = getTextInput(e.target.value);
    setForageCount({
      ...forageCount,
      [e.target.name]: value,
    });
  };

  const copyForage = () => {
    if (monthsArr.length > 0) {
      const janForage = getTextInput(forageCount[`forage${monthsArr[0]}`]);
      const forageMonthsArr = [];
      monthsArr.map((month) => forageMonthsArr.push([`forage${month}`]));
      const forageNewObj = {};
      forageMonthsArr.forEach((mon) => {
        forageNewObj[mon] = janForage;
      });

      setForageCount({ ...forageCount, ...forageNewObj });
    }
  };
  const copyFeedAdditives = () => {
    if (monthsArr.length > 0) {
      const fdAdditiveNewObj = setAddtveDefaults(monthsArr);
      const additiveMonths = [];

      const unitsMonths = [];
      const dosageMonths = [];
      const unitsNewObj = {};
      const dosageNewObj = {};
      const janUnits = additiveUnits[`units${monthsArr[0]}`];
      monthsArr.map((month) => additiveMonths.push([`additiveType${month}`]));

      monthsArr.map((month) => unitsMonths.push([`units${month}`]));

      monthsArr.map((month) => dosageMonths.push([`dosage${month}`]));

      additiveMonths.forEach((mon) => {
        fdAdditiveNewObj[mon].id =
          additiveTypeVal[`additiveType${monthsArr[0]}`]?.id;
        fdAdditiveNewObj[mon].name =
          additiveTypeVal[`additiveType${monthsArr[0]}`]?.name;
      });

      setAdditiveTypeVal({ ...additiveTypeVal, ...fdAdditiveNewObj });

      unitsMonths.forEach((mon) => {
        unitsNewObj[mon] = janUnits;
      });

      setAdditiveUnits({ ...additiveUnits, ...unitsNewObj });
      if (
        additiveTypeVal[`additiveType${monthsArr[0]}`]?.id === 215203 ||
        additiveTypeVal[`additiveType${monthsArr[0]}`]?.id === 215204 ||
        additiveTypeVal[`additiveType${monthsArr[0]}`]?.id === 215206
      ) {
        dosageMonths.forEach((mon) => {
          dosageNewObj[mon] = 0;
        });
        setDosageCount({ ...dosageCount, ...dosageNewObj });
      }
    }
  };
  const handleDosageChange = (e) => {
    setDosageCount({
      ...dosageCount,
      [e.target.name]: e.target.value,
    });
  };
  const copyDosages = () => {
    if (monthsArr.length > 0) {
      const janDosageCount = getTextInput(dosageCount[`dosage${monthsArr[0]}`]);
      const dosageMonthsArr = [];
      monthsArr.map((month) => dosageMonthsArr.push([`dosage${month}`]));
      const dosagnewObj = {};
      dosageMonthsArr.forEach((mon) => {
        dosagnewObj[mon] = janDosageCount;
      });

      setDosageCount({ ...dosageCount, ...dosagnewObj });
    }
  };
  const fetchFeedQualityLookUps = () => {
    setLoading(true);

    const lookUpObj = {
      feedqlyty: "FEEDQLTY",
    };
    post(GET_GRAZING_FEEDS_LOOKUPS, lookUpObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const json = JSON.parse(res.data);

          setFeedQualityList(json.feedQuality);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const isFeedValid = () => {
    let isComplete = false;
    const fdQltyExists = Object.entries(feedQualityCount).some(
      (x) => x[1].id !== 212450
    );
    const frgeExists = Object.entries(forageCount).some((x) => x[1] > 0);

    if (fdQltyExists && frgeExists) {
      isComplete = true;
    }

    setGrFeedsSaveEnable(isComplete);
  };

  const fetchGrazingFeeds = () => {
    setLoading(true);
    const popObj = {
      popid: id,
    };
    post(GET_GRAZING_FEEDS, popObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const json = res.data;

          setMonthsArr(json.grazingMonths);
          const forageMonthsJson = [];
          const addtveTypeMonthsJson = [];
          const dosageMonthsJson = [];
          const unitsMonthsJson = [];
          const feedQualityNewJson = setDefaults(json.grazingMonths);
          const forageNewJson = {};
          const addTypeNewJson = setAddtveDefaults(json.grazingMonths);
          const unitsNewJson = setUnitDefaults(json.grazingMonths);
          const dosageNewJson = {};
          let fdQltyExists = false;
          let frgeCountExists = false;

          json.grazingMonths.map((month) =>
            forageMonthsJson.push([`forage${month}`])
          );
          json.grazingMonths.map((month) =>
            addtveTypeMonthsJson.push([`additiveType${month}`])
          );
          json.grazingMonths.map((month) =>
            dosageMonthsJson.push([`dosage${month}`])
          );
          json.grazingMonths.map((month) =>
            unitsMonthsJson.push([`units${month}`])
          );

          json.grazingMonths.forEach((mon) => {
            feedQualityNewJson[mon].id = json.grazngCatleViewData.find(
              (fdqlty) => fdqlty.month.toString() === mon.toString()
            ).feedQualityId;
            feedQualityNewJson[mon].name = json.grazngCatleViewData.find(
              (fdqlty) => fdqlty.month.toString() === mon.toString()
            ).feedQualityName;
          });
          fdQltyExists = json.grazngCatleViewData.some(
            (el) => el.feedQualityId !== 212450
          );
          setFeedQualityCount({ ...feedQualityCount, ...feedQualityNewJson });
          setAdditiveTypeList(json.grazingAdditiveList);

          forageMonthsJson.forEach((mon) => {
            const forageMonthName = mon.toString().replace("forage", "");
            forageNewJson[mon] = json.grazngCatleViewData.find(
              (forge) => forge.month.toString() === forageMonthName.toString()
            ).forageContent;
          });
          frgeCountExists = json.grazngCatleViewData.some(
            (el) => el.forageContent > 0
          );

          setForageCount({
            ...forageCount,
            ...forageNewJson,
          });

          addtveTypeMonthsJson.forEach((mon) => {
            const addTypeMonthName = mon.toString().replace("additiveType", "");
            const addId = json.grazngCatleViewData.find(
              (addtve) =>
                addtve.month.toString() === addTypeMonthName.toString()
            ).additiveTypeId;
            addTypeNewJson[mon].id = addId;
            unitsNewJson[`units${addTypeMonthName}`] = getAdditiveUnits(addId);
            addTypeNewJson[mon].name = json.grazngCatleViewData.find(
              (addtve) =>
                addtve.month.toString() === addTypeMonthName.toString()
            ).additiveTypeName;
          });

          setAdditiveTypeVal({ ...additiveTypeVal, ...addTypeNewJson });

          setAdditiveUnits({ ...additiveUnits, ...unitsNewJson });

          dosageMonthsJson.forEach((mon) => {
            const dosageMonthName = mon.toString().replace("dosage", "");
            dosageNewJson[mon] = json.grazngCatleViewData.find(
              (dsge) => dsge.month.toString() === dosageMonthName.toString()
            ).dosage;
          });

          setDosageCount({
            ...dosageCount,
            ...dosageNewJson,
          });

          if (fdQltyExists && frgeCountExists) {
            verifyDone("green");
            setGrFeedsSaveEnable(true);
          } else {
            verifyDone("grey");
            setGrFeedsSaveEnable(false);
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const updateFeeds = () => {
    setLoading(true);

    const animalTypesObj = {
      feedQualityObj: feedQualityCount,
      forageObj: forageCount,
      fdAddtveObj: additiveTypeVal,
      dsgeObj: dosageCount,
      months: monthsArr,
      popid: id,
    };
    post(UPDATE_GRAZING_FEEDS, animalTypesObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          setSaveSuccessMsg(true);
          // fetchGrazingFeeds();

          // anagObjectChanged(true);
          verifyDone("green");
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchFeedQualityLookUps();
  }, []);

  useEffect(() => {
    setGrPopFlag(false);

    fetchGrazingFeeds();
  }, [grPopFlag]);

  useEffect(() => {
    isFeedValid();
  }, [feedQualityCount, forageCount]);

  return (
    <CFBox width="100%">
      {loading && <CFLoading local />}
      {/* Main form */}
      <CFBox width="100%">
        {monthsArr.length > 0 && (
          <Grid container direction="row" xs={12} sm={12} md={12}>
            <Grid item xs={12}>
              <CFTypography
                sx={{
                  fontSize: "0.8em",

                  color: info.dullFocus,

                  marginBottom: "0.7em",
                }}
              >
                Select the monthly forage quality and enter the % of the diet
                that is consumed through grazing in the forage content row.
                Optional: Include feed additives where applicable
              </CFTypography>
            </Grid>
          </Grid>
        )}

        <Grid container direction="row" xs={12} sm={12} md={12}>
          <Grid container direction="row">
            <Grid item xs={1}>
              <Stack direction="column">
                {monthsArr.map((month) => (
                  <CFBox
                    sx={{
                      backgroundColor: colors.background.header,
                      padding: "0.43em",
                      marginTop: month === monthsArr[0] ? "1.6em" : ".08em",
                      boxShadow: "0px 1px 3px 0px #bbb",
                      marginBottom: ".7em",
                    }}
                  >
                    <CFTypography
                      sx={{
                        fontSize: "15px",
                        color: info.dullFocus,
                        fontWeight: "bold",
                        padding: "10px",
                      }}
                    >
                      {month}
                    </CFTypography>
                  </CFBox>
                ))}
              </Stack>
            </Grid>
            {monthsArr.length > 0 && (
              <Grid item xs={3}>
                <CFBox style={{ display: "flex" }}>
                  <CFTypography
                    sx={{
                      fontSize: "12px",
                      color: info.dullFocus,
                      fontWeight: "bold",
                      marginBottom: "0.5em",
                      marginTop: "0.5em",
                      marginLeft: "8em",
                    }}
                  >
                    Forage Quality
                  </CFTypography>
                  <Tooltip title="Copy data across months">
                    <ArrowCircleDownTwoToneIcon
                      onClick={() => copyFeedQuality()}
                      id="copyFdQlty"
                      color="primary"
                      fontSize="medium"
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </CFBox>

                {monthsArr.map((month) => (
                  <Stack direction="column">
                    <CFBox
                      sx={{
                        backgroundColor: colors.background.header,
                        padding: "0.6em",
                        marginLeft: "2em",
                        display: "flex",
                        marginBottom: ".5em",
                      }}
                    >
                      <CFLookupPicker
                        key={`feedQualityCount${month}`}
                        name={`feedQuality${month}`}
                        label="Select Feed Quality"
                        // required
                        style={{
                          width: "100%",
                        }}
                        value={feedQualityCount[month]}
                        onChange={(event, newValue) => {
                          setFeedQuality(month, newValue);
                        }}
                        options={feedQualityList}
                      />
                    </CFBox>
                  </Stack>
                ))}
              </Grid>
            )}
            {monthsArr.length > 0 && (
              <Grid item xs={2}>
                <CFBox style={{ display: "flex" }}>
                  <CFTypography
                    sx={{
                      fontSize: "12px",

                      color: info.dullFocus,
                      fontWeight: "bold",

                      marginBottom: "0.5em",
                      marginTop: "0.5em",
                      marginLeft: "4em",
                    }}
                  >
                    Forage Content
                  </CFTypography>
                  <Tooltip title="Copy data across months">
                    <ArrowCircleDownTwoToneIcon
                      onClick={() => copyForage()}
                      id="copyForage"
                      color="primary"
                      fontSize="medium"
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </CFBox>

                {monthsArr.map((month) => (
                  <Stack direction="column">
                    <CFBox
                      sx={{
                        backgroundColor: colors.background.header,
                        padding: "0.57em",
                        marginLeft: "2em",
                        display: "flex",
                        marginBottom: ".5em",
                      }}
                    >
                      <CFInput
                        sx={{
                          fieldset: {
                            borderColor: info.dullFocus,
                            borderStyle: "solid",
                            borderWidth: "2px",
                          },
                        }}
                        style={{ width: "6em" }}
                        color="primary"
                        type="number"
                        InputProps={{
                          inputProps: { min: 0, max: 100 },
                        }}
                        id={`forageCount${month}`}
                        name={`forage${month}`}
                        value={forageCount[`forage${month}`]}
                        defaultValue="0"
                        onChange={handleForageChange}
                        // inputProps={{ disabled: value }}
                        placeholder=""
                        fullWidth
                      />
                      <CFTypography
                        sx={{
                          fontSize: "8px",
                          color: info.dullFocus,
                          fontWeight: "bold",
                          marginBottom: "0.5em",
                          marginTop: "0.5em",
                          marginLeft: "0.5em",
                          padding: "3px",
                        }}
                      >
                        (%)
                      </CFTypography>
                    </CFBox>
                  </Stack>
                ))}
              </Grid>
            )}
            {monthsArr.length > 0 && (
              <Grid item xs={3} sm={3} md={3}>
                <Stack direction="column">
                  <CFBox style={{ display: "flex" }}>
                    <CFTypography
                      sx={{
                        fontSize: "12px",
                        color: info.dullFocus,
                        fontWeight: "bold",
                        marginBottom: "0.5em",
                        marginTop: "0.5em",
                        marginLeft: "6em",
                      }}
                    >
                      Feed Additive Type
                    </CFTypography>
                    <Tooltip title="Copy data across months">
                      <ArrowCircleDownTwoToneIcon
                        onClick={() => copyFeedAdditives()}
                        id="copyAddtves"
                        color="primary"
                        fontSize="medium"
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                    <UserGuidePopup
                      content="Feed additive impacts to emissions should not be summed as there are not
      sufficient data to conclude if combined practices would be effective. Feed
      additive impacts to emissions past the duration of the literature/studies
      cited (60-180 days) is unknown; therefore, emission reductions should not
      be considered in perpetuity. While studies exist showing the potential to
      reduce emissions, it is important to note that the drugs mentioned do not
      claim, nor may they claim, emissions reductions. Use of nitrates can
      contribute to higher probability of animal fatalities and should only be
      done under the supervision of a trained and certified nutritionist."
                      userGuideLabel="Feed Additives"
                      variant="h4"
                      sx={{ padding: ".1em" }}
                    />
                  </CFBox>
                  {monthsArr.map((month) => (
                    <Stack direction="row">
                      <CFBox
                        sx={{
                          backgroundColor: colors.background.header,
                          padding: "0.6em",
                          marginLeft: "2em",
                          display: "flex",
                          marginBottom: ".5em",
                        }}
                      >
                        <CFLookupPicker
                          key={`additiveTypeVal${month}`}
                          name={`additiveType${month}`}
                          label="Select Additive Type"
                          // required
                          style={{
                            width: "9em",
                          }}
                          value={additiveTypeVal[`additiveType${month}`]}
                          onChange={(event, newValue) => {
                            setAdditiveType(month, newValue);
                          }}
                          options={additiveTypeList}
                        />
                      </CFBox>
                    </Stack>
                  ))}
                </Stack>
              </Grid>
            )}

            {monthsArr.length > 0 && (
              <Grid item xs={2.2} sm={2.2} md={2.2}>
                <Stack direction="column">
                  <CFBox style={{ display: "flex" }}>
                    <CFTypography
                      sx={{
                        fontSize: "12px",

                        color: info.dullFocus,
                        fontWeight: "bold",

                        marginBottom: "0.5em",
                        marginTop: "0.5em",
                        marginLeft: "4em",
                      }}
                    >
                      Dosage
                    </CFTypography>
                    <Tooltip title="Copy data across months">
                      <ArrowCircleDownTwoToneIcon
                        onClick={() => copyDosages()}
                        id="copyDosage"
                        color="primary"
                        fontSize="medium"
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </CFBox>
                  {monthsArr.map((month) => (
                    <Stack direction="row">
                      <CFBox
                        sx={{
                          backgroundColor: colors.background.header,
                          padding: "0.57em",
                          marginLeft: "2em",
                          display: "flex",
                          marginBottom: ".5em",
                        }}
                        style={{ width: "9.5em" }}
                      >
                        <CFInput
                          sx={{
                            fieldset: {
                              borderColor: info.dullFocus,
                              borderStyle: "solid",
                              borderWidth: "2px",
                            },
                          }}
                          style={{ width: "6em" }}
                          type="number"
                          InputProps={{
                            inputProps: { min: 0, max: 100 },
                          }}
                          color="primary"
                          id={`dosageCount${month}`}
                          name={`dosage${month}`}
                          defaultValue="0"
                          value={dosageCount[`dosage${month}`]}
                          onChange={handleDosageChange}
                          // inputProps={{ disabled: value }}
                          disabled={
                            additiveTypeVal[`additiveType${month}`]?.id ===
                              215203 ||
                            additiveTypeVal[`additiveType${month}`]?.id ===
                              215204 ||
                            additiveTypeVal[`additiveType${month}`]?.id ===
                              215206
                          }
                          placeholder=""
                          fullWidth
                        />
                        <CFTypography
                          sx={{
                            fontSize: "8px",
                            color: info.dullFocus,
                            fontWeight: "bold",
                            marginBottom: "0.5em",
                            marginTop: "0.5em",
                            padding: "3px",
                          }}
                          id={`additiveUnits${month}`}
                          key={`units${month}`}
                        >
                          {additiveUnits[`units${month}`]}
                        </CFTypography>
                      </CFBox>
                    </Stack>
                  ))}
                </Stack>
              </Grid>
            )}
            <Grid container py={2} px={2} justifyContent="space-around">
              <Grid item xs={12} sm={12} md={12}>
                <CFButton
                  type="submit"
                  color="progress"
                  sx={{
                    float: "right",
                  }}
                  onClick={() => {
                    updateFeeds();
                  }}
                  disabled={!grFeedsSaveEnable}
                >
                  Save
                </CFButton>
              </Grid>
            </Grid>
            {saveSuccessMsg && (
              <Grid container justifyContent="space-around">
                <Grid item xs={2} sm={2} md={2} alignItems="center">
                  <CFBox id="feedSuccessMessage">
                    <CFTypography
                      sx={{
                        fontSize: "15px",
                        color: "green",

                        marginBottom: "0.5em",
                        marginTop: "0.5em",
                      }}
                    >
                      Save was successful.
                    </CFTypography>
                  </CFBox>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CFBox>
    </CFBox>
  );
}
