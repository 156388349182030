/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Dialog, Grid, IconButton, Tooltip } from "@mui/material";
import CFarmPage from "components/CFarmParts/CFarmPage";

import { updateLoading } from "store/reducers/mask";
import { useQuery, popupError, isDemo } from "utils/generic";
import { post } from "utils/api";
import { CROPLANDS_FETCH_LOCATIONLIST } from "constants/api";
import CFRoadmap from "components/CFarmParts/CFarmPage/CFRoadmap/Roadmap";
import CFButton from "components/CFButton";
import CFTypography from "components/CFTypography";
import CFDialog from "components/CFDialog";
import CFBox from "components/CFBox";
import { Close } from "@mui/icons-material";
import CFStep from "components/CFarmParts/CFStep";
import {
  selectedActivities,
  reportProps,
} from "components/CFarmParts/CFarmPage/CFRoadmap/roadmap.config";
import HistoricManagement from "./HistoricManagement/HistoricManagement";
import BaselineManagement from "./BaselineManagement/BaselineManagement";
import SelectField from "./SelectField";
import FloatingToTopArrow from "../../components/CroplandsComponents/FloatingToTopArrow/FloatingToTopArrow";
import CroplandStyle from "./CroplandStyle";

function CroplandsHistoryAndCurrentPage() {
  const timelineActivities = [...selectedActivities(), ...reportProps];
  const timelineIndexOfScenarios = timelineActivities.findIndex(
    (x) => x.id === 10 && x.stage === 3
  );
  const query = useQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user);
  const [reportOrFutureOpen, setReportOrFutureOpen] = useState(false);

  const [scenarioFetchComplete, setscenarioFetchComplete] = useState(false);
  const [HistoryAndBaselineComplete, SetHistoryAndBaselineComplete] =
    useState(false);
  const [selectedField, setSelectedField] = useState({
    id: 0,
    name: "Select a field",
    baselineId: 0,
    scenarios: [],
    baselineStart: 2000,
  });
  const [fields, setField] = useState([
    {
      id: 0,
      name: "Select a field",
      baselineId: 0,
      scenarios: [],
      baselineStart: 2000,
    },
  ]);
  const [locationMapFeatureHolder, setLocationMapFeatureHolder] = useState({});
  const refs = {
    "3a": React.createRef("cropland3a"),
    "3b": React.createRef("cropland3b"),
    "3c": React.createRef("cropland3c"),
    "3d": React.createRef("cropland3d"),
    "3f": React.createRef("cropland3d"),
  };

  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  const goToPrevious = (initiatingStep) => {
    refs[initiatingStep].current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const setLoading = (message) => {
    if (message !== "") {
      dispatch(updateLoading({ loading: true }));
    } else {
      dispatch(updateLoading({ loading: false }));
    }
  };

  const [selectedOnceAlready, setSelectedOnceAlready] = useState(false);
  const autoselectFirstUnfinishedField = () => {
    if (fields.length > 1) {
      if (!selectedOnceAlready) {
        setSelectedOnceAlready(true);
        for (let i = 0; i < fields.length; i += 1) {
          if (!fields[i].historyAndBaselineComplete) {
            setSelectedField(fields[i]);

            return;
          }
        }

        setSelectedField(fields[0]);
      }
    }
  };
  let oneFetchAtATime = false;
  const fetchProjectInformation = () => {
    if (!oneFetchAtATime) {
      oneFetchAtATime = true;

      dispatch(updateLoading({ loading: true }));

      post(CROPLANDS_FETCH_LOCATIONLIST, {
        user: currentUser,
        projectId: query.get("project"),
      })
        .then((res) => {
          if (res.error) {
            popupError(`Fetching Locations Error ${res.error}`, dispatch);
          } else {
            const appliedField = res.data.fieldLocationData.map((x) => ({
              ...x,
              dataComplete: x.historyAndBaselineComplete,
            }));

            setField(appliedField);

            setSelectedField({
              ...appliedField.filter((x) => x.id === selectedField.id)[0],
            });
            setLocationMapFeatureHolder(res.data.locationHolder);
            setscenarioFetchComplete(appliedField.id !== 0);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(updateLoading({ loading: false }));
          oneFetchAtATime = false;
        });
    }
  };

  const Next = () => {
    // next logic for cropland history and current management:
    // 1st if there is any field that is not dataComplete, select the first such field and scroll the screen to the top
    // 2nd if the user has any scenario data, go to crop scenarios page   navigate("/croplandsScenario")
    // 3rd open a "go to scenarios or go to report" popup
    let HasScenarioData = false;
    for (let i = 0; i < fields.length; i += 1) {
      if (!fields[i].dataComplete) {
        // go to first detected unfinished field
        setSelectedField(fields[i]);
        goToTop();
        return;
      }
      if (!HasScenarioData && fields[i]?.scenarios?.length > 0) {
        HasScenarioData = true;
      }
    }
    if (HasScenarioData) {
      // go to scenarios page
      navigate("/croplandsScenario");
    }
    setReportOrFutureOpen(true);
  };

  const checkForPageComplete = () => {
    let ready = true;
    for (let i = 0; i < fields.length; i += 1) {
      if (fields[i].id !== 0) {
        ready = ready && fields[i].historyAndBaselineComplete;
      }
    }

    SetHistoryAndBaselineComplete(ready);
  };

  useEffect(() => {
    autoselectFirstUnfinishedField();
    checkForPageComplete();
  }, [fields]);

  useEffect(() => {
    fetchProjectInformation();
  }, []);

  // Demo info popup
  const [demoPopupShown, setDemoPopupShown] = useState(false);
  const [localPopupState, updateLocalPopup] = useState({});
  useEffect(() => {
    if (isDemo(10, currentUser) && !demoPopupShown) {
      setDemoPopupShown(true);
      updateLocalPopup({
        title: "Croplands Demo Project",
        style: { minWidth: "750px", maxHeight: "75vh", overflow: "auto" },
        zIndex: 10,
        children: [
          <Grid container direction="column" alignItems="center" p={2}>
            <CFTypography variant="h4" py={1}>
              Historic Management
            </CFTypography>
            <p>
              For the purposes of this demonstration, we assumed the parcel was
              in a long-term, non-irrigated, upland cropping system. Between
              1980 and 2000, we assumed the system was intensively tilled,
              non-irrigated annual crops in rotation.
            </p>
            <br />
            <CFTypography variant="h4" py={1}>
              Baseline Management
            </CFTypography>
            <p>
              We assumed the parcel was in a grain corn-soybean rotation. Corn
              was intensively tilled with 160 lbs of nitrogen from anhydrous
              ammonia at the time of planting in the 1st week of May. No manure
              or compost was applied, there was no irrigation, liming, or
              burning. Soybean was reduce-tilled with no fertilizer, irrigation,
              organic matter/compost, liming or burning.
            </p>
          </Grid>,
        ],
        showPopup: true,
        closeAction: () => {
          updateLocalPopup({ showPopup: false });
        },
      });
    }
  }, [currentUser.activeProject]);

  return (
    <CFarmPage bgImage="" pageName="">
      <div ref={refs["1"]} />
      <CFRoadmap />
      <Grid
        container
        spacing={2}
        sx={{ marginTop: "1em", width: CroplandStyle.width }}
      >
        <Grid item xs={12} xl={3}>
          <div ref={refs["2"]} />
          <SelectField
            locationMapFeatureHolder={locationMapFeatureHolder}
            fields={fields}
            setSelectedField={setSelectedField}
            selectedField={selectedField}
            stepNumber="1"
            goToPrevious={goToPrevious}
            activityTypeId={10}
          />
        </Grid>
        <Grid item xs={12} xl={9}>
          <div ref={refs["3c"]} />
          <HistoricManagement
            field={selectedField}
            stepNumber="2"
            goToPrevious={goToPrevious}
            baselineStart={selectedField.baselineStart}
            fetchProjectInformation={fetchProjectInformation}
          />
        </Grid>
      </Grid>
      <div ref={refs["4"]} />

      <BaselineManagement
        allOtherFields={fields}
        field={selectedField}
        stepNumber="3"
        goToPrevious={goToPrevious}
        fetchProjectInformation={fetchProjectInformation}
      />
      <Grid
        container
        item
        sx={{ marginTop: "1em", width: CroplandStyle.width }}
        spacing={2}
        direction="row"
        alignItems="right"
        justifyContent="right"
      >
        {/* Left buttons */}
        <Grid item container justifyContent="flex-start" xs={4} pr={1}>
          <Grid item>
            <CFButton
              color="secondary"
              onClick={() => {
                navigate("/map-croplands");
              }}
              sx={{
                margin: "1em",
                padding: "1.5em",
              }}
            >
              Back to Field Locations
            </CFButton>
          </Grid>
        </Grid>
        {/* Right buttons */}
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-end"
          xs={8}
          pr={1}
        >
          <Grid item xs={4}>
            <CFButton
              // disabled={!HistoryAndBaselineComplete}
              color="progress"
              onClick={Next}
              sx={{ float: "right", margin: "1em", padding: "1.5em" }}
            >
              Next
            </CFButton>
          </Grid>
        </Grid>
      </Grid>

      <Tooltip title="Back to Top" placement="top">
        <div>
          <FloatingToTopArrow
            onClick={goToTop}
            sx={{ position: "fixed", right: "2em", zIndex: 999, bottom: "5em" }}
          />
        </div>
      </Tooltip>

      <Dialog
        open={reportOrFutureOpen}
        fullWidth
        maxWidth="lg"
        sx={{ padding: "3em", zIndex: 5 }}
        onClose={() => setReportOrFutureOpen(false)}
      >
        <IconButton
          onClick={() => setReportOrFutureOpen(false)}
          style={{
            position: "absolute",
            right: ".5em",
            margin: ".5em",
            padding: 0,
          }}
        >
          <Tooltip title="Close">
            <Close />
          </Tooltip>
        </IconButton>

        <CFBox sx={{ padding: "2em" }}>
          <Grid container direction="row">
            {/* Window Header */}
            <Grid
              container
              item
              xs={12}
              sx={{ paddingLeft: "1em", paddingTop: "1em" }}
            />
            <CFStep
              stepLabel="Continue"
              useGuideWidth={`${Math.min(1440, window.innerWidth)}px`}
              subText={
                <div>
                  Use the buttons below to either create a new scenario
                  (optional) or continue to{" "}
                  {timelineActivities[timelineIndexOfScenarios + 1]?.label ===
                  "Report"
                    ? "the"
                    : ""}{" "}
                  {timelineActivities[timelineIndexOfScenarios + 1]?.label}.
                </div>
              }
            />
          </Grid>

          <Grid container justifyContent="space-between">
            <Grid item xs={6}>
              <CFButton
                color="secondary"
                onClick={() => setReportOrFutureOpen(false)}
                sx={{ margin: "1em", padding: "1.5em" }}
              >
                Cancel
              </CFButton>
            </Grid>
            <Grid
              container
              justifySelf="flex-end"
              justifyContent="space-around"
              item
              xs={6}
            >
              <Grid item xs={7}>
                <CFButton
                  color="progress"
                  to={timelineActivities[timelineIndexOfScenarios]?.to || "/"}
                  component={Link}
                  sx={{ margin: "1em", padding: "1.5em" }}
                >
                  Add Scenario (optional)
                </CFButton>
              </Grid>
              <Grid item xs={5}>
                <CFButton
                  color="progress"
                  to={
                    timelineActivities[timelineIndexOfScenarios + 1]?.to || "/"
                  }
                  component={Link}
                  sx={{ margin: "1em", padding: "1.5em" }}
                >
                  {`Go to ${
                    timelineActivities[timelineIndexOfScenarios + 1]?.label ??
                    "Report"
                  }`}
                </CFButton>
              </Grid>
            </Grid>
          </Grid>
        </CFBox>
      </Dialog>

      {localPopupState && localPopupState.showPopup && (
        <CFDialog
          {...localPopupState}
          updatePopup={(e) => updateLocalPopup(e)}
        />
      )}
    </CFarmPage>
  );
}
export default CroplandsHistoryAndCurrentPage;
