// React
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// MUI
import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";

import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import Tooltip from "@mui/material/Tooltip";
import { set } from "lodash/fp";
import colors from "assets/theme/base/colors";

import CFInput from "components/CFInput";
// Our Components
import CFBox from "components/CFBox";

import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";
import { updateLoading } from "store/reducers/mask";
import { post } from "utils/api";
import { popupError } from "utils/generic";
import { UPDATE_SHEEP_DMI, FETCH_SHEEP_DMI } from "constants/api";
import CFLoading from "components/CFLoading";
// Constants

export default function SheepDMIPopulation({
  id,
  yearValue,
  isCopy,
  animalCategory,
  animalCategoryId,
  groupId,
  anagObject,
  anagObjectChanged,
  verifyDone,
}) {
  const dispatch = useDispatch();

  const { info } = colors;

  const [population, setPopulation] = useState([]);
  const [averageBodyWeight, setAverageBodyWeight] = useState("");

  const [saveSuccessMsg, setSaveSuccessMsg] = useState(false);
  const [sheepPopSave, setSheepPopSave] = useState(false);
  const [loading, setLoading] = useState(false);

  const monthsArr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const handleSheepPopulationChange = (e) => {
    setPopulation({
      ...population,
      [e.target.name]: e.target.value,
    });
  };
  const copySheepPopulation = () => {
    const janPop = population.popsheepcountJan;

    const popMonthsArr = [];
    monthsArr.map((month) => popMonthsArr.push([`popsheepcount${month}`]));
    const popNewObj = {};
    popMonthsArr.forEach((mon) => {
      popNewObj[mon] = janPop;
    });

    setPopulation({ ...population, ...popNewObj });
  };
  const handleAvgBodyWeightChange = (event) => {
    setAverageBodyWeight(event.target.value);
  };

  const isSheepPopValid = () => {
    let isComplete = false;
    const popExists =
      anagObject.selectedScenario === "Baseline" ||
      (anagObject.selectedScenario !== "Baseline" && !isCopy)
        ? Object.entries(population).some((x) => x[1] > 0)
        : Object.entries(population).some((x) => x[1] >= 0);
    const avgBwxists =
      anagObject.selectedScenario === "Baseline" ||
      (anagObject.selectedScenario !== "Baseline" && !isCopy)
        ? averageBodyWeight > 0
        : averageBodyWeight >= 0;

    if (popExists && avgBwxists) {
      isComplete = true;
    }

    setSheepPopSave(isComplete);
  };
  const fetchSheepDMIPopulation = () => {
    setLoading(true);
    const popObj = {
      popid: id,
    };
    post(FETCH_SHEEP_DMI, popObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const json = JSON.parse(res.data);
          const pop = json.SheepDMI;
          const popmonths = pop.SheepDMIPopMonths;
          let popCountExists = false;

          setAverageBodyWeight(pop.AvgBodyWeight);

          const popMonthsJson = [];

          const popNewJson = {};

          monthsArr.map((month) =>
            popMonthsJson.push([`popsheepcount${month}`])
          );

          popMonthsJson.forEach((mon) => {
            const monthName = mon.toString().replace("popsheepcount", "");

            popNewJson[mon] = popmonths.find(
              (popln) => popln.Month.toString() === monthName.toString()
            ).PopCount;
          });

          popCountExists =
            anagObject.selectedScenario === "Baseline" ||
            (anagObject.selectedScenario !== "Baseline" && !isCopy)
              ? popmonths.some((el) => el.PopCount > 0) > 0
              : popmonths.some((el) => el.PopCount > 0) >= 0;
          setPopulation({ ...population, ...popNewJson });
          const avgWght =
            anagObject.selectedScenario === "Baseline" ||
            (anagObject.selectedScenario !== "Baseline" && !isCopy)
              ? pop.AvgBodyWeight > 0
              : pop.AvgBodyWeight >= 0;
          if (popCountExists && avgWght) {
            verifyDone("green");
            setSheepPopSave(true);
          } else {
            verifyDone("grey");
            setSheepPopSave(false);
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const updateSheepDMIPops = () => {
    setLoading(true);
    const animalTypesObj = {
      popObj: population,
      avgBdyWght: averageBodyWeight,
      popid: id,
    };
    post(UPDATE_SHEEP_DMI, animalTypesObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          setSaveSuccessMsg(true);
          verifyDone("green");
          // fetchSheepDMIPopulation();

          // anagObjectChanged(true);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSheepDMIPopulation();
  }, [anagObject.activeAnimalCategories]);

  useEffect(() => {
    isSheepPopValid();
  }, [population, averageBodyWeight]);

  return (
    <CFBox width="100%">
      {loading && <CFLoading local />}
      <Grid container item>
        {/* Main form */}
        <CFBox width="100%">
          <Grid container direction="row" xs={12} sm={12} md={12}>
            <Grid container direction="row">
              <Grid item container xs={12} direction="column">
                <Grid item xs={12}>
                  <CFTypography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: info.dullFocus,
                    }}
                  >
                    Population Details
                  </CFTypography>
                  <CFTypography
                    sx={{
                      fontSize: "0.7em",

                      color: info.dullFocus,

                      marginBottom: "0.7em",
                    }}
                  >
                    Enter the monthly population of sheep. Use the arrows to
                    copy data across months.
                  </CFTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid
                item
                container
                xs={12}
                alignItems="center"
                direction="column"
              >
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    gap={1}
                    style={{ marginLeft: "12%" }}
                  >
                    {monthsArr.map((month) => (
                      <CFTypography
                        sx={{
                          fontSize: "1.2em",

                          color: info.dullFocus,
                          fontWeight: "bold",
                          padding: "9.5px",
                        }}
                      >
                        {month}
                      </CFTypography>
                    ))}
                  </Stack>
                </Grid>
              </Grid>
              <Grid item container xs={12} alignItems="center" direction="row">
                <Grid item xs={12}>
                  <Tooltip title="Copy data across months">
                    <ArrowCircleRightTwoToneIcon
                      onClick={() => copySheepPopulation()}
                      id="copySheepPopulation"
                      color="primary"
                      fontSize="medium"
                      style={{
                        cursor: "pointer",
                        marginLeft: "20%",
                        marginTop: "1%",
                      }}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item container xs={12} alignItems="center" direction="row">
                <Grid item xs={2}>
                  <CFTypography
                    sx={{
                      fontSize: "15px",
                      color: info.dullFocus,
                      fontWeight: "bold",

                      marginBottom: "0.5em",
                      marginTop: "0.5em",
                    }}
                  >
                    Population
                  </CFTypography>
                </Grid>
                <Grid item xs={10}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    style={{ marginLeft: "3%" }}
                  >
                    {monthsArr.map((month) => (
                      <CFInput
                        sx={{
                          padding: "10px",
                          fieldset: {
                            borderColor: info.dullFocus,
                            borderStyle: "solid",
                            borderWidth: "2px",
                          },
                        }}
                        color="primary"
                        id={`popSheepCount${month}`}
                        name={`popsheepcount${month}`}
                        defaultValue="0"
                        value={population[`popsheepcount${month}`]}
                        onChange={handleSheepPopulationChange}
                        placeholder=""
                        fullWidth
                      />
                    ))}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} direction="column">
              <Grid item xs={12}>
                <Grid item xs={8}>
                  <CFBox
                    sx={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      marginTop: "1em",
                      display: "flex",
                      marginBottom: "1em",
                    }}
                  >
                    <CFTypography
                      sx={{
                        fontSize: "15px",
                        color: info.dullFocus,
                        fontWeight: "bold",
                      }}
                    >
                      Average body weight :
                    </CFTypography>

                    <CFInput
                      sx={{
                        padding: "10px",
                        fieldset: {
                          borderColor: info.dullFocus,
                          borderStyle: "solid",
                          borderWidth: "2px",
                        },
                      }}
                      color="primary"
                      id="avgBodyWeight"
                      name="avgBodyWeight"
                      value={averageBodyWeight}
                      onChange={handleAvgBodyWeightChange}
                      placeholder=""
                      style={{ width: "30%", marginLeft: "2em" }}
                      InputProps={{
                        endAdornment: "lbs",
                      }}
                    />
                  </CFBox>
                </Grid>
              </Grid>
            </Grid>

            <Grid container py={2} px={2} justifyContent="space-around">
              <Grid item xs={12} sm={12} md={12}>
                <CFButton
                  type="submit"
                  sx={{
                    float: "right",
                  }}
                  color="progress"
                  onClick={() => updateSheepDMIPops()}
                  disabled={!sheepPopSave}
                >
                  Save
                </CFButton>
              </Grid>
            </Grid>
            {saveSuccessMsg && (
              <Grid container justifyContent="space-around">
                <Grid item xs={2} sm={2} md={2} alignItems="center">
                  <CFBox id="successMessage">
                    <CFTypography
                      sx={{
                        fontSize: "15px",
                        color: "green",

                        marginBottom: "0.5em",
                        marginTop: "0.5em",
                      }}
                    >
                      Save was successful.
                    </CFTypography>
                  </CFBox>
                </Grid>
              </Grid>
            )}
          </Grid>
        </CFBox>
      </Grid>
    </CFBox>
  );
}
