// React
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// MUI
import {
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Grid,
} from "@mui/material";
import Stack from "@mui/material/Stack";

import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Tooltip from "@mui/material/Tooltip";
import { heIL } from "@mui/material/locale";
import colors from "assets/theme/base/colors";
import CFDialog from "components/CFDialog";
import CFInput from "components/CFInput";
// Our Components
import CFBox from "components/CFBox";

import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";

import { post } from "utils/api";
import { popupError } from "utils/generic";
import {
  UPDATE_FEEDLOT_POPULATIONS,
  FETCH_ALL_FEEDLOT_POPULATIONS,
} from "constants/api";
import CFLoading from "components/CFLoading";
// Constants

export default function FeedLotPopulation({
  fdlotid,
  fdlotherdName,
  fdlotyearValue,
  fdlotcopy,
  fdlotanimalCategory,
  fdlotanimalCategoryId,
  fldlotRation,
  fdlotExpanded,
  fdlotanagObject,
  fdlotanagObjectChanged,
  fdlotPopComplete,
  fdlotPopEdited,
  expanded,
  expandToggle,
}) {
  const dispatch = useDispatch();

  const { info } = colors;

  const [localPopupState, updateLocalPopup] = useState({});

  const [steerPopulation, setSteerPopulation] = useState([]);
  const [heiferPopulation, setHeiferPopulation] = useState([]);
  const [saveSuccessMsg, setSaveSuccessMsg] = useState(false);
  const [loading, setLoading] = useState(false);

  const [fdLotEnableSave, setFdLotEnableSave] = useState(false);
  const monthsArr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const isPopValid = () => {
    let isComplete = false;
    const steerPopExists =
      fdlotanagObject.selectedScenario === "Baseline" ||
      (fdlotanagObject.selectedScenario !== "Baseline" && !fdlotcopy)
        ? Object.entries(steerPopulation).some((x) => x[1] > 0)
        : Object.entries(steerPopulation).some((x) => x[1] >= 0);
    const heiferPopExists =
      fdlotanagObject.selectedScenario === "Baseline" ||
      (fdlotanagObject.selectedScenario !== "Baseline" && !fdlotcopy)
        ? Object.entries(heiferPopulation).some((x) => x[1] > 0)
        : Object.entries(heiferPopulation).some((x) => x[1] >= 0);

    if (steerPopExists || heiferPopExists) {
      isComplete = true;
    }

    setFdLotEnableSave(isComplete);
  };
  const handleHeiferPopulationChange = (e) => {
    setHeiferPopulation({
      ...heiferPopulation,
      [e.target.name]: e.target.value,
    });
  };
  const copyHeiferPopulation = () => {
    const janPop = heiferPopulation.heiferpopcountJan;

    const popMonthsArr = [];
    monthsArr.map((month) => popMonthsArr.push([`heiferpopcount${month}`]));
    const popNewObj = {};
    popMonthsArr.forEach((mon) => {
      popNewObj[mon] = janPop;
    });

    setHeiferPopulation({ ...heiferPopulation, ...popNewObj });
  };
  const handleSteerPopulationChange = (e) => {
    setSteerPopulation({
      ...steerPopulation,
      [e.target.name]: e.target.value,
    });
  };
  const copySteerPopulation = () => {
    const janPop = steerPopulation.steerpopcountJan;

    const popMonthsArr = [];
    monthsArr.map((month) => popMonthsArr.push([`steerpopcount${month}`]));
    const popNewObj = {};
    popMonthsArr.forEach((mon) => {
      popNewObj[mon] = janPop;
    });

    setSteerPopulation({ ...steerPopulation, ...popNewObj });
  };

  const fetchFeedLotPopulation = () => {
    setLoading(true);

    const popObj = {
      popid: fdlotid,
      rationName: fdlotherdName,
    };
    post(FETCH_ALL_FEEDLOT_POPULATIONS, popObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const pops = JSON.parse(res.data);

          const steerPopMonthsJson = [];
          const heiferPopMonthsJson = [];

          const steerPopNewJson = {};
          const heiferPopNewJson = {};
          let steerPopExists = false;
          let heiferPopExists = false;

          monthsArr.map((month) =>
            steerPopMonthsJson.push([`steerpopcount${month}`])
          );
          monthsArr.map((month) =>
            heiferPopMonthsJson.push([`heiferpopcount${month}`])
          );
          steerPopMonthsJson.forEach((mon) => {
            const monthName = mon.toString().replace(`steerpopcount`, "");

            steerPopNewJson[mon] = pops.find(
              (popln) => popln.Month.toString() === monthName.toString()
            ).SteerPopCount;
          });

          setSteerPopulation({ ...steerPopulation, ...steerPopNewJson });
          steerPopExists =
            fdlotanagObject.selectedScenario === "Baseline" ||
            (fdlotanagObject.selectedScenario !== "Baseline" && !fdlotcopy)
              ? pops.some((el) => el.SteerPopCount > 0)
              : pops.some((el) => el.SteerPopCount >= 0);
          heiferPopMonthsJson.forEach((mon) => {
            const monthName = mon.toString().replace(`heiferpopcount`, "");
            heiferPopNewJson[mon] = pops.find(
              (popln) => popln.Month.toString() === monthName.toString()
            ).HeiferPopCount;
          });

          setHeiferPopulation({ ...heiferPopulation, ...heiferPopNewJson });
          heiferPopExists =
            fdlotanagObject.selectedScenario === "Baseline" ||
            (fdlotanagObject.selectedScenario !== "Baseline" && !fdlotcopy)
              ? pops.some((el) => el.HeiferPopCount > 0)
              : pops.some((el) => el.HeiferPopCount >= 0);
          if (steerPopExists || heiferPopExists) {
            setFdLotEnableSave(true);
          } else {
            setFdLotEnableSave(false);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const updateFeedLotPops = () => {
    setLoading(true);
    fdlotPopEdited(false);
    const animalTypesObj = {
      steerPopObj: steerPopulation,
      heiferPopObj: heiferPopulation,
      herd: fdlotherdName,
      popid: fdlotid,
    };
    post(UPDATE_FEEDLOT_POPULATIONS, animalTypesObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          setSaveSuccessMsg(true);

          fdlotPopComplete(true);

          fdlotPopEdited(true);
          // fetchFeedLotPopulation();
          // fdlotanagObjectChanged(true);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (fldlotRation === fdlotherdName) {
      fetchFeedLotPopulation();
    }
  }, [expanded]);

  useEffect(() => {
    isPopValid();
  }, [steerPopulation, heiferPopulation]);

  return (
    <CFBox width="100%">
      {loading && <CFLoading local />}
      <CFBox width="100%" style={{ marginTop: "1em" }}>
        <Accordion
          expanded={expanded === "Population"}
          onChange={expandToggle("Population")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: colors.text.light }} />}
            aria-controls="animalDetailsCard-content"
            id="animalDetailsCard-header"
            sx={{
              backgroundColor: colors.grey[500],
              borderStyle: "outset",
              borderRadius: ".3em",
              flexDirection: "row-reverse",
            }}
          >
            <Grid
              item
              container
              alignItems="center"
              justifyContent="flex-start"
            >
              {/* Year */}

              <CFBox
                xs={12}
                sx={{
                  textAlign: "left",
                  marginLeft: "1em",
                  marginRight: "1em",
                  fontSize: "medium",
                  fontWeight: "550",
                  color: colors.text.light,
                }}
              >
                Population
              </CFBox>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container item>
              {/* Main form */}
              <CFBox width="100%">
                <Grid container direction="row" xs={12} sm={12} md={12}>
                  <Grid container direction="row">
                    <Grid container direction="row" xs={12} sm={12} md={12}>
                      <Grid item xs={12}>
                        <CFTypography
                          sx={{
                            fontSize: "0.9em",
                            fontWeight: "bold",
                            color: info.dullFocus,

                            marginBottom: "0.7em",
                          }}
                        >
                          Ration Population
                        </CFTypography>
                        <CFTypography
                          sx={{
                            fontSize: "0.7em",

                            color: info.dullFocus,

                            marginBottom: "0.7em",
                          }}
                        >
                          Enter the monthly population of steers and heifers fed
                          this ration throughout the year. Use the arrows to
                          copy data across months.
                        </CFTypography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      id="step2box"
                      alignItems="center"
                    >
                      <Grid
                        item
                        container
                        xs={12}
                        alignItems="center"
                        direction="column"
                      >
                        <Grid item xs={12}>
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            gap={1}
                            style={{ marginLeft: "12%" }}
                          >
                            {monthsArr.map((month) => (
                              <CFTypography
                                sx={{
                                  fontSize: "1.03em",

                                  color: info.dullFocus,
                                  fontWeight: "bold",
                                  padding: "9.5px",
                                }}
                              >
                                {month}
                              </CFTypography>
                            ))}
                          </Stack>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        alignItems="center"
                        direction="row"
                      >
                        <Grid item xs={12}>
                          <Tooltip title="Copy data across months">
                            <ArrowCircleRightTwoToneIcon
                              onClick={() => copySteerPopulation()}
                              id="copyPopulation"
                              color="primary"
                              fontSize="medium"
                              style={{
                                cursor: "pointer",
                                marginLeft: "20%",
                                marginTop: "1%",
                              }}
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        alignItems="center"
                        direction="row"
                      >
                        <Grid item xs={2}>
                          <CFTypography
                            sx={{
                              fontSize: "15px",
                              color: info.dullFocus,
                              fontWeight: "bold",

                              marginBottom: "0.5em",
                              marginTop: "0.5em",
                            }}
                          >
                            Steers
                          </CFTypography>
                        </Grid>
                        <Grid item xs={10}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            gap={1}
                            style={{ marginLeft: "3%" }}
                          >
                            {monthsArr.map((month) => (
                              <CFInput
                                sx={{
                                  padding: "10px",
                                  fieldset: {
                                    borderColor: info.dullFocus,
                                    borderStyle: "solid",
                                    borderWidth: "2px",
                                  },
                                }}
                                color="primary"
                                id={`steerpopcount${month}`}
                                name={`steerpopcount${month}`}
                                defaultValue="0"
                                value={steerPopulation[`steerpopcount${month}`]}
                                onChange={handleSteerPopulationChange}
                                placeholder=""
                                fullWidth
                              />
                            ))}
                          </Stack>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        alignItems="center"
                        direction="row"
                      >
                        <Grid item xs={12}>
                          <Tooltip title="Copy data across months">
                            <ArrowCircleRightTwoToneIcon
                              onClick={() => copyHeiferPopulation()}
                              id="copyPopulation"
                              color="primary"
                              fontSize="medium"
                              style={{
                                cursor: "pointer",
                                marginLeft: "20%",
                                marginTop: "1%",
                              }}
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        alignItems="center"
                        direction="row"
                      >
                        <Grid item xs={2}>
                          <CFTypography
                            sx={{
                              fontSize: "15px",
                              color: info.dullFocus,
                              fontWeight: "bold",

                              marginBottom: "0.5em",
                              marginTop: "0.5em",
                            }}
                          >
                            Heifers
                          </CFTypography>
                        </Grid>
                        <Grid item xs={10}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            gap={1}
                            style={{ marginLeft: "3%" }}
                          >
                            {monthsArr.map((month) => (
                              <CFInput
                                sx={{
                                  padding: "10px",
                                  fieldset: {
                                    borderColor: info.dullFocus,
                                    borderStyle: "solid",
                                    borderWidth: "2px",
                                  },
                                }}
                                color="primary"
                                id={`heiferpopcount${month}`}
                                name={`heiferpopcount${month}`}
                                defaultValue="0"
                                value={
                                  heiferPopulation[`heiferpopcount${month}`]
                                }
                                onChange={handleHeiferPopulationChange}
                                placeholder=""
                                fullWidth
                              />
                            ))}
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container py={2} px={2} justifyContent="space-around">
                    <Grid item xs={12} sm={12} md={12}>
                      <CFButton
                        type="submit"
                        sx={{
                          float: "right",
                        }}
                        color="progress"
                        onClick={() => updateFeedLotPops()}
                        disabled={!fdLotEnableSave}
                      >
                        Save
                      </CFButton>
                    </Grid>
                  </Grid>
                  {saveSuccessMsg && (
                    <Grid container justifyContent="space-around">
                      <Grid item xs={2} sm={2} md={2} alignItems="center">
                        <CFBox id="successMessage">
                          <CFTypography
                            sx={{
                              fontSize: "15px",
                              color: "green",

                              marginBottom: "0.5em",
                              marginTop: "0.5em",
                            }}
                          >
                            Save was successful.
                          </CFTypography>
                        </CFBox>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </CFBox>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </CFBox>
    </CFBox>
  );
}
