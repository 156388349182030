// React
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// MUI
import { Grid, Dialog } from "@mui/material";

import colors from "assets/theme/base/colors";
import DairyH from "assets/cFarmImages/HelpImages/dh replacements one-pager.webp";
import DairyD from "assets/cFarmImages/HelpImages/dairy dry one-pager.webp";
import DairyL from "assets/cFarmImages/HelpImages/dairy lactating one pager.webp";
import GrazingS from "assets/cFarmImages/HelpImages/grazing stockers one-pager.webp";
import GrazingC from "assets/cFarmImages/HelpImages/grazing cow-calf one-pager.webp";
import GrazingB from "assets/cFarmImages/HelpImages/grazing bulls one-pager.webp";
import FeedH from "assets/cFarmImages/HelpImages/feedlot one pager.webp";
import BroilerP from "assets/cFarmImages/HelpImages/broiler one pager.webp";
import DuckP from "assets/cFarmImages/HelpImages/duck one pager.webp";
import LayingHensP from "assets/cFarmImages/HelpImages/laying hens one pager.webp";
import TurkeyP from "assets/cFarmImages/HelpImages/turkey one pager.webp";
import BisonOR from "assets/cFarmImages/HelpImages/bison one pager.webp";
import LlamaOR from "assets/cFarmImages/HelpImages/llamas one pager.webp";
import DeerOR from "assets/cFarmImages/HelpImages/deer one pager.webp";
import RabbitsONR from "assets/cFarmImages/HelpImages/rabbits one pager.webp";
import HorsesONR from "assets/cFarmImages/HelpImages/horse one pager.webp";
import MulesONR from "assets/cFarmImages/HelpImages/mule one pager.webp";
import OtherFurONR from "assets/cFarmImages/HelpImages/other fur one pager.webp";
import BreedingS from "assets/cFarmImages/HelpImages/breeding swine one pager.webp";
import GrowingS from "assets/cFarmImages/HelpImages/growing swine one pager.webp";

import CFDialog from "components/CFDialog";
import CFStep from "components/CFarmParts/CFStep";

// Our Components
import CFBox from "components/CFBox";

import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";
import { updateLoading } from "store/reducers/mask";
import { post } from "utils/api";
import { popupError } from "utils/generic";
import {
  FETCH_ALL_PANELS,
  FETCH_GOAT_GEST_LACT_EXISTS,
  FETCH_ALL_BARNNAMES,
} from "constants/api";

import CFLoading from "components/CFLoading";
import AnimalPanelsCard from "./AnimalPanelsCard";
import ScenarioCard from "./ScenarioCard";

// Constants

export default function AnimalPanels({
  isOpen,
  setIsOpen,
  id,
  yearValue,
  isCopied,
  animalCategory,
  animalCategoryId,
  herd,
  groupId,
  anagobject,
  anagobjectChanged,
  isClosed,
}) {
  const dispatch = useDispatch();

  const { info } = colors;

  const [localPopupState, updateLocalPopup] = useState({});

  const [catDetails, setCatDetails] = useState({});

  const [expanded, setExpanded] = useState(false);

  const [barnsList, setBarnsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gestExists, setGestExists] = useState(false);
  const [lactExists, setLactExists] = useState(false);
  const [hsngComplete, setHsngComplete] = useState(false);
  const [dairyPopUpdated, setDairyPopUpdated] = useState(false);

  const [grazingPopUpdated, setGrazingPopUpdated] = useState(false);
  const [feedlotPopUpdated, setFeedlotPopUpdated] = useState(false);
  const [sheepPopUpdated, setSheepPopUpdated] = useState(false);
  const [verified, setVerified] = useState("incomplete");

  const handleHousingEdited = (flag) => {
    if (flag) {
      setVerified("grey");
    } else {
      setVerified("incomplete");
    }
  };
  const fetchBarnNames = () => {
    setLoading(true);
    const popObj = {
      pop: id,

      cat: animalCategoryId,
    };
    post(FETCH_ALL_BARNNAMES, popObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const json = res.data;
          setBarnsList(json.barns);
          setHsngComplete(json.housingComplete);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const fetchGoatGestLactExists = () => {
    setLoading(true);
    const popObj = {
      popid: id,
    };
    post(FETCH_GOAT_GEST_LACT_EXISTS, popObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const json = res.data;
          setGestExists(json.gestExists);
          setLactExists(json.lactExists);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const expandToggle = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    if (panel === "Manure") {
      fetchBarnNames();
    }
    if (panel === "Gestation & Lactation Details") {
      fetchGoatGestLactExists();
    }
    if (
      (panel === "Feed Characteristics" || panel === "Housing") &&
      (animalCategoryId === 21260 ||
        animalCategoryId === 21261 ||
        animalCategoryId === 21262)
    ) {
      setDairyPopUpdated(true);
    }
    if (
      panel === "Feed Characteristics" &&
      (animalCategoryId === 21250 ||
        animalCategoryId === 21253 ||
        animalCategoryId === 21255)
    ) {
      setGrazingPopUpdated(true);
    }
    if (panel === "Housing" && animalCategoryId === 21247) {
      setFeedlotPopUpdated(true);
    }
    if (panel === "Housing" && animalCategoryId === 21267) {
      setSheepPopUpdated(true);
    }
  };

  const fetchAllPanels = () => {
    post(FETCH_ALL_PANELS, animalCategory)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const json = res.data;

          setCatDetails(json);

          // setAnagCategory(json);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    setIsOpen(false);
    isClosed(false);
    setExpanded("");
    anagobjectChanged(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setLoading(true);
  };

  useEffect(() => {
    if (animalCategory) fetchAllPanels();
  }, [animalCategory]);

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="xl"
      maxHeight="xl"
      sx={{ zIndex: 5 }}
    >
      {loading && <CFLoading local />}
      <Grid container item>
        {/* Main form */}
        <CFBox width="100%" sx={{ padding: "2em" }}>
          {/* Grid with four rows: title, step one and two, step three, and action buttons */}
          <Grid container direction="row" item xs={12} sm={12} md={12}>
            {/* Window Header */}
            <CFStep
              stepLabel={
                <div>
                  Enter Management for {anagobject.selectedScenario} Scenario
                </div>
              }
              stepNumber="3"
              helpText={
                <CFBox
                  sx={{ overflow: "auto", height: "600px", maxHeight: "60vh" }}
                >
                  {animalCategoryId === 21260 && (
                    <img
                      src={DairyH}
                      width="100%"
                      alt="Dairy Heifer Replacements Help"
                    />
                  )}
                  {animalCategoryId === 21261 && (
                    <img src={DairyD} width="100%" alt="Dairy-Dry Cows Help" />
                  )}
                  {animalCategoryId === 21262 && (
                    <img
                      src={DairyL}
                      width="100%"
                      alt="Dairy-Lactating Cows Help"
                    />
                  )}
                  {animalCategoryId === 21250 && (
                    <img
                      src={GrazingC}
                      width="100%"
                      alt="Grazing Cow-Calf Pairs Help"
                    />
                  )}
                  {animalCategoryId === 21253 && (
                    <img src={GrazingB} width="100%" alt="Grazing Bulls Help" />
                  )}
                  {animalCategoryId === 21255 && (
                    <img
                      src={GrazingS}
                      width="100%"
                      alt="Grazing Stockers Help"
                    />
                  )}
                  {animalCategoryId === 21247 && (
                    <img src={FeedH} width="100%" alt="Feedlot Help" />
                  )}
                  {animalCategoryId === 21271 && (
                    <img src={BroilerP} width="100%" alt="Broilers Help" />
                  )}
                  {animalCategoryId === 21273 && (
                    <img
                      src={LayingHensP}
                      width="100%"
                      alt="Laying Hens Help"
                    />
                  )}
                  {animalCategoryId === 21274 && (
                    <img src={TurkeyP} width="100%" alt="Laying Hens Help" />
                  )}
                  {animalCategoryId === 21275 && (
                    <img src={DuckP} width="100%" alt="Ducks Help" />
                  )}
                  {animalCategoryId === 21280 && (
                    <img src={BisonOR} width="100%" alt="American Bison Help" />
                  )}
                  {animalCategoryId === 21283 && (
                    <img src={GrowingS} width="100%" alt="Growing Swine Help" />
                  )}
                  {animalCategoryId === 21284 && (
                    <img
                      src={BreedingS}
                      width="100%"
                      alt="Breeding Swine Help"
                    />
                  )}
                  {animalCategoryId === 21290 && (
                    <img src={LlamaOR} width="100%" alt="Llama & Alpaca Help" />
                  )}
                  {animalCategoryId === 21291 && (
                    <img src={DeerOR} width="100%" alt="Deer Help" />
                  )}
                  {animalCategoryId === 21295 && (
                    <img src={RabbitsONR} width="100%" alt="Rabbits Help" />
                  )}
                  {animalCategoryId === 21296 && (
                    <img src={HorsesONR} width="100%" alt="Horses Help" />
                  )}
                  {animalCategoryId === 21297 && (
                    <img src={MulesONR} width="100%" alt="Mules/Asses Help" />
                  )}
                  {animalCategoryId === 21298 && (
                    <img
                      src={OtherFurONR}
                      width="100%"
                      alt="Other Fur Bearing Help"
                    />
                  )}
                </CFBox>
              }
            />

            {/* Grid for steps one and two */}
            <Grid container direction="row">
              {/* Step one */}

              {/* Step two */}
              <Grid
                item
                container
                xs={12}
                id="step2box"
                display="flex"
                alignItems="start"
                justifyContent="start"
                px={2}
              >
                <CFTypography
                  sx={{
                    fontSize: "20px",
                    color: info.dullFocus,
                    fontWeight: "bold",
                    marginLeft: "1em",
                    marginBottom: "0.5em",
                    marginTop: "0.5em",
                  }}
                >
                  {herd}
                </CFTypography>

                <CFBox sx={{ width: "100%", marginLeft: "1em" }}>
                  {catDetails.population ? (
                    <AnimalPanelsCard
                      popId={id}
                      popYear={yearValue}
                      copyFlag={isCopied}
                      popCategory={animalCategory}
                      popCategoryId={animalCategoryId}
                      popHerd={herd}
                      popGroupId={groupId}
                      panelName="Animal Details"
                      anagObj={anagobject}
                      anagObjChanged={anagobjectChanged}
                      expanded={expanded}
                      expandToggle={expandToggle}
                      // fetchAllPopulation={fetchAllPopulation}
                      updateLocalPopup={updateLocalPopup}
                    />
                  ) : null}
                  {catDetails.pregnancyAndMilkProduction ? (
                    <AnimalPanelsCard
                      popId={id}
                      popYear={yearValue}
                      copyFlag={isCopied}
                      popCategory={animalCategory}
                      popCategoryId={animalCategoryId}
                      popHerd={herd}
                      popGroupId={groupId}
                      anagObj={anagobject}
                      anagObjChanged={anagobjectChanged}
                      panelName="Gestation & Lactation Details"
                      expanded={expanded}
                      expandToggle={expandToggle}
                      // fetchAllPopulation={fetchAllPopulation}
                      updateLocalPopup={updateLocalPopup}
                      hasGest={gestExists}
                      hasLact={lactExists}
                    />
                  ) : null}
                  {catDetails.typesOfFeed ? (
                    <AnimalPanelsCard
                      popId={id}
                      popYear={yearValue}
                      copyFlag={isCopied}
                      popCategory={animalCategory}
                      popCategoryId={animalCategoryId}
                      popHerd={herd}
                      popGroupId={groupId}
                      panelName="Feed Characteristics"
                      anagObj={anagobject}
                      anagObjChanged={anagobjectChanged}
                      expanded={expanded}
                      expandToggle={expandToggle}
                      dairyFlag={dairyPopUpdated}
                      setDairyFlag={setDairyPopUpdated}
                      grazingFlag={grazingPopUpdated}
                      setGrazingFlag={setGrazingPopUpdated}
                      // fetchAllPopulation={fetchAllPopulation}
                      updateLocalPopup={updateLocalPopup}
                    />
                  ) : null}

                  {catDetails.weight ? (
                    <AnimalPanelsCard
                      popId={id}
                      popYear={yearValue}
                      copyFlag={isCopied}
                      popCategory={animalCategory}
                      popCategoryId={animalCategoryId}
                      popHerd={herd}
                      popGroupId={groupId}
                      panelName="Weight"
                      anagObj={anagobject}
                      anagObjChanged={anagobjectChanged}
                      expanded={expanded}
                      expandToggle={expandToggle}
                      // fetchAllPopulation={fetchAllPopulation}
                      updateLocalPopup={updateLocalPopup}
                    />
                  ) : null}

                  {catDetails.wool ? (
                    <AnimalPanelsCard
                      popId={id}
                      popYear={yearValue}
                      copyFlag={isCopied}
                      popCategory={animalCategory}
                      popCategoryId={animalCategoryId}
                      popHerd={herd}
                      popGroupId={groupId}
                      anagObj={anagobject}
                      anagObjChanged={anagobjectChanged}
                      panelName="Wool Production Details"
                      expanded={expanded}
                      expandToggle={expandToggle}
                      // fetchAllPopulation={fetchAllPopulation}
                      updateLocalPopup={updateLocalPopup}
                    />
                  ) : null}
                  {catDetails.housingSystemType ? (
                    <AnimalPanelsCard
                      popId={id}
                      popYear={yearValue}
                      copyFlag={isCopied}
                      popCategory={animalCategory}
                      popCategoryId={animalCategoryId}
                      popHerd={herd}
                      popGroupId={groupId}
                      panelName="Housing"
                      anagObj={anagobject}
                      anagObjChanged={anagobjectChanged}
                      expanded={expanded}
                      expandToggle={expandToggle}
                      dairyFlag={dairyPopUpdated}
                      setDairyFlag={setDairyPopUpdated}
                      // fetchAllPopulation={fetchAllPopulation}
                      feedlotFlag={feedlotPopUpdated}
                      setFeedlotFlag={setFeedlotPopUpdated}
                      sheepFlag={sheepPopUpdated}
                      setSheepFlag={setSheepPopUpdated}
                      updateLocalPopup={updateLocalPopup}
                      housingEdited={handleHousingEdited}
                    />
                  ) : null}

                  {catDetails.manureSystemType ? (
                    <AnimalPanelsCard
                      popId={id}
                      popYear={yearValue}
                      copyFlag={isCopied}
                      popCategory={animalCategory}
                      popCategoryId={animalCategoryId}
                      popHerd={herd}
                      popGroupId={groupId}
                      anagObj={anagobject}
                      anagObjChanged={anagobjectChanged}
                      panelName="Manure"
                      // fetchAllPopulation={fetchAllPopulation}
                      expanded={expanded}
                      expandToggle={expandToggle}
                      barnIds={barnsList}
                      hComplete={hsngComplete}
                      updateLocalPopup={updateLocalPopup}
                      housingEdited={handleHousingEdited}
                      manureVerify={verified}
                    />
                  ) : null}
                </CFBox>
              </Grid>
            </Grid>
            <Grid container py={2} px={2} justifyContent="space-around">
              {/* Return to projects */}
              <Grid item xs={11} sm={11} md={11}>
                <CFButton color="secondary" onClick={() => handleClose()}>
                  Close
                </CFButton>
              </Grid>
              {/* Management Details */}
            </Grid>
          </Grid>
        </CFBox>

        {localPopupState && localPopupState.showPopup && (
          <CFDialog
            {...localPopupState}
            updatePopup={(e) => updateLocalPopup(e)}
          />
        )}
      </Grid>
    </Dialog>
  );
}
