import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { useScreenSize } from "hooks";

const CFGraphBox = styled(Box)(({ theme, height = 750 }) => ({
  padding: theme.spacing(1),
  height,
  width: useScreenSize().width * 0.95,

  // although using screensize works (cheat + 1), want to keep this
  // as an example of changing things based on screen size

  //   [theme.breakpoints.down("md")]: {
  //     minWidth: 400,
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     minWidth: 760,
  //   },
  //   [theme.breakpoints.up("lg")]: {
  //     minWidth: 990,
  //   },
  //   [theme.breakpoints.up("xl")]: {
  //     minWidth: 1200,
  //   },
}));

export default CFGraphBox;
