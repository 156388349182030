import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { Grid } from "@mui/material";

import CFBox from "components/CFBox";
import CFLoading from "components/CFLoading";
import CFTypography from "components/CFTypography";
import CFDialog from "components/CFDialog";
import CFInput from "components/CFInput";
import CFButton from "components/CFButton";
import { post } from "utils/api";
import { VERIFY_DEV } from "constants/api";

import { updatePopup } from "store/reducers/popup";
import CFarmBottomBar from "./CFarmBottomBar";
import CFarmHeader from "./CFarmHeader/CFarmHeader";

function InPage(children, pageName, pageTitle, padding, optionalBgImage) {
  const loading = useSelector((state) => state.mask.loading);
  // This just a state to show default popups.  This is intended to provide a way to show generic messages to the user when a catch all is hit.
  const genericPopupState = useSelector((state) => state.popup);

  const dispatch = useDispatch();

  const [showMaintPopup, setShowMaint] = useState(false); // !currentUser.emailId);
  const [devPass, setDevPass] = useState("");

  function updatePopupGlobally(e) {
    dispatch(updatePopup(e));
  }
  function onSubmitMaint() {
    post(VERIFY_DEV, { devPass })
      .then((res) => {
        // result is true if password was correct, false if not
        setShowMaint(!res);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // Uncomment this if we need a maintenance message
  /*  
  const currentUser = useSelector((state) => state.user); 
  useEffect(() => {
    setShowMaint(!currentUser.emailId);
  }, [currentUser]);
  */

  const pt = pageTitle || pageName;
  return (
    <CFBox
      width="100%"
      sx={{
        padding: "0.1em",
        background: "white",
      }}
    >
      <Helmet htmlAttributes={{ lang: "en" }}>
        <title>{pt}</title>
        <script type="text/javascript">
          {`
    window.fwSettings = {
      'widget_id': 64000000678
  };
  !function () { if ("function" != typeof window.FreshworksWidget) { var n = function () { n.q.push(arguments) }; n.q = [], window.FreshworksWidget = n } }()
         `}
        </script>
        <script
          type="text/javascript"
          src="https://widget.freshworks.com/widgets/64000000678.js"
          async
          defer
        />
        <script src="https://www.google.com/recaptcha/enterprise.js?render=6LcPlWkgAAAAACaQ_rEpSUXEgLop1otyshqZudcE" />
      </Helmet>
      <CFarmHeader />

      <CFBox
        minHeight="75vh"
        width="100%"
        bgImage={optionalBgImage}
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
          position: "relative",
        }}
        role="main"
        px={padding !== undefined ? padding : 5}
      >
        {loading && <CFLoading />}
        {genericPopupState && genericPopupState.showPopup && (
          <CFDialog
            {...genericPopupState}
            updatePopup={(e) => updatePopupGlobally(e)}
          />
        )}
        {/* Cfarm is down dialog box */}
        <CFDialog
          title="COMET-Farm is down"
          showPopup={showMaintPopup}
          updatePopup={() => onSubmitMaint()}
          closable={false}
        >
          <CFBox sx={{ margin: "1em" }}>
            <CFTypography>
              COMET-Farm is down for mainenance. Please check back in on Monday,
              September 23rd, 2024.{" "}
            </CFTypography>
            <br />
            <CFTypography>If you are a dev user, enter password:</CFTypography>
            <Grid
              container
              direction="row"
              sx={{ justifyContent: "space-evenly" }}
            >
              <CFInput
                value={devPass}
                onChange={(event) => {
                  setDevPass(event.target.value);
                }}
              />
              <CFButton color="progress" onClick={() => onSubmitMaint()}>
                Submit
              </CFButton>
            </Grid>
          </CFBox>
        </CFDialog>

        {pageName && (
          <CFTypography
            textTransform="capitalize"
            fontWeight="bold"
            fontSize="50px"
            sx={{ marginTop: ".5em", top: "0px" }}
          >
            {pageName}{" "}
          </CFTypography>
        )}
        {children}
      </CFBox>
      <CFarmBottomBar />
    </CFBox>
  );
}
function CFarmPage(props) {
  return InPage(
    props.children,
    props.pageName,
    props.title,
    props.padding,
    props.bgImage
  );
}

export default CFarmPage;
