/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { EditTwoTone } from "@mui/icons-material";
import CFStep from "components/CFarmParts/CFStep";
import CFTypography from "components/CFTypography";

import CFBox from "components/CFBox";
import { post } from "utils/api";
import { popupError } from "utils/generic";
import { FETCH_POPULATIONS } from "constants/api";
import colors from "assets/theme/base/colors";
import DetailsImage from "assets/cFarmImages/HelpImages/Step 3 Animal Management.jpg";
import CFLoading from "components/CFLoading";
import VerifiedTwoToneIcon from "@mui/icons-material/VerifiedTwoTone";

import AnimalPanels from "./AnimalPanels";

function AnimalDetails(props) {
  const { anagOpObject, anagObjectChanged, animalDetailsChanged } = props;
  const dispatch = useDispatch();
  const [populationList, setPopulationList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const currentUser = useSelector((state) => state.user);

  const [editPopulationIsOpen, setEditPopulationIsOpen] = useState(false);

  const [pop, setPop] = useState("");

  const [loading, setLoading] = useState(false);

  const editPopFlag = (flag) => {
    setEditPopulationIsOpen(flag);
  };
  const editPopulation = (popln) => {
    editPopFlag(true);
    setPop(popln);
  };
  const fetchPopulations = () => {
    setLoading(true);
    const popObj = {
      scenario: anagOpObject.selectedScenario,

      id: currentUser,
      projectId: currentUser.activeProject.id || 0,
    };
    post(FETCH_POPULATIONS, popObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const json = res.data;
          setPopulationList(json);
          setCategoryList(json.catList);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (anagOpObject.id !== "" && anagOpObject.id !== 0) {
      fetchPopulations();
    }
  }, [anagOpObject.activeAnimalCategories]);

  return (
    <CFBox>
      {loading && <CFLoading local />}

      {anagOpObject.id !== "" && anagOpObject.countyId !== 0 && (
        <CFBox id="animalManagement">
          <CFStep
            stepLabel="Animal Management"
            stepNumber="3"
            helpText={
              <div>
                The selected animal types will populate the Animal Management
                section of the module. Click on the <i>Edit Management</i>{" "}
                button next to each animal type to begin data entry. The
                checkmark to the left of each animal category indicates whether
                that animal has complete management data associated with it. A
                gray check means that management is incomplete; a green check
                indicates that it is completed. Management must be complete for
                each animal type in order to proceed to the report or add a
                scenario.
                <img
                  src={DetailsImage}
                  width="100%"
                  alt="Animal Management Help"
                />
              </div>
            }
          />
          <CFBox
            component="form"
            autoComplete="off"
            sx={{
              marginLeft: "1em",
              marginTop: "1em",
              marginBottom: "2em",
            }}
          >
            <Grid
              flexDirection="row"
              justifyContent="start"
              alignItems="start"
              container
            >
              <Grid
                container
                flexDirection="row"
                alignItems="start"
                item
                xs={12}
                sm={12}
                md={12}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <CFTypography
                    sx={{
                      fontSize: "14px",
                      marginBottom: "2em",
                      marginLeft: "2em",
                    }}
                    fontWeight="regular"
                  >
                    Select the <b>Edit Management</b> button for each{" "}
                    <b>animal category</b> to enter the annual management.
                  </CFTypography>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                mx="auto"
                textAlign="center"
                direction="row"
                sx={{ width: "100%" }}
              >
                <CFBox sx={{ marginLeft: "1em" }}>
                  <CFBox>
                    <Grid
                      item
                      container
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      xs={12}
                    >
                      {populationList.map((population) => (
                        <CFBox
                          sx={{
                            backgroundColor: colors.background.header,
                            padding: "10px",
                            display: "flex",
                            marginBottom: ".2em",
                          }}
                        >
                          <VerifiedTwoToneIcon
                            color="primary"
                            fontSize="medium"
                            style={{
                              cursor: "pointer",
                              marginRight: ".5em",
                              marginTop: ".5em",

                              color: population.dataComplete ? "green" : "grey",
                            }}
                          />
                          <Grid
                            item
                            container
                            key={`${population.id}gridCat`}
                            sx={{
                              background: "white",
                              marginRight: ".5em",
                              paddingLeft: ".3em",
                              alignItems: "center",

                              borderRadius: ".3em",
                            }}
                          >
                            <CFBox
                              sx={{
                                textAlign: "left",
                                borderStyle: "hidden",
                                fontSize: "large",
                              }}
                              style={{ width: "14em" }}
                            >
                              {population.groupName}
                            </CFBox>
                          </Grid>
                          <Grid item>
                            <CFBox
                              onClick={() => editPopulation(population)}
                              style={{ cursor: "pointer", fontSize: "medium" }}
                              sx={{
                                color: colors.text.light,
                                backgroundColor: colors.primary.main,
                                padding: ".5em",
                                alignItems: "center",
                                display: "flex",
                                width: "12em",
                              }}
                            >
                              <EditTwoTone
                                onClick={() => editPopulation(population)}
                                color="white"
                                fontSize="medium"
                                style={{
                                  cursor: "pointer",
                                  marginRight: ".15em",
                                }}
                              />
                              {"   "}
                              Edit Management
                            </CFBox>
                          </Grid>
                        </CFBox>
                      ))}
                    </Grid>
                  </CFBox>
                  <AnimalPanels
                    isOpen={editPopulationIsOpen}
                    setIsOpen={setEditPopulationIsOpen}
                    id={pop.id}
                    yearValue={pop.yearValue}
                    isCopied={pop.copied}
                    animalCategory={pop.animalCategoryName}
                    animalCategoryId={pop.animalCategoryId}
                    herd={pop.groupName}
                    groupId={pop.grazingAreaId}
                    anagobject={anagOpObject}
                    anagobjectChanged={anagObjectChanged}
                    isClosed={animalDetailsChanged}
                  />
                </CFBox>
              </Grid>
            </Grid>
          </CFBox>
        </CFBox>
      )}
    </CFBox>
  );
}
export default AnimalDetails;
