// React
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// MUI
import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";

import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import Tooltip from "@mui/material/Tooltip";
import colors from "assets/theme/base/colors";
import CFDialog from "components/CFDialog";
import CFInput from "components/CFInput";
// Our Components
import CFBox from "components/CFBox";

import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";
import { updateLoading } from "store/reducers/mask";
import { post } from "utils/api";
import { popupError } from "utils/generic";
import {
  UPDATE_DAIRY_POPULATIONS,
  FETCH_DAIRY_POPULATION,
} from "constants/api";
import CFLoading from "components/CFLoading";
import { getTextInput } from "./AnimalAgCommonFunc";
// Constants

export default function DairyPopulation({
  id,
  yearValue,
  isCopy,
  animalCategory,
  animalCategoryId,
  groupId,
  anagObject,
  anagObjectChanged,
  verifyDone,
}) {
  const dispatch = useDispatch();

  const { info } = colors;

  const [population, setPopulation] = useState([]);
  const [liveBodyWeight, setLiveBodyWeight] = useState([]);
  const [percentMilkFat, setPercentMilkFat] = useState([]);

  const [saveSuccessMsg, setSaveSuccessMsg] = useState(false);

  const [enableSave, setEnableSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const monthsArr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const isValid = () => {
    let isComplete = false;
    const popExists =
      anagObject.selectedScenario === "Baseline" ||
      (anagObject.selectedScenario !== "Baseline" && !isCopy)
        ? Object.entries(population).some((x) => x[1] > 0)
        : Object.entries(population).some((x) => x[1] >= 0);
    const lwExists =
      anagObject.selectedScenario === "Baseline" ||
      (anagObject.selectedScenario !== "Baseline" && !isCopy)
        ? Object.entries(liveBodyWeight).some((x) => x[1] > 0)
        : Object.entries(liveBodyWeight).some((x) => x[1] >= 0);
    const milkExists =
      anagObject.selectedScenario === "Baseline" ||
      (anagObject.selectedScenario !== "Baseline" && !isCopy)
        ? Object.entries(percentMilkFat).some((x) => x[1] > 0)
        : Object.entries(percentMilkFat).some((x) => x[1] >= 0);
    if (animalCategoryId === 21262) {
      if (popExists && lwExists && milkExists) {
        isComplete = true;
      }
    } else if (animalCategoryId === 21260 || animalCategoryId === 21261) {
      if (popExists && lwExists) {
        isComplete = true;
      }
    }
    setEnableSave(isComplete);
  };

  const isFetchValid = (pop, bw, milk) => {
    let isComplete = false;

    if (animalCategoryId === 21262) {
      if (pop && bw && milk) {
        isComplete = true;
      }
    } else if (animalCategoryId === 21260 || animalCategoryId === 21261) {
      if (pop && bw) {
        isComplete = true;
      }
    }
    return isComplete;
  };

  const handlePopulationChange = (e) => {
    setPopulation({
      ...population,
      [e.target.name]: e.target.value,
    });
  };
  const copyPopulation = () => {
    const janPop = population.popcountJan;

    const popMonthsArr = [];
    monthsArr.map((month) => popMonthsArr.push([`popcount${month}`]));
    const popNewObj = {};
    popMonthsArr.forEach((mon) => {
      popNewObj[mon] = janPop;
    });

    setPopulation({ ...population, ...popNewObj });
  };
  const handleLiveBodyWeightChange = (e) => {
    setLiveBodyWeight({
      ...liveBodyWeight,
      [e.target.name]: e.target.value,
    });
  };
  const copyLiveBodyWeight = () => {
    const janLiveBodyWeight = liveBodyWeight.livebodyweightcountJan;

    const liveBodyWeightMonthsArr = [];
    monthsArr.map((month) =>
      liveBodyWeightMonthsArr.push([`livebodyweightcount${month}`])
    );
    const liveBodyWeightNewObj = {};
    liveBodyWeightMonthsArr.forEach((mon) => {
      liveBodyWeightNewObj[mon] = janLiveBodyWeight;
    });

    setLiveBodyWeight({ ...liveBodyWeight, ...liveBodyWeightNewObj });
  };
  const handlePercentMilkFatChange = (e) => {
    const value = getTextInput(e.target.value);
    setPercentMilkFat({
      ...percentMilkFat,
      [e.target.name]: value,
    });
  };
  const copyPercentMilkFat = () => {
    const janPercentMilkFat = getTextInput(percentMilkFat.percentmilkfatJan);
    const percentMilkFatMonthsArr = [];
    monthsArr.map((month) =>
      percentMilkFatMonthsArr.push([`percentmilkfat${month}`])
    );
    const percentMilkFatNewObj = {};
    percentMilkFatMonthsArr.forEach((mon) => {
      percentMilkFatNewObj[mon] = janPercentMilkFat;
    });

    setPercentMilkFat({ ...percentMilkFat, ...percentMilkFatNewObj });
  };

  const fetchDairyPopulation = () => {
    setLoading(true);
    const popObj = {
      popid: id,
    };
    post(FETCH_DAIRY_POPULATION, popObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const json = JSON.parse(res.data);
          const pop = json.DairyPopMonths;
          const popMonthsJson = [];
          const liveBodyWeightMonthsJson = [];
          const percentMilkFatMonthsJson = [];
          const popNewJson = {};
          const liveBodyWeighNewJson = {};
          const percentMilkFatNewJson = {};
          let popExists = false;
          let bwExists = false;
          let pctFatExists = false;
          monthsArr.map((month) => popMonthsJson.push([`popcount${month}`]));
          monthsArr.map((month) =>
            liveBodyWeightMonthsJson.push([`livebodyweightcount${month}`])
          );
          monthsArr.map((month) =>
            percentMilkFatMonthsJson.push([`percentmilkfat${month}`])
          );

          popMonthsJson.forEach((mon) => {
            const monthName = mon.toString().replace("popcount", "");

            popNewJson[mon] = pop.find(
              (popln) => popln.Month.toString() === monthName.toString()
            ).PopCount;
          });

          setPopulation({ ...population, ...popNewJson });
          popExists =
            anagObject.selectedScenario === "Baseline" ||
            (anagObject.selectedScenario !== "Baseline" && !isCopy)
              ? pop.some((el) => el.PopCount > 0)
              : pop.some((el) => el.PopCount >= 0);

          liveBodyWeightMonthsJson.forEach((mon) => {
            const lbwMonthName = mon
              .toString()
              .replace("livebodyweightcount", "");

            liveBodyWeighNewJson[mon] = pop.find(
              (popln) => popln.Month.toString() === lbwMonthName.toString()
            ).LiveBodyWeightCount;
          });

          setLiveBodyWeight({ ...liveBodyWeight, ...liveBodyWeighNewJson });
          bwExists =
            anagObject.selectedScenario === "Baseline" ||
            (anagObject.selectedScenario !== "Baseline" && !isCopy)
              ? pop.some((el) => el.LiveBodyWeightCount > 0)
              : pop.some((el) => el.LiveBodyWeightCount >= 0);
          percentMilkFatMonthsJson.forEach((mon) => {
            const pmfMonthName = mon.toString().replace("percentmilkfat", "");

            percentMilkFatNewJson[mon] = pop.find(
              (popln) => popln.Month.toString() === pmfMonthName.toString()
            ).PercentMilkFatCount;
          });

          setPercentMilkFat({ ...percentMilkFat, ...percentMilkFatNewJson });
          pctFatExists =
            anagObject.selectedScenario === "Baseline" ||
            (anagObject.selectedScenario !== "Baseline" && !isCopy)
              ? pop.some((el) => el.PercentMilkFatCount > 0)
              : pop.some((el) => el.PercentMilkFatCount >= 0);

          if (isFetchValid(popExists, bwExists, pctFatExists)) {
            verifyDone("green");
            setEnableSave(true);
          } else {
            verifyDone("grey");
            setEnableSave(false);
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const updateDairyPops = () => {
    setLoading(true);
    const animalTypesObj = {
      popObj: population,
      liveBodyWghtObj: liveBodyWeight,
      perMilkFat: percentMilkFat,
      popid: id,
    };
    post(UPDATE_DAIRY_POPULATIONS, animalTypesObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          setSaveSuccessMsg(true);
          // fetchDairyPopulation();

          // anagObjectChanged(true);
          verifyDone("green");
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDairyPopulation();
  }, [anagObject.activeAnimalCategories]);

  useEffect(() => {
    isValid();
  }, [population, liveBodyWeight, percentMilkFat]);

  return (
    <CFBox width="100%">
      {loading && <CFLoading local />}
      <Grid container item>
        {/* Main form */}
        <CFBox width="100%">
          <Grid container direction="row" xs={12} sm={12} md={12}>
            <Grid container direction="row">
              {(animalCategoryId === 21260 || animalCategoryId === 21261) && (
                <Grid item container xs={12}>
                  <CFTypography
                    sx={{
                      fontSize: "0.7em",

                      color: info.dullFocus,

                      marginBottom: "0.7em",
                    }}
                  >
                    Enter the monthly animal population and average live body
                    weight for {animalCategory}. Use arrows to copy data across
                    months.
                  </CFTypography>
                </Grid>
              )}
              {animalCategoryId === 21262 && (
                <Grid item container xs={12}>
                  <CFTypography
                    sx={{
                      fontSize: "0.7em",

                      color: info.dullFocus,

                      marginBottom: "0.7em",
                    }}
                  >
                    Enter the monthly animal population, average live body
                    weight and milk fat for {animalCategory}. Use arrows to copy
                    data across months.
                  </CFTypography>
                </Grid>
              )}
              <Grid item container xs={12} id="step2box" alignItems="center">
                <Grid
                  item
                  container
                  xs={12}
                  alignItems="center"
                  direction="column"
                >
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      justifyContent="space-evenly"
                      gap={1}
                      style={{ marginLeft: "12%" }}
                    >
                      {monthsArr.map((month) => (
                        <CFTypography
                          sx={{
                            fontSize: "1.2em",

                            color: info.dullFocus,
                            fontWeight: "bold",
                            padding: "9.5px",
                          }}
                        >
                          {month}
                        </CFTypography>
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  alignItems="center"
                  direction="row"
                >
                  <Grid item xs={12}>
                    <Tooltip title="Copy data across months">
                      <ArrowCircleRightTwoToneIcon
                        onClick={() => copyPopulation()}
                        id="copyPopulation"
                        color="primary"
                        fontSize="medium"
                        style={{
                          cursor: "pointer",
                          marginLeft: "20%",
                          marginTop: "1%",
                        }}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  alignItems="center"
                  direction="row"
                >
                  <Grid item xs={2}>
                    <CFTypography
                      sx={{
                        fontSize: "15px",
                        color: info.dullFocus,
                        fontWeight: "bold",

                        marginBottom: "0.5em",
                        marginTop: "0.5em",
                      }}
                    >
                      Population
                    </CFTypography>
                  </Grid>
                  <Grid item xs={10}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={1}
                      style={{ marginLeft: "3%" }}
                    >
                      {monthsArr.map((month) => (
                        <CFInput
                          sx={{
                            padding: "10px",
                            fieldset: {
                              borderColor: info.dullFocus,
                              borderStyle: "solid",
                              borderWidth: "2px",
                            },
                          }}
                          color="primary"
                          id={`popCount${month}`}
                          name={`popcount${month}`}
                          defaultValue="0"
                          value={population[`popcount${month}`]}
                          onChange={handlePopulationChange}
                          placeholder=""
                          fullWidth
                        />
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  alignItems="center"
                  direction="row"
                >
                  <Grid item xs={12}>
                    <Tooltip title="Copy data across months">
                      <ArrowCircleRightTwoToneIcon
                        onClick={() => copyLiveBodyWeight()}
                        id="copyLiveBodyWeight"
                        color="primary"
                        fontSize="medium"
                        style={{
                          cursor: "pointer",
                          marginLeft: "20%",
                          marginTop: "1%",
                        }}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  alignItems="center"
                  direction="row"
                >
                  <Grid item xs={2}>
                    <CFTypography
                      sx={{
                        fontSize: "15px",

                        color: info.dullFocus,
                        fontWeight: "bold",

                        marginBottom: "0.5em",
                        marginTop: "0.5em",
                      }}
                    >
                      Live Body Weight (lbs)
                    </CFTypography>
                  </Grid>
                  <Grid item xs={10}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={1}
                      style={{ marginLeft: "3%" }}
                    >
                      {monthsArr.map((month) => (
                        <CFInput
                          sx={{
                            padding: "10px",
                            fieldset: {
                              borderColor: info.dullFocus,
                              borderStyle: "solid",
                              borderWidth: "2px",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          color="primary"
                          id={`liveBodyWeightCount${month}`}
                          name={`livebodyweightcount${month}`}
                          value={liveBodyWeight[`livebodyweightcount${month}`]}
                          defaultValue="0"
                          onChange={handleLiveBodyWeightChange}
                          placeholder=""
                          fullWidth
                        />
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
                {animalCategoryId === 21262 && (
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    direction="row"
                  >
                    <Grid item xs={12}>
                      <Tooltip title="Copy data across months">
                        <ArrowCircleRightTwoToneIcon
                          onClick={() => copyPercentMilkFat()}
                          id="copyPercentMilkFat"
                          color="primary"
                          fontSize="medium"
                          style={{
                            cursor: "pointer",
                            marginLeft: "20%",
                            marginTop: "1%",
                          }}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
                {animalCategoryId === 21262 && (
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    direction="row"
                  >
                    <Grid item xs={2}>
                      <CFTypography
                        sx={{
                          fontSize: "15px",
                          color: info.dullFocus,
                          fontWeight: "bold",

                          marginBottom: "0.5em",
                          marginTop: "0.5em",
                        }}
                      >
                        Milk Fat (%)
                      </CFTypography>
                    </Grid>
                    <Grid item xs={10}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}
                        style={{ marginLeft: "3%" }}
                      >
                        {monthsArr.map((month) => (
                          <CFInput
                            sx={{
                              padding: "10px",
                              fieldset: {
                                borderColor: info.dullFocus,
                                borderStyle: "solid",
                                borderWidth: "2px",
                              },
                            }}
                            InputProps={{
                              inputProps: { min: 0, max: 100 },
                            }}
                            type="number"
                            color="primary"
                            id={`percentMilkFat${month}`}
                            name={`percentmilkfat${month}`}
                            defaultValue="0"
                            value={percentMilkFat[`percentmilkfat${month}`]}
                            onChange={handlePercentMilkFatChange}
                            placeholder=""
                            fullWidth
                          />
                        ))}
                      </Stack>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid container py={2} px={2} justifyContent="space-around">
              <Grid item xs={12} sm={12} md={12}>
                <CFButton
                  type="submit"
                  color="progress"
                  sx={{
                    float: "right",
                  }}
                  onClick={() => updateDairyPops()}
                  disabled={!enableSave}
                >
                  Save
                </CFButton>
              </Grid>
            </Grid>
            {saveSuccessMsg && (
              <Grid container justifyContent="space-around">
                <Grid item xs={2} sm={2} md={2} alignItems="center">
                  <CFBox id="successMessage">
                    <CFTypography
                      sx={{
                        fontSize: "15px",
                        color: "green",

                        marginBottom: "0.5em",
                        marginTop: "0.5em",
                      }}
                    >
                      Save was successful.
                    </CFTypography>
                  </CFBox>
                </Grid>
              </Grid>
            )}
          </Grid>
        </CFBox>
      </Grid>
    </CFBox>
  );
}
