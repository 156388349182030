import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  InputAdornment,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Info } from "@mui/icons-material";

import { bool } from "prop-types";
import CFBox from "components/CFBox";
import CFTypography from "components/CFTypography";
import CFInput from "components/CFInput";
import CFButton from "components/CFButton";
import colors from "assets/theme/base/colors";
import { updateLoading } from "store/reducers/mask";
import { post } from "utils/api";
import {
  FETCH_SWINE,
  UPDATE_SWINE_BREEDING,
  UPDATE_SWINE_GROWING,
} from "constants/api";
import { popupError } from "utils/generic";
import SwineHeader from "components/AnimalAgracultureComponents/SwineHeader";
import SwineInput from "components/AnimalAgracultureComponents/SwineInput";
import CFLoading from "components/CFLoading";
import { cntExists } from "./AnimalAgCommonFunc";

export default function Swine({
  id,
  yearValue,
  isCopy,
  anagObject,
  animalCategory,
  animalCategoryId,
  verifyDone,
}) {
  const dispatch = useDispatch();
  const [avgDMI, setAvgDMI] = useState(0);
  const [crudeProtein, setCrudeProtein] = useState(0);

  // growing swine
  const [herdsCnt, setHerdsCnt] = useState(0);
  const [pigsPerHerd, setPigsPerHerd] = useState(0);
  const [growthStageDaysCnt, setGrowthStageDaysCnt] = useState(0);
  const [startWeight, setStartWeight] = useState(0);
  const [endWeight, setEndWeight] = useState(0);

  // breeding swine
  const [gestationCyclesCnt, setGestationCyclesCnt] = useState(0);
  const [sowsPerCycle, setSowsPerCycle] = useState(0);
  const [gestationCycleDaysCnt, setGestationCycleDaysCnt] = useState(0);
  const [weightGain, setWeightGain] = useState(0);
  const [sowWeight, setSowWeight] = useState(0);
  const [headPerLitter, setHeadPerLitter] = useState(0);
  const [birthWeightPiglets, setBirthWeightPiglets] = useState(0);
  const [weanWeightPiglets, setWeanWeightPiglets] = useState(0);

  const [saveState, setSaveState] = useState("");

  const [loading, setLoading] = useState(false);

  const updateSwine = () => {
    setLoading(true);
    let postCall;
    let postObj;
    if (animalCategoryId === 21283) {
      postCall = UPDATE_SWINE_GROWING;
      postObj = {
        popId: id,
        herdsCnt,
        pigsPerHerd,
        growthStageDaysCnt,
        startWeight,
        endWeight,
        avgDMI,
        crudeProtein,
      };
    } else if (animalCategoryId === 21284) {
      postCall = UPDATE_SWINE_BREEDING;
      postObj = {
        popId: id,
        gestationCyclesCnt,
        sowsPerCycle,
        gestationCycleDaysCnt,
        weightGain,
        sowWeight,
        headPerLitter,
        birthWeightPiglets,
        weanWeightPiglets,
        avgDMI,
        crudeProtein,
      };
    }
    post(postCall, postObj)
      .then((res) => {
        setLoading(false);
        if (res.error) {
          console.log("Error obj ", res.error);
          setSaveState("Error saving. Please try again.");
        } else {
          setSaveState("Save was successful");
          verifyDone("green");
        }
        setLoading(false);
      })
      .catch((err) => {
        setSaveState("Error saving. Please try again.");
        setLoading(false);
      });
  };
  const setGrowingSwine = (data) => {
    setHerdsCnt(data.herdsCnt);
    setPigsPerHerd(data.pigsPerHerd);
    setGrowthStageDaysCnt(data.growthStageDaysCnt);
    setStartWeight(data.startWeight);
    setEndWeight(data.endWeight);
    setAvgDMI(data.avgDMI);
    setCrudeProtein(data.crudeProtein);
  };

  const setBreedingSwine = (data) => {
    setGestationCyclesCnt(data.gestationCyclesCnt);
    setSowsPerCycle(data.sowsPerCycle);
    setGestationCycleDaysCnt(data.gestationCycleDaysCnt);
    setWeightGain(data.weightGain);
    setSowWeight(data.sowWeight);
    setHeadPerLitter(data.headPerLitter);
    setBirthWeightPiglets(data.birthWeightPiglets);
    setWeanWeightPiglets(data.weanWeightPiglets);
    setAvgDMI(data.avgDMI);
    setCrudeProtein(data.crudeProtein);
  };

  const validateUserInputs = (data) => {
    let isValid = false;
    if (animalCategoryId === 21283) {
      const herdCount = data !== undefined ? data.herdsCnt : herdsCnt;
      const pigsCount = data !== undefined ? data.pigsPerHerd : pigsPerHerd;
      if (
        cntExists(anagObject.selectedScenario, isCopy, herdCount) &&
        cntExists(anagObject.selectedScenario, isCopy, pigsCount) &&
        (data !== undefined
          ? data.growthStageDaysCnt > 0
          : growthStageDaysCnt > 0) &&
        (data !== undefined ? data.startWeight > 0 : startWeight > 0) &&
        (data !== undefined ? data.endWeight > 0 : endWeight > 0) &&
        (data !== undefined ? data.avgDMI > 0 : avgDMI > 0) &&
        (data !== undefined ? data.crudeProtein > 0 : crudeProtein > 0)
      ) {
        isValid = true;
      }
    } else if (animalCategoryId === 21284) {
      const gestCyle =
        data !== undefined ? data.gestationCycleDaysCnt : gestationCycleDaysCnt;
      const sowsPrCyle = data !== undefined ? data.sowsPerCycle : sowsPerCycle;
      if (
        cntExists(anagObject.selectedScenario, isCopy, gestCyle) &&
        cntExists(anagObject.selectedScenario, isCopy, sowsPrCyle) &&
        (data !== undefined
          ? data.gestationCyclesCnt > 0
          : gestationCyclesCnt > 0) &&
        (data !== undefined ? data.weightGain > 0 : weightGain > 0) &&
        (data !== undefined ? data.headPerLitter > 0 : headPerLitter > 0) &&
        (data !== undefined
          ? data.birthWeightPiglets > 0
          : birthWeightPiglets > 0) &&
        (data !== undefined
          ? data.weanWeightPiglets > 0
          : weanWeightPiglets > 0) &&
        (data !== undefined ? data.avgDMI > 0 : avgDMI > 0) &&
        (data !== undefined ? data.crudeProtein > 0 : crudeProtein > 0)
      ) {
        isValid = true;
      }
    }

    return isValid;
  };

  useEffect(() => {
    post(FETCH_SWINE, { popId: id })
      .then((res) => {
        dispatch(updateLoading({ loading: false }));
        if (res.error) {
          popupError(res.error, dispatch);
          console.log("Error obj ", res.error);
        } else {
          if (animalCategoryId === 21283) setGrowingSwine(res.data);
          if (animalCategoryId === 21284) setBreedingSwine(res.data);
          if (validateUserInputs(res.data)) {
            verifyDone("green");
          } else {
            verifyDone("grey");
          }
        }
        dispatch(updateLoading({ loading: false }));
      })
      .catch((err) => {
        dispatch(updateLoading({ loading: false }));
      });
  }, []);
  return (
    <CFBox width="100%">
      {loading && <CFLoading local />}
      <Grid container item>
        {/* Main form */}
        <CFBox width="100%">
          <Grid container direction="row" xs={12} sm={12} md={12}>
            {animalCategoryId === 21283 && (
              <Accordion
                defaultExpanded
                sx={{
                  borderRadius: ".3em",
                  marginBottom: ".5em",
                }}
              >
                <SwineHeader text="Herd Details" />
                <AccordionDetails>
                  <Grid
                    container
                    xs={12}
                    spacing={2}
                    alignItems="center"
                    direction="row"
                  >
                    <SwineInput
                      question="Number of herds per year:"
                      value={herdsCnt}
                      onChange={setHerdsCnt}
                      endAdornment="herds"
                    />
                    <SwineInput
                      question="Average number of pigs per herd:"
                      value={pigsPerHerd}
                      onChange={setPigsPerHerd}
                      endAdornment="pigs"
                    />
                    <SwineInput
                      question="Number of days in the growth stage:"
                      value={growthStageDaysCnt}
                      onChange={setGrowthStageDaysCnt}
                      endAdornment="days"
                    />
                    <SwineInput
                      question="Initial body weight:"
                      value={startWeight}
                      onChange={setStartWeight}
                      endAdornment="lbs"
                    />
                    <SwineInput
                      question="Final body weight:"
                      value={endWeight}
                      onChange={setEndWeight}
                      endAdornment="lbs"
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
            {animalCategoryId === 21284 && (
              <>
                <Accordion
                  defaultExpanded
                  sx={{
                    borderRadius: ".3em",
                  }}
                >
                  <SwineHeader text="Gestation Details" />
                  <AccordionDetails>
                    <Grid
                      container
                      xs={12}
                      spacing={1}
                      alignItems="center"
                      direction="row"
                    >
                      <SwineInput
                        question="Number of gestation cycles per year:"
                        value={gestationCyclesCnt}
                        onChange={setGestationCyclesCnt}
                        endAdornment="cycles"
                      />
                      <SwineInput
                        question="Number of sows per gestation cycle:"
                        value={sowsPerCycle}
                        onChange={setSowsPerCycle}
                        endAdornment="sows"
                      />
                      <SwineInput
                        question="Number of days per gestation cycle:"
                        value={gestationCycleDaysCnt}
                        onChange={setGestationCycleDaysCnt}
                        endAdornment="days"
                      />
                      <SwineInput
                        question="Average sow body weight during gestation period:"
                        value={sowWeight}
                        onChange={setSowWeight}
                        endAdornment="lbs"
                      />
                      <SwineInput
                        question="Average sow weight gain during gestation period:"
                        value={weightGain}
                        onChange={setWeightGain}
                        endAdornment="lbs"
                      />
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    borderRadius: ".3em",
                  }}
                >
                  <SwineHeader text="Litter Details" />
                  <AccordionDetails>
                    <Grid
                      container
                      xs={12}
                      spacing={2}
                      alignItems="center"
                      direction="row"
                    >
                      <SwineInput
                        question="Average number of head per litter:"
                        value={headPerLitter}
                        onChange={setHeadPerLitter}
                        endAdornment="piglets"
                      />
                      <SwineInput
                        question="Average body weight of piglets at birth:"
                        value={birthWeightPiglets}
                        onChange={setBirthWeightPiglets}
                        endAdornment="lbs"
                      />
                      <SwineInput
                        question="Average body weight of at weaning:"
                        value={weanWeightPiglets}
                        onChange={setWeanWeightPiglets}
                        endAdornment="lbs"
                      />
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
            <Accordion
              sx={{
                borderRadius: ".3em",
              }}
            >
              <SwineHeader text="Feed Details" />
              <AccordionDetails>
                <Grid
                  container
                  xs={12}
                  spacing={2}
                  alignItems="center"
                  direction="row"
                >
                  <SwineInput
                    question="Average daily dry matter intake:"
                    value={avgDMI}
                    onChange={setAvgDMI}
                    endAdornment="lbs"
                  />
                  <SwineInput
                    question="Crude protein:"
                    value={crudeProtein}
                    onChange={setCrudeProtein}
                    endAdornment="%"
                    min={0}
                    max={100}
                  />
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid container py={2} px={2} justifyContent="space-around">
            <Grid item xs={12} sm={12} md={12}>
              <CFButton
                type="submit"
                sx={{
                  float: "right",
                }}
                color="progress"
                onClick={updateSwine}
                disabled={!validateUserInputs()}
              >
                Save
              </CFButton>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-around">
            <Grid item xs={2} sm={2} md={2} alignItems="center">
              <CFBox>
                <CFTypography
                  sx={{
                    fontSize: "15px",
                    color: !saveState.includes("Error") ? "green" : "red",

                    marginBottom: "0.5em",
                    marginTop: "0.5em",
                  }}
                >
                  {saveState}
                </CFTypography>
              </CFBox>
            </Grid>
          </Grid>
        </CFBox>
      </Grid>
    </CFBox>
  );
}
