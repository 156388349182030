import { useEffect, useState } from "react";

import { Grid, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses, ChartsReferenceLine } from "@mui/x-charts";
import colors from "assets/theme/base/colors";
import CFGraphBox from "components/CFGraphBox";

function AgroforestryParcelGraph({ parcel }) {
  // Determine if data object contains scenario or species data
  const [isScenario, setIsScenario] = useState(!!parcel.scenariodata);

  // Chart display settings
  const chartSetting = {
    tooltip: { trigger: "item" },
    colors: isScenario ? colors.graphColors.agroforestry.speciesList : [],
    xAxis: [
      {
        scaleType: "band",
        dataKey: "year",
        label: "Year",
        labelStyle: { fontSize: 16, fontWeight: "bold" },
        tickLabelStyle: {
          fontSize: 16,
        },
      },
    ],
    yAxis: [{ label: "Carbon Stock (tonnes CO\u2082 equivalent)" }],
    legend: {
      direction: "row",
      position: {
        vertical: "bottom",
        horizontal: "middle",
      },
      padding: 5,
    },
    margin: { top: 50, right: 75, left: 75, bottom: 150 },
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-33px, 0px)",
        fontWeight: "bold",
      },
      "--ChartsLegend-rootOffsetX": "2em",
      "--ChartsLegend-rootSpacing": "1em",
    },
  };

  // String formatting (capitalize first letter)
  function StringFormat(value) {
    let words = value.replace(/([-])/g, " ");
    words = words.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
      letter.toUpperCase()
    );

    return words;
  }

  // Format chart values and stack labels
  const valueFormatter = (value) => {
    const roundedVal = Math.round((value + Number.EPSILON) * 10) / 10;
    return `${roundedVal.toLocaleString("en-US")} (tonnes CO\u2082 equiv.)`;
  };
  function labelFormatter(value, total) {
    const roundedTotal = Math.round((total + Number.EPSILON) * 10) / 10;
    return `${value} (Total: ${roundedTotal.toLocaleString("en-US")})`;
  }

  // Data display selection
  function GenerateDatasetScenario() {
    const tempData = [];
    const tempSeries = [];

    let currentTotal = 0;
    let totalTen = 0;
    let totalTwenty = 0;
    let totalThirty = 0;
    let totalForty = 0;
    let totalFifty = 0;

    // One object for each year of data
    const dataCurrent = {};
    const dataTen = {};
    const dataTwenty = {};
    const dataThirty = {};
    const dataForty = {};
    const dataFifty = {};

    // Populate data objects
    parcel.scenariodata.forEach((species) => {
      if (!species.invalidspeciesyear) {
        dataCurrent[species.scenarioname] = species.currentTotal;
        currentTotal += species.currentTotal;
      } else {
        dataCurrent[species.scenarioname] = 0;
      }
      if (!species.invalidspeciesyearTen) {
        dataTen[species.scenarioname] = species.futureTenTotal;
        totalTen += species.futureTenTotal;
      } else {
        dataTen[species.scenarioname] = 0;
      }
      if (!species.invalidspeciesyearTwenty) {
        dataTwenty[species.scenarioname] = species.futureTwentyTotal;
        totalTwenty += species.futureTwentyTotal;
      } else {
        dataTwenty[species.scenarioname] = 0;
      }
      if (!species.invalidspeciesyearThirty) {
        dataThirty[species.scenarioname] = species.futureThirtyTotal;
        totalThirty += species.futureThirtyTotal;
      } else {
        dataThirty[species.scenarioname] = 0;
      }
      if (!species.invalidspeciesyearForty) {
        dataForty[species.scenarioname] = species.futureFortyTotal;
        totalForty += species.futureFortyTotal;
      } else {
        dataForty[species.scenarioname] = 0;
      }
      if (!species.invalidspeciesyearFifty) {
        dataFifty[species.scenarioname] = species.futureFiftyTotal;
        totalFifty += species.futureFiftyTotal;
      } else {
        dataFifty[species.scenarioname] = 0;
      }

      // One series object for each species
      const seriesObj = {
        dataKey: species.scenarioname,
        stack: parcel.speciesname,
        label: StringFormat(species.scenarioname),
      };
      tempSeries.push(seriesObj);
    });

    // Add label for year and total value
    dataCurrent.year = labelFormatter(
      parcel.scenariodata[0].year2015.name,
      currentTotal
    );
    dataTen.year = labelFormatter(
      parcel.scenariodata[0].year2025.name,
      totalTen
    );
    dataTwenty.year = labelFormatter(
      parcel.scenariodata[0].year2035.name,
      totalTwenty
    );
    dataThirty.year = labelFormatter(
      parcel.scenariodata[0].year2045.name,
      totalThirty
    );
    dataForty.year = labelFormatter(
      parcel.scenariodata[0].year2055.name,
      totalForty
    );
    dataFifty.year = labelFormatter(
      parcel.scenariodata[0].year2065.name,
      totalFifty
    );

    // Add to array
    tempData.push(dataCurrent);
    tempData.push(dataTen);
    tempData.push(dataTwenty);
    tempData.push(dataThirty);
    tempData.push(dataForty);
    tempData.push(dataFifty);

    return [tempData, tempSeries];
  }
  function GenerateDatasetSpecies() {
    const tempData = [];
    const tempSeries = [];

    let currentTotal = 0;
    let totalTen = 0;
    let totalTwenty = 0;
    let totalThirty = 0;
    let totalForty = 0;
    let totalFifty = 0;

    // One object for each year of data
    const dataCurrent = {
      liveTrees: 0,
      downedDead: 0,
      forestFloor: 0,
      standingDead: 0,
      understory: 0,
    };
    const dataTen = {
      liveTrees: 0,
      downedDead: 0,
      forestFloor: 0,
      standingDead: 0,
      understory: 0,
    };
    const dataTwenty = {
      liveTrees: 0,
      downedDead: 0,
      forestFloor: 0,
      standingDead: 0,
      understory: 0,
    };
    const dataThirty = {
      liveTrees: 0,
      downedDead: 0,
      forestFloor: 0,
      standingDead: 0,
      understory: 0,
    };
    const dataForty = {
      liveTrees: 0,
      downedDead: 0,
      forestFloor: 0,
      standingDead: 0,
      understory: 0,
    };
    const dataFifty = {
      liveTrees: 0,
      downedDead: 0,
      forestFloor: 0,
      standingDead: 0,
      understory: 0,
    };

    // Current year data
    parcel.year2015.emissionsSubcategories.map((category) => {
      if (
        category.specname === parcel.speciesname &&
        category.speciesYear === parcel.year2015.name &&
        parcel.id === category.iddetail &&
        !parcel.invalidspeciesyear
      ) {
        switch (category.name) {
          case "Live Trees":
            dataCurrent.liveTrees = category.value;
            currentTotal += category.value;
            break;
          case "Downed dead wood":
            dataCurrent.downedDead = category.value;
            currentTotal += category.value;
            break;
          case "Forest Floor":
            dataCurrent.forestFloor = category.value;
            currentTotal += category.value;
            break;
          case "Standing dead trees":
            dataCurrent.standingDead = category.value;
            currentTotal += category.value;
            break;
          case "Understory":
            dataCurrent.understory = category.value;
            currentTotal += category.value;
            break;
          default:
            break;
        }
      }
      return null;
    });

    // Ten year data
    parcel.year2025.emissionsSubcategories.map((category) => {
      if (
        category.specname === parcel.speciesname &&
        category.speciesYear === parcel.year2025.name &&
        parcel.id === category.iddetail &&
        !parcel.invalidspeciesyearTen
      ) {
        switch (category.name) {
          case "Live Trees":
            dataTen.liveTrees = category.value;
            totalTen += category.value;
            break;
          case "Downed dead wood":
            dataTen.downedDead = category.value;
            totalTen += category.value;
            break;
          case "Forest Floor":
            dataTen.forestFloor = category.value;
            totalTen += category.value;
            break;
          case "Standing dead trees":
            dataTen.standingDead = category.value;
            totalTen += category.value;
            break;
          case "Understory":
            dataTen.understory = category.value;
            totalTen += category.value;
            break;
          default:
            break;
        }
      }
      return null;
    });

    // Twenty year data
    parcel.year2035.emissionsSubcategories.map((category) => {
      if (
        category.specname === parcel.speciesname &&
        category.speciesYear === parcel.year2035.name &&
        parcel.id === category.iddetail &&
        !parcel.invalidspeciesyearTwenty
      ) {
        switch (category.name) {
          case "Live Trees":
            dataTwenty.liveTrees = category.value;
            totalTwenty += category.value;
            break;
          case "Downed dead wood":
            dataTwenty.downedDead = category.value;
            totalTwenty += category.value;
            break;
          case "Forest Floor":
            dataTwenty.forestFloor = category.value;
            totalTwenty += category.value;
            break;
          case "Standing dead trees":
            dataTwenty.standingDead = category.value;
            totalTwenty += category.value;
            break;
          case "Understory":
            dataTwenty.understory = category.value;
            totalTwenty += category.value;
            break;
          default:
            break;
        }
      }
      return null;
    });

    // Thirty year data
    parcel.year2045.emissionsSubcategories.map((category) => {
      if (
        category.specname === parcel.speciesname &&
        category.speciesYear === parcel.year2045.name &&
        parcel.id === category.iddetail &&
        !parcel.invalidspeciesyearThirty
      ) {
        switch (category.name) {
          case "Live Trees":
            dataThirty.liveTrees = category.value;
            totalThirty += category.value;
            break;
          case "Downed dead wood":
            dataThirty.downedDead = category.value;
            totalThirty += category.value;
            break;
          case "Forest Floor":
            dataThirty.forestFloor = category.value;
            totalThirty += category.value;
            break;
          case "Standing dead trees":
            dataThirty.standingDead = category.value;
            totalThirty += category.value;
            break;
          case "Understory":
            dataThirty.understory = category.value;
            totalThirty += category.value;
            break;
          default:
            break;
        }
      }
      return null;
    });

    // Forty year data
    parcel.year2055.emissionsSubcategories.map((category) => {
      if (
        category.specname === parcel.speciesname &&
        category.speciesYear === parcel.year2055.name &&
        parcel.id === category.iddetail &&
        !parcel.invalidspeciesyearForty
      ) {
        switch (category.name) {
          case "Live Trees":
            dataForty.liveTrees = category.value;
            totalForty += category.value;
            break;
          case "Downed dead wood":
            dataForty.downedDead = category.value;
            totalForty += category.value;
            break;
          case "Forest Floor":
            dataForty.forestFloor = category.value;
            totalForty += category.value;
            break;
          case "Standing dead trees":
            dataForty.standingDead = category.value;
            totalForty += category.value;
            break;
          case "Understory":
            dataForty.understory = category.value;
            totalForty += category.value;
            break;
          default:
            break;
        }
      }
      return null;
    });

    // Fifty year data
    parcel.year2065.emissionsSubcategories.map((category) => {
      if (
        category.specname === parcel.speciesname &&
        category.speciesYear === parcel.year2065.name &&
        parcel.id === category.iddetail &&
        !parcel.invalidspeciesyearFifty
      ) {
        switch (category.name) {
          case "Live Trees":
            dataFifty.liveTrees = category.value;
            totalFifty += category.value;
            break;
          case "Downed dead wood":
            dataFifty.downedDead = category.value;
            totalFifty += category.value;
            break;
          case "Forest Floor":
            dataFifty.forestFloor = category.value;
            totalFifty += category.value;
            break;
          case "Standing dead trees":
            dataFifty.standingDead = category.value;
            totalFifty += category.value;
            break;
          case "Understory":
            dataFifty.understory = category.value;
            totalFifty += category.value;
            break;
          default:
            break;
        }
      }
      return 0;
    });

    // One series object for each data type
    const liveSeries = {
      dataKey: "liveTrees",
      stack: parcel.speciesname,
      label: "Live Trees",
      color: colors.graphColors.agroforestry.liveTrees,
    };
    const downedDeadSeries = {
      dataKey: "downedDead",
      stack: parcel.speciesname,
      label: "Downed Dead Trees",
      color: colors.graphColors.agroforestry.downedDead,
    };
    const forestFloorSeries = {
      dataKey: "forestFloor",
      stack: parcel.speciesname,
      label: "Forest Floor",
      color: colors.graphColors.agroforestry.forestFloor,
    };
    const standingDeadSeries = {
      dataKey: "standingDead",
      stack: parcel.speciesname,
      label: "Standing Dead Trees",
      color: colors.graphColors.agroforestry.standingDead,
    };
    const understorySeries = {
      dataKey: "understory",
      stack: parcel.speciesname,
      label: "Understory",
      color: colors.graphColors.agroforestry.understory,
    };

    dataCurrent.year = labelFormatter(parcel.year2015.name, currentTotal);
    dataTen.year = labelFormatter(parcel.year2025.name, totalTen);
    dataTwenty.year = labelFormatter(parcel.year2035.name, totalTwenty);
    dataThirty.year = labelFormatter(parcel.year2045.name, totalThirty);
    dataForty.year = labelFormatter(parcel.year2055.name, totalForty);
    dataFifty.year = labelFormatter(parcel.year2065.name, totalFifty);

    // Add to arrays
    tempData.push(dataCurrent);
    tempData.push(dataTen);
    tempData.push(dataTwenty);
    tempData.push(dataThirty);
    tempData.push(dataForty);
    tempData.push(dataFifty);

    tempSeries.push(liveSeries);
    tempSeries.push(downedDeadSeries);
    tempSeries.push(forestFloorSeries);
    tempSeries.push(standingDeadSeries);
    tempSeries.push(understorySeries);

    return [tempData, tempSeries];
  }
  const [dataset, setDataset] = useState(() => {
    if (parcel) {
      const [chartData, chartSeries] = isScenario
        ? GenerateDatasetScenario()
        : GenerateDatasetSpecies();
      return chartData;
    }

    return [];
  });
  const [dataSeries, setDataSeries] = useState(() => {
    if (parcel) {
      const [chartData, chartSeries] = isScenario
        ? GenerateDatasetScenario()
        : GenerateDatasetSpecies();
      return chartSeries;
    }

    return [];
  });
  useEffect(() => {
    if (parcel) {
      const [chartData, chartSeries] = isScenario
        ? GenerateDatasetScenario()
        : GenerateDatasetSpecies();

      setDataset(chartData);
      setDataSeries(chartSeries);
    }
  }, [parcel]);

  return (
    <Grid
      container
      direction="column"
      pt={6}
      pb={2}
      id="graphDownload_agroforestry"
    >
      {/* Chart Title */}
      <Grid item container direction="column" alignItems="center" xs={1}>
        <Typography variant="h4">
          {`Total Carbon Stock for Agroforestry: ${parcel.speciesname}`}
        </Typography>
      </Grid>
      {/* Chart */}
      <Grid item container direction="column" alignItems="center" xs={9}>
        <CFGraphBox>
          <BarChart
            dataset={dataset}
            series={dataSeries.map((series) => ({
              ...series,
              highlightScope: {
                highlighted: "series",
                faded: "global",
              },
              valueFormatter,
            }))}
            grid={{ horizontal: true }}
            {...chartSetting}
          >
            <ChartsReferenceLine
              y={0}
              lineStyle={{ strokeDasharray: "10 5", strokeWidth: "2" }}
            />
          </BarChart>
        </CFGraphBox>
      </Grid>
    </Grid>
  );
}
export default AgroforestryParcelGraph;
