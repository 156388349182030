/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import { Checkbox, FormControlLabel, Radio } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { Grid } from "swiper";
import { popupError, jsonCopy } from "utils/generic";
import CFStep from "components/CFarmParts/CFStep";
import {
  HISTORIC_MANAGEMENT_FOR_FIELD_FETCH,
  HISTORIC_MANAGEMENT_SAVE,
} from "constants/api";
import colors from "assets/theme/base/colors";
import CFInput from "components/CFInput";
import CFBox from "components/CFBox";
import CFLoading from "components/CFLoading";
import { post } from "utils/api";
import CFButton from "components/CFButton";

import CFHistoricSelectionCard from "components/CroplandsComponents/CFHistoricSelectionCard/CFHistoricSelectionCard";
import CFLookupPicker from "components/CroplandsComponents/CFLookupPicker/CFLookupPicker";
import { fetchUser } from "actions/user";
import CopyHistoryCard from "./CopyHistoryCard/CopyHistoryCard";

const empty = { id: 0, name: "" };

function HistoricManagement({
  field,
  stepNumber,
  goToPrevious,
  baselineStart,
  fetchProjectInformation,
}) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user);
  const [pre1980ManagementList, setPre1980ManagementList] = useState([]);
  const [post1980ManagementList, setPost1980ManagementList] = useState([]);
  const [post1980TillageList, setPost1980TillageList] = useState([]);
  const [crpTypeList, setCrpTypeList] = useState([]);
  const [initialHistoricManagement, setinitialHistoricManagement] = useState(
    {}
  );

  const [fieldsInRegion, setfieldsInRegion] = useState([]);

  const [pre1980Management, setPre1980Management] = useState(empty);
  const [isCrp, setIsCrp] = useState(false);
  const [post1980Management, setPost1980Management] = useState(empty);
  const [post1980Tillage, setPost1980Tillage] = useState(empty);
  const [crpType, setCrpType] = useState(empty);
  const [CRPStartYear, setCRPStartYear] = useState(1900);
  const [CRPEndYear, setCRPEndYear] = useState(1999);
  const [postCRPManagement, setPostCRPManagement] = useState(empty);
  const [postCRPTillage, setPostCRPTillage] = useState(empty);

  const [CRPEndYearError, setCRPEndYearError] = useState(empty);
  const [CRPStartYearError, setCRPStartYearError] = useState(empty);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [loadingMessage, setLoading] = useState("Select a field...");

  const { name, id } = field;
  const [isCompleteOnLoad, setIsCompleteOnLoad] = useState(false);

  const [copyOpen, setCopyOpen] = useState(false);
  const [copyEnabled, setcopyEnabled] = useState(false);

  const backClicked = () => {
    goToPrevious(stepNumber);
  };

  const openCopyInterface = () => {
    setCopyOpen(true);
  };

  const isComplete = (fetchedHistoricData) => {
    let iscom = false;

    if (fetchedHistoricData) {
      iscom = fetchedHistoricData.historyComplete;

      if (iscom === null || iscom === 0) {
        iscom = false;
      }

      setIsCompleteOnLoad(iscom);
    } else {
      iscom =
        (!isCrp ||
          (true &&
            isCrp &&
            crpType &&
            CRPStartYear &&
            CRPEndYear &&
            postCRPManagement &&
            postCRPTillage &&
            !CRPEndYearError &&
            !CRPStartYearError)) &&
        pre1980Management &&
        post1980Management &&
        (post1980Tillage ||
          (post1980Management &&
            (post1980Management?.id === 3016 ||
              post1980Management?.id === 3020))) &&
        post1980Management?.id !== 0 &&
        pre1980Management?.id !== 0 &&
        (!isCrp ||
          (isCrp &&
            crpType?.id !== 0 &&
            postCRPManagement?.id !== 0 &&
            postCRPTillage?.id !== 0));

      if (iscom === null || iscom === 0) {
        iscom = false;
      }
    }
    return iscom;
  };

  const fetchHistoricManagement = () => {
    if (id && id !== 0) {
      setLoading("Loading...");
      post(HISTORIC_MANAGEMENT_FOR_FIELD_FETCH, {
        user: currentUser,
        fieldid: id,
      })
        .then((res) => {
          if (res.error) {
            popupError(`Historic Management Load Error ${res.error}`, dispatch);
          } else {
            res.data.pre1980managementlist.push(empty);
            setPre1980ManagementList(res.data.pre1980managementlist);
            res.data.post1980managementlist.push(empty);
            setPost1980ManagementList(res.data.post1980managementlist);
            res.data.post1980tillagelist.push(empty);
            setPost1980TillageList(res.data.post1980tillagelist);
            res.data.crptypelist.push(empty);
            setCrpTypeList(res.data.crptypelist);

            setPre1980Management(res.data.historicmanagement.managementPre1980);

            setIsCrp(res.data.historicmanagement.iscrp);
            setPost1980Management(
              res.data.historicmanagement.managementPost1980
            );
            setPost1980Tillage(res.data.historicmanagement.tillagePost1980);
            setCrpType(res.data.historicmanagement.crpType);

            setCRPStartYear(res.data.historicmanagement.crpStartYear);
            setCRPEndYear(res.data.historicmanagement.crpEndYear);
            setPostCRPManagement(res.data.historicmanagement.managementPostCrp);
            setPostCRPTillage(res.data.historicmanagement.tillagePostCrp);

            jsonCopy(res.data.locationsInSameRegion).then(
              (localCopyOtherFields) => {
                const arrImmutableVersion = localCopyOtherFields.map((e) => ({
                  ...e,
                  dataComplete: e.historyComplete,
                }));

                setfieldsInRegion(arrImmutableVersion);
              }
            );

            setinitialHistoricManagement(res.data.historicmanagement);
            isComplete(res.data.historicmanagement);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading("");
        });
    }
    // fetch historic management from field id
    // and fetch available historic managements for that area for field
  };

  const fetchEverything = () => {
    dispatch(fetchUser(currentUser));
    fetchHistoricManagement();
    fetchProjectInformation();
  };

  const copyFieldHistories = (listToCopy) => {
    setLoading("Saving...");

    listToCopy.push(id);

    post(HISTORIC_MANAGEMENT_SAVE, {
      user: currentUser,
      fieldid: JSON.stringify(listToCopy),
      pre1980Management,
      post1980Management,
      post1980Tillage,
      crpType,
      CRPStartYear,
      CRPEndYear,
      postCRPManagement,
      postCRPTillage,
      isCrp,
    })
      .then((res) => {
        if (res.error) {
          popupError(`Historic Management Save Error ${res.error}`, dispatch);
        } else {
          fetchEverything();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading("");
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (id !== 0) {
      setLoading("Saving...");

      post(HISTORIC_MANAGEMENT_SAVE, {
        user: currentUser,
        fieldid: JSON.stringify([id]),
        pre1980Management,
        post1980Management,
        post1980Tillage,
        crpType,
        CRPStartYear,
        CRPEndYear,
        postCRPManagement,
        postCRPTillage,
        isCrp,
      })
        .then((res) => {
          if (res.error) {
            popupError(`Historic Management Save Error ${res.error}`, dispatch);
          } else {
            fetchEverything();
            document.getElementById("Step3").scrollIntoView();
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading("");
        });
    }
  };

  const ValidateCrpYears = () => {
    if (isCrp && CRPStartYear < 1954) {
      setCRPStartYearError("Year must be after 1954");
    } else if (isCrp && CRPStartYear >= baselineStart) {
      setCRPStartYearError(`Year must be before ${baselineStart}`);
    } else if (isCrp && CRPStartYear >= CRPEndYear) {
      setCRPStartYearError("Year must be before the CRP end year");
    } else {
      setCRPStartYearError("");
    }

    if (isCrp && CRPEndYear < 1900) {
      setCRPEndYearError("Year must be after 1954");
    } else if (isCrp && CRPEndYear >= baselineStart) {
      setCRPEndYearError(`Year must be before ${baselineStart}`);
    } else if (isCrp && CRPEndYear <= CRPStartYear) {
      setCRPEndYearError("Year must be after the CRP start year");
    } else {
      setCRPEndYearError("");
    }
  };

  const validateStartYear = (evnt) => {
    setCRPStartYear(evnt.target.value);
  };
  const validateEndYear = (evnt) => {
    setCRPEndYear(evnt.target.value);
  };

  const bottomRef = useRef(null);

  useEffect(() => {
    if (isCompleteOnLoad && bottomRef && bottomRef.current) {
      bottomRef.current.scrollIntoView();
    }
  }, [isCompleteOnLoad, id]);

  const ChangeIsCrp = (newVal) => {
    if (newVal) {
      setCrpType(crpTypeList[0]);
    } else {
      setCrpType(crpTypeList[crpTypeList.length - 1]);
    }

    setIsCrp(newVal);
  };

  const checkSaveEnabled = () => {
    // check if form changed
    let changedFromInitial = false;
    let formAllFilled = false;
    if (
      initialHistoricManagement &&
      initialHistoricManagement.managementPre1980 &&
      initialHistoricManagement.managementPre1980?.id
    ) {
      if (
        pre1980Management?.id !==
          initialHistoricManagement.managementPre1980?.id ||
        isCrp !== initialHistoricManagement.iscrp ||
        post1980Management?.id !==
          initialHistoricManagement.managementPost1980?.id ||
        post1980Tillage?.id !== initialHistoricManagement.tillagePost1980?.id ||
        crpType?.id !== initialHistoricManagement.crpType?.id ||
        CRPStartYear !== initialHistoricManagement.crpStartYear ||
        CRPEndYear !== initialHistoricManagement.crpEndYear ||
        postCRPManagement?.id !==
          initialHistoricManagement.managementPostCrp?.id ||
        postCRPTillage?.id !== initialHistoricManagement.tillagePostCrp?.id
      ) {
        changedFromInitial = true;
      }
    } else {
      changedFromInitial = true;
    }

    if (
      (!isCrp ||
        (isCrp &&
          crpType &&
          CRPStartYear &&
          CRPEndYear &&
          postCRPManagement &&
          postCRPTillage &&
          !CRPEndYearError &&
          !CRPStartYearError)) &&
      pre1980Management &&
      post1980Management &&
      (post1980Tillage ||
        (post1980Management &&
          (post1980Management?.id === 3016 ||
            post1980Management?.id === 3020))) &&
      post1980Management?.id !== 0 &&
      pre1980Management?.id !== 0 &&
      (!isCrp ||
        (isCrp &&
          crpType?.id !== 0 &&
          postCRPManagement?.id !== 0 &&
          postCRPTillage?.id !== 0))
    ) {
      formAllFilled = true;
    }

    setSaveEnabled(changedFromInitial && formAllFilled);
    setcopyEnabled(formAllFilled && fieldsInRegion.length > 0);

    // check if form required attributes filled
  };

  useEffect(() => {
    ValidateCrpYears();
  }, [CRPEndYear, CRPStartYear]);

  useEffect(() => {
    checkSaveEnabled();
  }, [
    postCRPTillage,
    postCRPManagement,
    crpType,
    CRPEndYear,
    CRPStartYear,
    pre1980Management,
    post1980Management,
    post1980Tillage,
    initialHistoricManagement,
    CRPEndYearError,
    CRPStartYearError,
    fieldsInRegion,
  ]);

  useEffect(() => {
    fetchHistoricManagement();
  }, [id]);

  return (
    <CFBox
      component="form"
      method="post"
      autoComplete="off"
      onSubmit={handleSubmit}
      sx={{ position: "relative" }}
    >
      {loadingMessage !== "" && (
        <CFLoading
          message={loadingMessage}
          local
          showCircle={loadingMessage.indexOf("field") === -1}
        />
      )}{" "}
      <CFStep
        stepLabel={
          <div>
            Historic Management: <em>{name}</em>
          </div>
        }
        stepNumber={stepNumber}
        complete={isCompleteOnLoad}
        helpText={
          <div>
            Historic management (pre-1980s and 1980-2000) is required for all
            fields before moving on to baseline management. Historic management
            options for a defined field are dependent on the county-rectified
            MLRA in which the field is located. Users are not able to copy
            historic management from one county to another, but may copy
            historic management to other fields located within the same
            county-rectified MLRA. Gaps in historical management records are
            expected; however, users should select the option that is{" "}
            <em>most representative</em> of their field. Extension offices and
            resource conservation districts may be able to provide insight into
            management typical of the area and time.
          </div>
        }
        subText="Using the dropdown menus, select the historic management that best represents the management of this field prior to the baseline period."
      />
      <CFBox
        sx={{
          position: "relative",
          paddingTop: "1em",
          paddingBottom: "1em",
        }}
      >
        <CFHistoricSelectionCard
          label="Pre-1980 Management"
          input={
            <CFLookupPicker
              label="Select management"
              key="pre1980Management"
              name="pre1980Management"
              required
              sx={{ width: "100%" }}
              value={pre1980Management}
              onChange={(event, newValue) => {
                setPre1980Management(newValue);
              }}
              options={pre1980ManagementList}
            />
          }
        />
        <CFHistoricSelectionCard
          label="Was this field enrolled in Conservation Reserve Program (CRP) prior to the baseline period?"
          labelWidth={8}
          input={
            <CFBox sx={{ display: "flex", justifyItems: "space-between" }}>
              <CFBox sx={{ display: "flex" }}>
                <FormControlLabel
                  value="Yes"
                  control={
                    <Radio
                      checked={isCrp}
                      onChange={(e) => {
                        ChangeIsCrp(true);
                      }}
                      value
                      name="radio-buttons"
                      inputProps={{ "aria-label": "Yes" }}
                    />
                  }
                  label="Yes"
                  labelPlacement="end"
                />

                <FormControlLabel
                  value="No"
                  control={
                    <Radio
                      checked={!isCrp}
                      onChange={(e) => {
                        ChangeIsCrp(!true);
                      }}
                      value
                      name="radio-buttons"
                      inputProps={{ "aria-label": "No" }}
                    />
                  }
                  label="No"
                  labelPlacement="end"
                />
              </CFBox>
            </CFBox>
          }
        />
        {isCrp && (
          <CFBox>
            <CFHistoricSelectionCard
              label="CRP Start Year"
              input={
                <CFInput
                  inputProps={{ style: { fontSize: "1.25em" } }}
                  key="crpStartYear"
                  name="crpStartYear"
                  type="number"
                  value={CRPStartYear}
                  onChange={validateStartYear}
                  error={CRPStartYearError !== ""}
                  helperText={CRPStartYearError}
                  sx={{ backgroundColor: colors.white.main }}
                />
              }
            />
            <CFHistoricSelectionCard
              label="CRP End Year"
              input={
                <CFInput
                  inputProps={{ style: { fontSize: "1.25em" } }}
                  key="crpEndYear"
                  name="crpEndYear"
                  type="number"
                  onChange={validateEndYear}
                  value={CRPEndYear}
                  error={CRPEndYearError !== ""}
                  helperText={CRPEndYearError}
                  sx={{ backgroundColor: colors.white.main }}
                />
              }
            />

            <CFHistoricSelectionCard
              label="CRP Type"
              input={
                <CFLookupPicker
                  label="Select crp type"
                  key="crpType"
                  name="crpType"
                  sx={{ width: "100%" }}
                  value={crpType}
                  onChange={(event, newValue) => {
                    setCrpType(newValue);
                  }}
                  options={crpTypeList}
                />
              }
            />

            <CFHistoricSelectionCard
              label="Pre-CRP Management"
              input={
                <CFLookupPicker
                  label="Select management"
                  required
                  key="post1980Management"
                  name="post1980Management"
                  sx={{ width: "100%" }}
                  value={post1980Management}
                  onChange={(event, newValue) => {
                    setPost1980Management(newValue);
                  }}
                  options={post1980ManagementList}
                />
              }
            />
            <CFHistoricSelectionCard
              label="Pre-CRP Tillage"
              input={
                <CFLookupPicker
                  label="Select a tillage"
                  required
                  key="post1980Tillage"
                  name="post1980Tillage"
                  sx={{ width: "100%" }}
                  value={post1980Tillage}
                  onChange={(event, newValue) => {
                    setPost1980Tillage(newValue);
                  }}
                  options={post1980TillageList}
                />
              }
            />
            <CFHistoricSelectionCard
              label="Post-CRP Management"
              input={
                <CFLookupPicker
                  label="Select management"
                  required
                  key="postCRPManagement"
                  name="postCRPManagement"
                  sx={{ width: "100%" }}
                  value={postCRPManagement}
                  onChange={(event, newValue) => {
                    setPostCRPManagement(newValue);
                  }}
                  options={post1980ManagementList}
                />
              }
            />
            <CFHistoricSelectionCard
              label="Post-CRP Tillage"
              input={
                <CFLookupPicker
                  label="Select a tillage"
                  required
                  key="postCRPTillage"
                  name="postCRPTillage"
                  sx={{ width: "100%" }}
                  value={postCRPTillage}
                  onChange={(event, newValue) => {
                    setPostCRPTillage(newValue);
                  }}
                  options={post1980TillageList}
                />
              }
            />
          </CFBox>
        )}
        {!isCrp && (
          <CFBox>
            <CFHistoricSelectionCard
              label="1980 to the Beginning of Baseline Period Management"
              input={
                <CFLookupPicker
                  label="Select management"
                  required
                  key="post1980Management"
                  name="post1980Management"
                  sx={{ width: "100%" }}
                  value={post1980Management}
                  onChange={(event, newValue) => {
                    setPost1980Management(newValue);
                  }}
                  options={post1980ManagementList}
                />
              }
            />
            {post1980Management &&
              post1980Management.id !== 3016 &&
              post1980Management.id !== 3020 && (
                <CFHistoricSelectionCard
                  label="1980 to the Beginning of Baseline Period Tillage"
                  input={
                    <CFLookupPicker
                      label="Select a tillage"
                      required
                      key="post1980Tillage"
                      name="post1980Tillage"
                      sx={{ width: "100%" }}
                      value={post1980Tillage}
                      onChange={(event, newValue) => {
                        setPost1980Tillage(newValue);
                      }}
                      options={post1980TillageList}
                    />
                  }
                />
              )}
          </CFBox>
        )}
        {/* Here is where the next block of historic management questions would go, to define a period between Beginning of Baseline Period and the start of the baseline management period once we allow baseline start to shift */}
      </CFBox>
      <CFButton
        size="large"
        color="progress"
        variant="gradient"
        type="submit"
        sx={{ textTransform: "none", float: "right", margin: "1em" }}
        disabled={!saveEnabled}
      >
        Save
      </CFButton>
      {fieldsInRegion.length > 0 && (
        <CFButton
          size="large"
          color="secondary"
          variant="gradient"
          onClick={openCopyInterface}
          sx={{ textTransform: "none", float: "right", margin: "1em" }}
          disabled={!copyEnabled}
          ref={bottomRef}
        >
          Copy Management to Other Fields
        </CFButton>
      )}
      <CopyHistoryCard
        title="History"
        isOpen={copyOpen}
        setIsOpen={setCopyOpen}
        locationName={name}
        fieldsInRegion={fieldsInRegion}
        copyFieldsHistories={copyFieldHistories}
      />
    </CFBox>
  );
}

export default HistoricManagement;
