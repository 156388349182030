/* eslint-disable no-use-before-define */
import React from "react";
import { Link } from "react-router-dom";
import { Link as MUILink } from "@mui/material";
import PropTypes from "prop-types";
import colors from "assets/theme/base/colors";

export default function CFLink(props) {
  const { to, href, ...rest } = props;
  if (startsWithCheck(to, "/")) {
    return <InternalReactRouterLink to={to} {...rest} />;
  }
  if (startsWithCheck(to, "http")) {
    return <ExternalLink to={to} {...rest} />;
  }
  if (startsWithCheck(href, "http")) {
    return <ExternalLink href={href} {...rest} />;
  }
  if (startsWithCheck(href, "#")) {
    return <ExternalLink href={href} {...rest} />;
  }
  return <ExternalLink href={href} {...rest} />;
}

export function InternalReactRouterLink(props) {
  const { label, to, children, ...rest } = props;
  return (
    <MUILink
      to={to}
      component={Link}
      style={{
        fontSize: ".8em",
        textDecoration: "underline",
        color: colors.tertiary.main,
      }}
      {...rest}
    >
      {children || label}
    </MUILink>
  );
}

export function ExternalLink(props) {
  const { label, href, children, ...rest } = props;
  return (
    <MUILink
      href={href}
      component="a"
      style={{
        fontSize: ".8em",
        textDecoration: "underline",
        color: colors.tertiary.main,
      }}
      {...rest}
    >
      {children || label}
    </MUILink>
  );
}

CFLink.defaultProps = {
  to: "",
  href: "",
};

CFLink.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
};

InternalReactRouterLink.defaultProps = {
  label: "",
  to: "/",
  children: null,
};

InternalReactRouterLink.propTypes = {
  to: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.node,
};

ExternalLink.defaultProps = {
  href: "",
  label: "",
  children: null,
};

ExternalLink.propTypes = {
  href: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.node,
};

function startsWithCheck(string, prefix) {
  return string.toString().startsWith(prefix);
}
