/* eslint-disable no-use-before-define */
import React from "react";

import { Link } from "@mui/material";
import PropTypes from "prop-types";
import colors from "assets/theme/base/colors";
import { Box } from "@mui/system";
import CFTypography from "components/CFTypography";

export default function CFLinkImage(props) {
  const { src, alt, href, ...rest } = props;

  return (
    <Link {...rest} href={href} target="_blank" underline="hover" px={2}>
      <Box
        style={{ height: "13em", display: "flex", justifyContent: "center" }}
      >
        <img
          src={src}
          alt={alt}
          style={{ height: "100%", width: "auto", display: "block" }}
        />
      </Box>
      <CFTypography
        variant="h5"
        fontWeight="regular"
        pt={2}
        px={1}
        align="center"
      >
        {alt}
      </CFTypography>
    </Link>
  );
}
