/* eslint-disable no-underscore-dangle */
import _ from "lodash";
import { clone, setWith, curry } from "lodash/fp";
import moment from "moment";
import momentZone from "moment-timezone";
import React from "react";
import { useLocation } from "react-router-dom";
import WKT from "ol/format/WKT";
import GeoJSON from "ol/format/GeoJSON";
import { fromCircle } from "ol/geom/Polygon";
import { Fill, Icon, Stroke, Style, Text } from "ol/style";
import { updatePopup } from "store/reducers/popup";
import pointMarker from "assets/images/field/field_point.png";
import pointMarkerSelected from "assets/images/field/field_point_selected.png";
import pointMarkerComplete from "assets/images/field/field_point_complete.png";
import pointMarkerInvalidField from "assets/images/field/field_point_invalid.png";
import pointMarkerInvalidFieldSelected from "assets/images/field/field_point_invalid_selected.png";
import pointMarkerSelectedComplete from "assets/images/field/field_point_complete_selected.png";
import colors from "assets/theme/base/colors";

const userTZ = momentZone.tz.guess();

export const callIf = (condition, func) => (condition ? func : () => null);

export const getUniqueName = (name, otherNames) => {
  const suffixes = otherNames
    .filter((n) => n.startsWith(name))
    .map((n) => n.slice(name.length));
  if (!suffixes.includes("")) return name;

  let number = 1;
  while (number) {
    const strNumber = number.toString();
    if (!suffixes.includes(strNumber)) return name + strNumber;
    number += 1;
  }
  return name;
};

export const preloadImage = (url) => {
  const img = new Image();
  img.src = url;
};

export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const popupError = (_message, dispatch) => {
  console.log("popup error", _message);

  dispatch(
    updatePopup({
      // popup is simple so can use the global popup
      title: "Error",
      message: String(_message),
      type: "OK",
      showPopup: true,
    })
  );
};

export const takeFromState = (state, stateObjectName, fields) =>
  _.pick(state[stateObjectName], fields);

export const setIn = curry((obj, path, value) =>
  setWith(clone, path, value, clone(obj))
);

export const firstUpperWords = (text, length) =>
  text
    .split(" ")
    .map((element) => element[0].toUpperCase())
    .splice(0, length);

export const sliceWithDots = (text, length) =>
  text.length > length ? `${text.slice(0, length)}...` : text;

export const pluralize = (text, amount) => (amount === 1 ? text : `${text}s`);

export const pascalToText = (text) =>
  _.capitalize(text.replace(/([A-Z][a-z])/g, " $1").replace(/(\d)/g, " $1"));

export const snakeToText = (text) => _.capitalize(text.split("_").join(" "));
export const def = (v) => v === undefined;

export const logArgs =
  (func) =>
  (...args) => {
    console.log(`${func.name} args: `, ...args);
    return func(...args);
  };

export const getStaticAdUrl = (ad) =>
  `${ad.city}/${ad.state}/${ad.company.replace(/\s/g, "-")}`;

/* Date coming in should be in utc */
export const getDateString = (date) => {
  const dt = moment.utc(date);
  const dtz = moment.tz(dt, userTZ);
  let dateS = "Invalid Date";

  if (dtz)
    dateS = `${(dtz.month() + 1).toString().padStart(2, "0")}/${dtz
      .date()
      .toString()
      .padStart(2, "0")}/${dtz.year().toString().padStart(4, "0")} ${dtz
      .hour()
      .toString()
      .padStart(2, "0")}:${dtz.minute().toString().padStart(2, "0")}:${dtz
      .seconds()
      .toString()
      .padStart(2, "0")} ${dtz.zoneAbbr()}`;

  return dateS;
};

const deg2rad = (deg) => deg * (Math.PI / 180);
export const getDistance = (lat1, lon1, lat2, lon2) => {
  const R = 3958.8; // radius of earth in miles
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in mi

  return d;
};

const PayloadOrInitialState = (payload, param, initialState) =>
  payload[param] ? payload[param] : initialState[param];

export function SetStateFromPayload(state, payload, initialState) {
  for (const param in initialState) {
    if (Object.hasOwn(state, param)) {
      // eslint-disable-next-line no-param-reassign
      state[param] = PayloadOrInitialState(payload, param, initialState);
    }
  }
}

export const getExampleFeatureCollection = () => {
  const format = new WKT();
  const wktString =
    "POLYGON ((-10576788.286889002 5249070.0661803, -10575718.168493003 5249060.5115517, -10575718.168493003 5248635.330582, -10576785.898231998 5248654.439839, -10576790.675545998 5248797.759267, -10576788.286889002 5249070.0661803))";
  const feature = format.readFeature(wktString, {
    dataProjection: "EPSG:3857",
    featureProjection: "EPSG:3857",
  });

  feature.setProperties({
    id: "96968",
    name: "F1",
    acres: "60",
    acresLabel: "acres",
    area: "60.272",
    wktString:
      "POLYGON ((-10576788.286889002 5249070.0661803, -10575718.168493003 5249060.5115517, -10575718.168493003 5248635.330582, -10576785.898231998 5248654.439839, -10576790.675545998 5248797.759267, -10576788.286889002 5249070.0661803))",
    soilComponentStatus: "success",
    canPerformAnalysis: false,
    dataComplete: false,
  });
  // console.log("Example Featuere : ", feature);
  return feature;
};

export const jsonCopy = async (inObject) =>
  JSON.parse(JSON.stringify(inObject));

export const convertCometFeatures = (locationHolder) => {
  // const format = new WKT();
  let listOfConvertedFeatures = [];

  if (
    locationHolder &&
    locationHolder.featureCollection &&
    locationHolder.featureCollection.features
  ) {
    listOfConvertedFeatures = new GeoJSON().readFeatures(
      locationHolder.featureCollection,
      {
        dataProjection: "EPSG:3857",
        featureProjection: "EPSG:3857",
      }
    );
  } else {
    // console.log("No features : TODO");
  }

  return listOfConvertedFeatures;
};

export const getZoomLevel = (boundingBox) => {
  const yDifference = boundingBox[0] - boundingBox[2];
  const xDifference = boundingBox[1] - boundingBox[3];
  const distance = Math.sqrt(
    xDifference * xDifference + yDifference * yDifference
  );

  // 4PL curve provided by mycurvefit.com from sample points
  return -144.3429 + 180.11539 / (1 + (distance / 87703640000) ** 0.07238359);
};

export const getGeoType = (feature) =>
  feature.getGeometry().getType().toLowerCase();

export const convertGeometry = (feature) => {
  let geometry = feature.getGeometry();
  const wkt = new WKT();
  if (getGeoType(feature) === "circle") {
    geometry = fromCircle(geometry);
  }
  return wkt.writeGeometry(geometry);
};

// export const verifyZipCode = (zipCodeToVerify) => {
//   const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCodeToVerify);
//   if (isValidZip) {
//     return true;
//   }
//   return false;
// };
export const mathRoundRenderer = (obj) => {
  console.log("Math Rounder ", obj);
  return Math.round(obj.value);
};

export const nameColumnRenderer = (obj) => obj.value;

const getStyle = (color, iconSrc) =>
  new Style({
    fill: new Fill({
      color: color.fill,
    }),
    stroke: new Stroke({
      color: color.border,
      width: 4,
    }),
    text: new Text({
      font: "bold 18px Calibri,sans-serif",
      overflow: true,
      fill: new Fill({
        color: color.text,
      }),
      stroke: new Stroke({
        color: color.textBorder,
        width: 3,
      }),
    }),
    image: new Icon({
      anchor: [0.5, 0],
      anchorXUnits: "fraction",
      anchorYUnits: "pixels",
      width: 60,
      height: 75,
      displacement: [0, 25],
      opacity: ".75",
      crossOrigin: "anonymous",
      src: iconSrc,
    }),
  });

export const getMapStyle = () => {
  const styleFunction = (feature) => {
    const parcelLabel = ` ${feature.get("name")} (${feature.get("acres")}) `;
    let style;
    if (feature.values_.soilComponentStatus !== "success") {
      if (feature.values_.selected)
        style = getStyle(
          colors.mapColors.invalidFieldSelected,
          pointMarkerInvalidFieldSelected
        );
      else
        style = getStyle(
          colors.mapColors.invalidField,
          pointMarkerInvalidField
        );
    } else if (feature.values_.dataComplete) {
      if (feature.values_.selected)
        style = getStyle(
          colors.mapColors.selectedComplete,
          pointMarkerSelectedComplete
        );
      else style = getStyle(colors.mapColors.complete, pointMarkerComplete);
    } else if (feature.values_.selected) {
      style = getStyle(
        colors.mapColors.selectedIncomplete,
        pointMarkerSelected
      );
    } else style = getStyle(colors.mapColors.incomplete, pointMarker);

    style.getText().setText(parcelLabel);
    return style;
  };

  return styleFunction;
};

/* If we don't check activity type and just check the list then that allows for multi-accounting */
export const isDemo = (activityType, currentUser) => {
  const scenarioName = currentUser?.activeProject?.name;

  if (scenarioName === "Croplands Demo Project") return true;

  if (scenarioName === "Agroforestry Demo Project") return true;

  if (scenarioName === "Forestry Demo Project") return true;

  if (
    scenarioName === "Animal Ag Dairy Demo" ||
    scenarioName === "Animal Ag Beef Demo"
  )
    return true;
  return false;

  // If we only want one activity type per demo use this
  // switch (activityType) {
  //   case 10:
  //     if (scenarioName === "Croplands Demo Project") return true;
  //     break;
  //   case 13:
  //     if (scenarioName === "Agroforestry Demo Project") return true;
  //     return false;
  //   case 14:
  //     if (scenarioName === "Forestry Demo Project") return true;
  //     return false;
  //   case 212320:
  //     if (
  //       scenarioName === "Animal Ag Dairy Demo" ||
  //       scenarioName === "Animal Ag Beef Demo"
  //     )
  //       return true;
  //     return false;
  //   default:
  //     return false;
  // }
  // return false;
};
