import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types"; // @mui material components
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteForeverTwoToneIcon from "@mui/icons-material/DeleteForeverTwoTone";
import Link from "@mui/material/Link";
import CFarmPage from "components/CFarmParts/CFarmPage";
import CFStep from "components/CFarmParts/CFStep";
import CFTypography from "components/CFTypography";
import CFBox from "components/CFBox";
import colors from "assets/theme/base/colors";
import ScenarioImage from "assets/cFarmImages/HelpImages/animal ag scenario.png";

import CFDialog from "components/CFDialog";

import { popupError, preloadImage } from "utils/generic";
import {
  FETCH_ALL_SCENARIOS,
  FETCH_POP_BY_SCENARIO,
  DELETE_ANIMAL_SCENARIO,
  FETCH_BASELINE_SCENARIO,
} from "constants/api";
import { post } from "utils/api";
import CFLoading from "components/CFLoading";
import style from "./AnimalAgStyle";

function ScenarioSideBar(props) {
  const {
    anagOpObject,
    anagObjectChanged,
    scenObjectChanged,
    animalDetailsChanged,
  } = props;

  const dispatch = useDispatch();
  preloadImage(ScenarioImage);
  const [localPopupState, updateLocalPopup] = useState({});
  const currentUser = useSelector((state) => state.user);
  const [baselineId, setBaselineId] = useState("");
  const [scenarioArr, setScenarioArr] = useState([]);

  const [loading, setLoading] = useState(false);
  const { info } = colors;

  const resetScenarios = () => {
    scenarioArr.forEach((sce) => {
      document.getElementById(
        `CheckCircle${sce.animalScenarioId}`
      ).style.color = "grey";
    });
  };
  const fetchScenario = (scenarioId) => {
    resetScenarios();

    setLoading(true);

    const sceObj = {
      id: currentUser,
      scid: scenarioId,
    };

    post(FETCH_POP_BY_SCENARIO, sceObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const json = res.data;
          scenObjectChanged(json.animalScenarioName);
          anagObjectChanged(true);
          document.getElementById(`CheckCircle${scenarioId}`).style.color =
            "green";
        }
        animalDetailsChanged(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const fetchAllScenarios = () => {
    setLoading(true);
    const scenarioObj = {
      id: currentUser,
    };

    post(FETCH_ALL_SCENARIOS, scenarioObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const json = res.data;
          setScenarioArr(json);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const deleteScenario = (scenarioId) => {
    setLoading(true);

    const sceObj = {
      id: currentUser,
      scid: scenarioId,
    };

    post(DELETE_ANIMAL_SCENARIO, sceObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          scenObjectChanged("Baseline");
          anagOpObject.selectedScenario = "Baseline";

          document.getElementById(`CheckCircle${baselineId}`).style.color =
            "green";
          fetchScenario(baselineId);
          fetchAllScenarios();
          updateLocalPopup({
            title: "Scenario deleted.",
            message: res.Data,
            type: "OK",
            showPopup: true,
          });
        }
        animalDetailsChanged(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  function deleteConfirmChange(event, scenarioToDelete) {
    const nameTarget = scenarioToDelete.animalScenarioName.toLowerCase().trim();
    const typedTarget = event.target.value.toLowerCase().trim();

    if (nameTarget === typedTarget) {
      updateLocalPopup({
        title: `Delete ${scenarioToDelete.animalScenarioName}?`,
        component: {
          type: "input",
          label: `Delete is enabled`,

          defaultValue: scenarioToDelete.animalScenarioName,
          disabled: true,
        },
        button2: {
          onClick: () => deleteScenario(scenarioToDelete.animalScenarioId),
          text: `Delete ${scenarioToDelete.animalScenarioName}`,
          variant: "gradient",
          color: "error",
        },

        showPopup: true,
      });
    }
  }
  function deleteScenarioClicked(scenarioToDelete) {
    updateLocalPopup({
      title: `Delete ${scenarioToDelete.animalScenarioName}?`,
      message: `Type the scenario name to enable the delete button`,
      component: {
        type: "input",
        label: `${scenarioToDelete.animalScenarioName}`,
        onChange: (e) => deleteConfirmChange(e, scenarioToDelete),
      },

      button2: {
        text: `Delete ${scenarioToDelete.animalScenarioName}`,
        variant: "gradient",
        color: "progress",
        disabled: true,
      },

      showPopup: true,
    });
  }

  const fetchBaselineScenario = () => {
    setLoading(true);

    const sceObj = {
      id: currentUser,
    };

    post(FETCH_BASELINE_SCENARIO, sceObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const json = res.data;
          if (json !== null) {
            setBaselineId(json.animalScenarioId);
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (
      anagOpObject.id !== "" &&
      anagOpObject.selectedScenario !== "" &&
      anagOpObject.selectedScenario !== null
    ) {
      fetchAllScenarios();
      fetchBaselineScenario();
    }
  }, [anagOpObject.selectedScenario, anagOpObject.activeAnimalCategories]);

  return (
    <CFBox>
      {anagOpObject.id !== "" &&
        anagOpObject.selectedScenario !== "" &&
        anagOpObject.selectedScenario !== null && (
          <CFBox sx={{ width: style.width }}>
            {loading && <CFLoading local />}
            <Grid
              container
              direction="column"
              item
              xs={12}
              sm={12}
              md={12}
              alignItems="flex-end"
            >
              <Grid item xs={12} sm={12} md={12}>
                <CFBox
                  sx={{
                    backgroundColor: colors.background.header,
                    float: "right",
                    px: "1em",
                    py: ".5em",
                  }}
                >
                  <CFStep
                    stepLabel={
                      <div
                        style={{
                          fontSize: "14px",
                          color: info.dullFocus,
                          fontWeight: "bold",
                          marginTop: "0.3em",
                        }}
                      >
                        Select scenario to view/edit
                      </div>
                    }
                    style={{
                      fontSize: "14px",
                      color: info.dullFocus,
                      fontWeight: "bold",
                      marginTop: "0.3em",
                    }}
                    helpText={
                      <CFBox
                        sx={{
                          overflow: "auto",
                          height: "600px",
                          maxHeight: "60vh",
                        }}
                      >
                        <img
                          src={ScenarioImage}
                          width="100%"
                          alt="Scenario Help"
                        />
                      </CFBox>
                    }
                  />

                  {scenarioArr.map((sce) => (
                    <CFBox
                      key={sce.animalScenarioId}
                      sx={{
                        display: "flex",
                      }}
                    >
                      <CheckCircleIcon
                        id={`CheckCircle${sce.animalScenarioId}`}
                        style={{
                          color:
                            sce.animalScenarioId ===
                            anagOpObject.selectedScenarioId
                              ? "green"
                              : "grey",
                          marginTop: "0.3em",
                          marginRight: "0.5em",
                        }}
                      />
                      <CFTypography
                        onClick={() => fetchScenario(sce.animalScenarioId)}
                        sx={{
                          fontSize: "20px",
                          color: info.dullFocus,
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                      >
                        {sce.animalScenarioName}
                      </CFTypography>
                      {sce.animalScenarioName !== "Baseline" && (
                        <DeleteForeverTwoToneIcon
                          style={{
                            marginTop: "0.3em",
                            marginLeft: "0.5em",
                            cursor: "pointer",
                          }}
                          onClick={() => deleteScenarioClicked(sce)}
                        />
                      )}
                    </CFBox>
                  ))}
                </CFBox>
              </Grid>
            </Grid>
            {localPopupState && localPopupState.showPopup && (
              <CFDialog
                {...localPopupState}
                updatePopup={(e) => updateLocalPopup(e)}
              />
            )}
          </CFBox>
        )}
    </CFBox>
  );
}

export default ScenarioSideBar;
