// React
import { useState } from "react";
import { useDispatch } from "react-redux";

// MUI
import { Grid, Checkbox, Divider } from "@mui/material";
import colors from "assets/theme/base/colors";

// Our Components
import CFBox from "components/CFBox";
import CFTypography from "components/CFTypography";
import CFInput from "components/CFInput";
import CFButton from "components/CFButton";
import { upload } from "utils/api";
import { updateLoading } from "store/reducers/mask";
import { popupError } from "utils/generic";
import { UPLOAD_ESRI_FILE } from "constants/api";

export default function ESRIUpload({
  setIsOpen,
  currentUser,
  parcelWording,
  activityTypeId,
  toggleResetToggle,
  updateLocations,
}) {
  const dispatch = useDispatch();
  const [idField, setIdField] = useState("");
  const [area, setArea] = useState("");
  const [isOverwrite, setIsOverwrite] = useState(false);

  const [file, setFile] = useState();
  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleOnCancel = () => {
    setIsOpen(false);
    toggleResetToggle();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("Files", file);
    formData.append("sfParcelId", idField);
    formData.append("sfArea", area);
    formData.append("sfOverwrite", isOverwrite);
    formData.append("userId", currentUser.id);
    formData.append("activityTypeId", activityTypeId);
    dispatch(updateLoading({ loading: true }));
    upload(UPLOAD_ESRI_FILE, formData)
      .then((res) => {
        if (res.error) {
          console.log("Error obj ", res.error);
          popupError(
            `Error saving ${parcelWording.toLowerCase()} locations.  Please try again.`,
            dispatch
          );
        } else {
          updateLocations(res);
        }
        setIsOpen(false);
        dispatch(updateLoading({ loading: false }));
        toggleResetToggle();
      })
      .catch((err) => {
        popupError(
          `Error saving ${parcelWording.toLowerCase()} locations.  Please try again.`,
          dispatch
        );
        dispatch(updateLoading({ loading: false }));
        toggleResetToggle();
        console.log(err);
      });
  };
  const onIdFieldChange = (e) => {
    setIdField(e.target.value);
  };
  const onAreaChange = (event) => {
    setArea(event.target.value);
  };

  return (
    <CFBox component="form" onSubmit={handleSubmit}>
      {
        <Grid container direction="row">
          {/* Window Header */}
          <CFTypography variant="h6" pl={1.5} pt={1} pb={0.5}>
            Upload Shape File
          </CFTypography>
          <Divider sx={{ width: "100%", mt: 0, mb: 2 }} />
          <Grid item xs={10.5}>
            <CFTypography fontSize={16} pl={2}>
              Specify the {parcelWording} identification field (please have
              unique ids, otherwise, a default name will be chosen):
            </CFTypography>
            <CFInput
              // required
              fullWidth
              className="cfmap-input"
              placeholder="Id Field"
              value={idField || ""}
              onChange={(e) => onIdFieldChange(e)}
            />
            <CFTypography fontSize={16} pl={2} py={2}>
              Total Area (required for point files):
            </CFTypography>
            <CFInput
              fullWidth
              className="cfmap-input"
              placeholder="Total Area"
              value={area || ""}
              onChange={(e) => onAreaChange(e)}
            />
          </Grid>
          <Grid item xs={8}>
            <CFTypography fontSize={16} pl={2} pr={0} py={2}>
              Overwrite existing areas?
            </CFTypography>
          </Grid>
          <Grid item xs={2} pt={1.5} pb={2}>
            <Checkbox
              value={false}
              px={0}
              mx={0}
              onChange={(e) => setIsOverwrite(e.target.value)}
            />
          </Grid>
          <CFBox pb={2}>
            <input
              type="file"
              accept=".zip"
              className="cfmap-input"
              onChange={handleChange}
            />
            {!file && (
              <CFTypography fontSize={14} pl={2} pr={0} py={2}>
                *File must be in .zip format to be uploaded successfully
              </CFTypography>
            )}
          </CFBox>
          {/* Action buttons */}
          <Grid container py={2} px={2} justifyContent="space-between">
            {/* Return to map page */}
            <CFButton color="secondary" onClick={handleOnCancel}>
              Cancel
            </CFButton>
            {/* Submit changes */}
            <CFButton
              type="submit"
              color="progress"
              // disabled={showNameError || showAreaError}
            >
              Save Changes
            </CFButton>
          </Grid>
        </Grid>
      }
    </CFBox>
  );
}
