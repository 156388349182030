// React
import { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
// MUI
import { Grid, Dialog } from "@mui/material";
import { DataGrid, gridClasses, GridToolbar } from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";

import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";

import CFCheckbox from "components/CFCheckbox/CFCheckbox";
import CFStep from "components/CFarmParts/CFStep";
import CFDialog from "components/CFDialog";
import colors from "assets/theme/base/colors";
// Our Components
import CFBox from "components/CFBox";
import SearchIcon from "@mui/icons-material/Search";

import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";
import { updateLoading } from "store/reducers/mask";
import { post } from "utils/api";
import { popupError } from "utils/generic";
import {
  FETCH_FEED_LOOKUPS,
  POPULATE_FEEDS,
  POPULATE_FEEDLOT_FEEDS,
  POPULATE_GOAT_FEED,
  FETCH_FEEDS,
  SORT_FEEDS,
} from "constants/api";
import CFLoading from "components/CFLoading";
import FeedCalcH from "assets/cFarmImages/HelpImages/feed calculator.webp";
import FeedlotCalcH from "assets/cFarmImages/HelpImages/feedlot feed calculator.webp";

// Constants

export default function FeedCalculator({
  isOpen,
  setIsOpen,
  Id,
  YearVal,
  rationName,
  AnimalCat,
  AnimalCatId,
  AnimalGroupId,
  animalObj,
  allMonths,
  populateFeedsClicked,
}) {
  const dispatch = useDispatch();

  const { info } = colors;

  // const monthsCheckBoxSel = {
  //   Jan: false,
  //   Feb: false,
  //   Mar: false,
  //   Apr: false,
  //   May: false,
  //   Jun: false,
  //   Jul: false,
  //   Aug: false,
  //   Sep: false,
  //   Oct: false,
  //   Nov: false,
  //   Dec: false,
  // };
  const getMonths = (months) => {
    const monthsSelArr = [];
    if (AnimalCatId !== 21247 && AnimalCatId !== 21281) {
      months.map((month) => monthsSelArr.push({ [month]: false }));
    }
    return monthsSelArr;
  };
  const [monthSelected, setMonthSelected] = useState(getMonths(allMonths));
  const [selAllSelected, setSelAllSelected] = useState(false);

  const [loading, setLoading] = useState(false);
  const step1 = AnimalCatId === 21247 || AnimalCatId === 21281 ? "" : "1";
  const step2 = AnimalCatId === 21247 || AnimalCatId === 21281 ? "1" : "2";
  const step3 = AnimalCatId === 21247 || AnimalCatId === 21281 ? "2" : "3";
  const step1Text =
    AnimalCatId !== 21247 && AnimalCatId !== 21281
      ? ". Select the months on the left to add the animal diet for the specified months ."
      : "";
  const handleMonthSelChange = (e) => {
    setMonthSelected({
      ...monthSelected,
      [e.target.name]: e.target.checked,
    });
  };
  const handleSelAllChange = (e) => {
    setSelAllSelected(e.target.checked);
    const allMonthsSelArr = {};

    allMonths.forEach((mon) => {
      allMonthsSelArr[mon] = e.target.checked;
    });

    setMonthSelected({ ...monthSelected, ...allMonthsSelArr });
  };

  const [localPopupState, updateLocalPopup] = useState({});
  const [rows, setRows] = useState([]);
  const [selRows, setSelRows] = useState([]);

  const columns = [
    {
      field: "id",
      headerName: "Id",
      width: 90,
      hide: true,
      filterable: false,
    },
    { field: "feed", headerName: "Feed", width: 400 },
    {
      field: "feedLbs",
      headerName: "Feed Intake - Fresh Weight (lbs/head/day)",
      width: 400,

      editable: true,
    },
  ];
  const processRowUpdate = (newRow) => {
    const newRows = [...rows];
    const currRow = newRows.find((t) => t.id === newRow.id);
    currRow.feedLbs = newRow.feedLbs;
    setRows(newRows);

    // setSelRows([...selRows, currRow]);

    // return updatedRow;
  };
  const handleProcessRowUpdateError = (error) => {
    console.log(error);
    updateLocalPopup({
      message: error,
      title: "Error",
      showPopup: true,
      closeAction: () => {
        updateLocalPopup({
          showPopup: false,
        });
      },
    });
  };

  const sortFeedStuffs = (sortId) => {
    setLoading(true);
    // setSelAllSelected(false);
    // const monthsNewJson = {};
    // monthsArr.forEach((mon) => {
    //   monthsNewJson[mon] = false;
    // });
    // setMonthSelected({ ...monthSelected, ...monthsNewJson });
    const sortParam = {
      sortVal: sortId,
    };
    post(SORT_FEEDS, sortParam)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const feeds = res.data;
          const feedRows = [];
          feeds.map((data) =>
            data.childList.map((childData) =>
              feedRows.push({
                id: `${childData.childId}_${data.parentId}`,
                feed: `${data.parentName} - ${childData.childName}`,
                feedLbs: 0,
              })
            )
          );
          setRows(feedRows);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const monthsExists = () => {
    let isValid = false;
    if (
      AnimalCatId === 21260 ||
      AnimalCatId === 21261 ||
      AnimalCatId === 21262
    ) {
      isValid = Object.values(monthSelected).includes(true);
    } else if (AnimalCatId === 21247 || AnimalCatId === 21281) {
      isValid = true;
    }
    return isValid;
  };
  const fetchLookUps = () => {
    setLoading(true);
    if (AnimalCatId !== 21247 && AnimalCatId !== 21281) {
      setSelAllSelected(false);
      const monthsNewJson = {};
      allMonths.forEach((mon) => {
        monthsNewJson[mon] = false;
      });
      setMonthSelected({ ...monthSelected, ...monthsNewJson });
    }
    setSelRows([]);

    post(FETCH_FEED_LOOKUPS)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const feeds = res.data;
          const feedRows = [];
          feeds.map((data) =>
            data.childList.map((childData) =>
              feedRows.push({
                id: `${childData.childId}_${data.parentId}`,
                feed: `${data.parentName} - ${childData.childName}`,
                feedLbs: 0,
              })
            )
          );

          setRows(feedRows);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const populateFeeds = () => {
    setLoading(true);

    const months = [];
    if (AnimalCatId !== 21247 && AnimalCatId !== 21281) {
      allMonths.map((month) =>
        months.push({
          monthName: month,
          isSelected: monthSelected[month],
        })
      );
    }
    const selectedRows = rows.filter((t) => t.feedLbs > 0);
    const feedsObj = {
      monthsObj: months,
      updatedFeedsObj: selectedRows,
      ration: rationName,
      popid: Id,
    };
    let postMethodName = "";
    if (
      AnimalCatId === 21260 ||
      AnimalCatId === 21261 ||
      AnimalCatId === 21262
    ) {
      postMethodName = POPULATE_FEEDS;
    } else if (AnimalCatId === 21247) {
      postMethodName = POPULATE_FEEDLOT_FEEDS;
    }
    // else if (AnimalCatId === 21268 || AnimalCatId === 21281) {
    //   postMethodName = POPULATE_SHEEP_GOAT_FEEDS;
    // }
    else if (AnimalCatId === 21281) {
      postMethodName = POPULATE_GOAT_FEED;
    }

    post(postMethodName, feedsObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          setIsOpen(false);
          populateFeedsClicked();
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      fetchLookUps();
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="xl"
      maxHeight="xl"
      PaperProps={{
        style: { padding: "1em" },
      }}
      sx={{ zIndex: 5 }}
    >
      {loading && <CFLoading local />}

      <CFBox style={{ height: "55em", verticalAlign: "center" }}>
        <Grid container direction="row" xs={12}>
          <Grid
            item
            container
            xs={12}
            id="monthBox"
            display="flex"
            alignItems="start"
            justifyContent="start"
          >
            <Grid item xs={12}>
              {/* <CFTypography
                sx={{
                  fontSize: "20px",
                  color: info.dullFocus,
                  fontWeight: "bold",
                  marginTop: "1em",
                  marginBottom: "1em",
                  marginLeft: "1em",
                }}
              >
                Feed Calculator
              </CFTypography> */}
              {/* Window Header */}
              <CFStep
                stepLabel={<div>Feed Calculator</div>}
                helpText={
                  <CFBox
                    sx={{
                      overflow: "auto",
                      height: "600px",
                      maxHeight: "60vh",
                    }}
                  >
                    {(AnimalCatId === 21260 ||
                      AnimalCatId === 21261 ||
                      AnimalCatId === 21262) && (
                      <img
                        src={FeedCalcH}
                        width="100%"
                        alt="Feed Calculator Help"
                      />
                    )}
                    {AnimalCatId === 21247 && (
                      <img
                        src={FeedlotCalcH}
                        width="100%"
                        alt="Feedlot Feed Calculator Help"
                      />
                    )}
                  </CFBox>
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" xs={12}>
          <Grid
            item
            container
            xs={12}
            id="monthBox"
            display="flex"
            alignItems="start"
            justifyContent="start"
          >
            <Grid item xs={12}>
              <CFTypography
                sx={{
                  fontSize: "14px",
                  color: info.dullFocus,
                  fontWeight: "bold",

                  marginBottom: "1em",
                  marginLeft: "1em",
                }}
              >
                {step1}
                {step1Text} <br />
                {step2}. Use the sorting pills to easily locate the types of
                feed fed on your farm. Enter the amount of feed per head in
                pounds per day on an as-fed basis for all feeds in the ration
                for the specified months. The calculator will determine the dry
                matter intake and other necessary diet characteristics.
                <br />
                {step3}.When all feedtypes in the ration for the specified
                months are entered, select Populate Feeds to calculate the feed
                characteristics of the diet in the main feed table. Continue for
                all rations until all months have been populated. Feed
                characteristics can be changed at any time from the main feed
                characteristic table at any time.
                <br />
              </CFTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" xs={12}>
          <Grid
            item
            container
            xs={1}
            id="monthBox"
            display="flex"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Grid item xs={1} sm={1} md={1}>
              <CFTypography
                sx={{
                  fontSize: "14px",
                  color: info.dullFocus,
                  fontWeight: "bold",
                  marginBottom: "1em",
                }}
              >
                Sort:
              </CFTypography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={11}
            id="monthBox"
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
          >
            <Grid item xs={1} sm={1} md={1}>
              <CFButton
                color="primary"
                variant="gradient"
                size="small"
                sx={{
                  fontSize: "11px",
                  fontWeight: "bold",
                  marginBottom: "1em",
                }}
                onClick={() => sortFeedStuffs(212450)}
              >
                All Feeds
              </CFButton>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <CFButton
                color="primary"
                variant="gradient"
                size="small"
                sx={{
                  fontSize: "11px",
                  fontWeight: "bold",
                  marginBottom: "1em",
                }}
                onClick={() => sortFeedStuffs(213100)}
              >
                Concentrate - Energy Rich
              </CFButton>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <CFButton
                color="primary"
                variant="gradient"
                size="small"
                sx={{
                  fontSize: "11px",
                  marginBottom: "1em",
                  fontWeight: "bold",
                }}
                onClick={() => sortFeedStuffs(213101)}
              >
                Concentrate - Protein Rich
              </CFButton>
            </Grid>
            <Grid item xs={1.2} sm={1.2} md={1.2}>
              <CFButton
                color="primary"
                variant="gradient"
                size="small"
                sx={{
                  fontSize: "11px",
                  marginBottom: "1em",
                  fontWeight: "bold",
                }}
                onClick={() => sortFeedStuffs(213102)}
              >
                Forage - Dry
              </CFButton>
            </Grid>
            <Grid item xs={1.3} sm={1.3} md={1.3}>
              <CFButton
                color="primary"
                variant="gradient"
                size="small"
                sx={{
                  fontSize: "11px",
                  marginBottom: "1em",
                  fontWeight: "bold",
                }}
                onClick={() => sortFeedStuffs(213103)}
              >
                Forage - Fresh
              </CFButton>
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
              <CFButton
                color="primary"
                variant="gradient"
                size="small"
                sx={{
                  fontSize: "11px",
                  marginBottom: "1em",
                  fontWeight: "bold",
                }}
                onClick={() => sortFeedStuffs(213104)}
              >
                Silages and Haylages
              </CFButton>
            </Grid>
          </Grid>
        </Grid>
        {AnimalCatId !== 21247 && AnimalCatId !== 21281 && (
          <Grid container direction="row" xs={12}>
            <Grid
              item
              container
              xs={12}
              id="monthBox"
              display="flex"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Grid item xs={12}>
                <FormControl component="fieldset" sx={{ marginLeft: "1em" }}>
                  <CFCheckbox
                    checked={selAllSelected}
                    onChange={handleSelAllChange}
                    color="secondary"
                    label="Select All"
                    name="Select All"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}
        {/* Grid with four rows: title, step one and two, step three, and action buttons */}
        <Grid container direction="row" xs={12}>
          {AnimalCatId !== 21247 && AnimalCatId !== 21281 && (
            <Grid
              item
              container
              xs={1}
              id="monthBox"
              display="flex"
              alignItems="start"
              justifyContent="start"
            >
              <Grid item xs={1}>
                <FormControl component="fieldset" sx={{ marginLeft: "1em" }}>
                  <FormGroup>
                    {allMonths.map((month) => (
                      <CFCheckbox
                        checked={monthSelected[month]}
                        onChange={handleMonthSelChange}
                        color="secondary"
                        label={month}
                        name={month}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          )}

          <Grid
            item
            container
            xs={11}
            id="feedBox"
            display="flex"
            alignItems="start"
            justifyContent="start"
            px={2}
          >
            <Grid container direction="row" xs={12} sm={12} md={12}>
              <Grid item xs={12}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  processRowUpdate={processRowUpdate}
                  // onProcessRowUpdateError={handleProcessRowUpdateError}
                  sx={(theme) => ({
                    [`.${gridClasses.main}`]: {
                      overflow: "unset",
                    },
                    [`.${gridClasses.columnHeaders}`]: {
                      position: "sticky",
                      top: 0,
                      backgroundColor: theme.palette.background.paper,
                      zIndex: 1,
                    },
                    ".MuiDataGrid-iconButtonContainer": {
                      visibility: "visible",
                    },
                    ".MuiDataGrid-sortIcon": {
                      opacity: "inherit !important",
                    },
                  })}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                      },
                    },
                  }}
                  slots={{
                    toolbar: GridToolbar,
                    openFilterButtonIcon: SearchIcon,
                  }}
                  slotProps={{
                    toolbar: {
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                    },
                  }}
                  localeText={{
                    toolbarFilters: "Search",
                  }}
                  pageSizeOptions={[10]}
                  // checkboxSelection
                  disableColumnMenu
                  disableRowSelectionOnClick
                  disableColumnSelector
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container py={2} px={2} justifyContent="flex-end">
            {/* Return to projects */}
            <Grid item xs={9} sm={9} md={9}>
              <CFButton color="secondary" onClick={() => handleClose()}>
                Cancel
              </CFButton>
            </Grid>
            {/* Management Details */}
            {/* making months selection mandatory for Dairy */}
            <Grid item xs={2} sm={2} md={2}>
              <CFButton
                color="progress"
                onClick={() => populateFeeds()}
                disabled={!monthsExists()}
              >
                Populate Feeds
              </CFButton>
            </Grid>
            {!monthsExists() && (
              <Grid item xs={12} sm={12} md={12}>
                <CFBox id="monthsSelectError">
                  <CFTypography
                    sx={{
                      fontSize: "15px",
                      color: "red",
                      fontWeight: "bold",
                      marginLeft: "1.7em",
                      float: "right",
                      marginBottom: "0.5em",
                      marginTop: "0.5em",
                    }}
                  >
                    Select months on the left to proceed
                  </CFTypography>
                </CFBox>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CFBox>

      {localPopupState && localPopupState.showPopup && (
        <CFDialog
          {...localPopupState}
          updatePopup={(e) => updateLocalPopup(e)}
        />
      )}
    </Dialog>
  );
}
