import CFBox from "components/CFBox";
import CFarmPage from "components/CFarmParts/CFarmPage";
import backgroundBarn from "assets/cFarmImages/MainPages/corner_barn.png";
import CFTypography from "components/CFTypography";

const LegacyInfoPage = (
  <div id="newsIntro">
    <h5 id="thank-you-for-your-interest-in-the-comet-farm-tool-">
      Thank you for your interest in the COMET-Farm tool!
    </h5>
    <h5 id="please-contact-us-via-the-help-desk-https-cometfarm-freshdesk-com-support-home-or-send-an-email-to-appnrel-colostate-edu-with-any-questions-or-comments-or-for-any-training-requests-">
      Please contact us via the{" "}
      <a href="https://cometfarm.freshdesk.com/support/home">help desk</a> or
      send an email to appnrel@colostate.edu with any questions or comments or
      for any training requests.
    </h5>
    <div style={{ marginLeft: "2em" }}>
      <h3 id="new-features-and-enhancements-to-comet-farm-version-5-0-">
        New features and enhancements to COMET-Farm Version 5.0
      </h3>
      <div>
        <p>23 September 2024</p>
        <ul style={{ marginLeft: "2em" }}>
          <li>
            Integration of new methods from USDA&#39;s{" "}
            <a href="https://www.usda.gov/oce/entity-scale-ghg-methods">
              Quantifying Greenhouse Gas Fluxes in in Agriculture and Forestry:
              Methods for Entity-Scale Inventory
            </a>
          </li>
          <li>
            New minimum 5 year baseline requirement for
            cropland/pasture/rangeland/orchard/vineyard
          </li>
          <li>
            New tillage calculator allowing users to enter detailed tillage
            implement data
          </li>
          <li>Addition of biochar amendments</li>
          <li>
            New &quot;Review Inputs&quot; button enables users to view all
            detailed management entry in one window
          </li>
          <li>Optional scenario entry</li>
          <li>Inclusion of feed additives for cattle in animal agriculture</li>
          <li>
            Enhanced tracking of mitigation strategies in animal agriculture,
            especially in regards to housing and manure emissions
          </li>
          <li>
            New customizable reports providing users the ability to view one
            scenario at a time or emissions per acre
          </li>
          <li>
            Updated methods for estimating biomass carbon from DBH in
            agroforestry
          </li>
        </ul>
      </div>
      <h3 id="change-log-comet-farm-version-5-0">
        Change Log - COMET-Farm Version 5.0
      </h3>
      <div>
        <p>01 November 2024</p>
        <ul style={{ marginLeft: "2em" }}>
          <li>PostGIS 3.2.5 upgrade; PostgreSQL 15 upgrade</li>
          <li>Updated COMET-Energy coefficients with 2024 data</li>
          <li>Resolved cropland copy bug</li>
          <li>Resolved incorrect MCF value for anaerobic lagoons</li>
          <li>Removed hidden old animal ag projects</li>
          <li>Resolved bug to create multiple of the same demo project</li>
          <li>Resolved UX bugs with the feed calculator</li>
          <li>Resolved redirection bug when signing into API access page</li>
          <li>Published COMET GUI 5.0 API documentation</li>
          <li>Added tobacco to crops list</li>
          <li>Updated cropland baseline minimum help tip</li>
        </ul>
      </div>
      <h3 id="enhancements-to-comet-farm-version-gui-experimental">
        Enhancements to COMET-Farm Version GUI-Experimental
      </h3>
      <p id="30-september-2022">30 September 2022</p>
      <div>
        <ul style={{ marginLeft: "2em" }}>
          <li>
            An updated version of the DayCent model moving to the 30cm depth
            version from the 20cm depth version. An overview of differences
            between versions is summarized here:{" "}
            <a href="https://storage.googleapis.com/public-sourcecode-access/COMET_Versions_Comparison_Sept2022.pdf">
              COMET Versions Comparison – September 2022
            </a>
            .
          </li>
          <li>New direct N2O emissions estimates using the DayCent model.</li>
          <li>
            New conservation practices available on the conservation practice
            advisor (CPA) for use in future scenarios.
          </li>
          <li>
            Improved readability and accessibility for PDF help documents.
          </li>
          <li>New available water holding capacity report.</li>
          <li>Numerous bug and defect fixes.</li>
        </ul>
      </div>
    </div>
  </div>
);

function Version() {
  return (
    <CFarmPage>
      <CFBox
        sx={{
          fontSize: "1em",
          margin: "1em",
          paddingTop: "5%",
          backgroundImage: `url(${backgroundBarn})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right center",
        }}
      >
        <CFTypography variant="h1" pb=".5em">
          Versions{" "}
        </CFTypography>
        {LegacyInfoPage}
      </CFBox>
    </CFarmPage>
  );
}
export default Version;
