// React
import { PropTypes } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
// MUI
import { Grid } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

// Redux

import React, { useState } from "react";
import VerifiedTwoToneIcon from "@mui/icons-material/VerifiedTwoTone";

import colors from "assets/theme/base/colors";
// Our Components
import CFBox from "components/CFBox";
import DairyPopulation from "./DairyPopulation";
import AnimalHousing from "./AnimalHousing";
import AnimalFeeds from "./AnimalFeeds";
import AnimalManure from "./AnimalManure";
import FeedLot from "./FeedLot";
import GrazingCattlePopulation from "./GrazingCattlePopulation";
import GrazingCattleFeeds from "./GrazingCattleFeeds";
import SheepDMIPopulation from "./SheepDMIPopulation";

import Swine from "./Swine";
import Poultry from "./Poultry";
import PoultryHousing from "./PoultryHousing";
import SheepDMIFeed from "./SheepDMIFeed";

import GoatPopulation from "./GoatPopulation";
import GoatFeed from "./GoatFeed";

import GoatGestLact from "./GoatGestLact";

export default function AnimalPanelsCard({
  popId,
  popYear,
  copyFlag,
  popCategory,
  popCategoryId,
  popHerd,
  popGroupId,
  anagObj,
  anagObjChanged,
  panelName,
  expanded,
  expandToggle,
  barnIds,
  hComplete,
  updateLocalPopup,
  hasGest,
  hasLact,
  dairyFlag,
  setDairyFlag,
  grazingFlag,
  setGrazingFlag,
  feedlotFlag,
  setFeedlotFlag,
  sheepFlag,
  setSheepFlag,
  housingEdited,

  manureVerify,
}) {
  const [doneColor, setDoneColor] = useState("grey");
  const handleVerifyColorChanged = (color) => {
    setDoneColor(color);
  };

  return (
    <CFBox width="100%" marginBottom="1em">
      <Accordion
        expanded={expanded === panelName}
        onChange={expandToggle(panelName)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: colors.text.light }} />}
          aria-controls="animalDetailsCard-content"
          id="animalDetailsCard-header"
          sx={{
            backgroundColor: colors.primary.main,
            borderRadius: ".3em",
            borderStyle: "outset",
            flexDirection: "row-reverse",
          }}
        >
          <Grid item container alignItems="center" justifyContent="flex-start">
            {/* Year */}

            <CFBox
              xs={12}
              sx={{
                textAlign: "left",
                marginLeft: "1em",
                marginRight: "1em",
                fontSize: "medium",
                fontWeight: "550",
                color: colors.text.light,
              }}
            >
              {panelName}
              <VerifiedTwoToneIcon
                color="primary"
                fontSize="medium"
                style={{
                  cursor: "pointer",
                  marginLeft: "2em",
                  color:
                    panelName === "Manure" && manureVerify !== "incomplete"
                      ? manureVerify
                      : doneColor,
                }}
              />
            </CFBox>
          </Grid>
        </AccordionSummary>
        {/* TODO: Set up accessibility features for drop-down actions */}
        <AccordionDetails>
          {panelName === "Animal Details"
            ? (popCategoryId === 21260 ||
                popCategoryId === 21261 ||
                popCategoryId === 21262) && (
                <DairyPopulation
                  id={popId}
                  yearValue={popYear}
                  isCopy={copyFlag}
                  animalCategory={popCategory}
                  animalCategoryId={popCategoryId}
                  groupId={popGroupId}
                  anagObject={anagObj}
                  anagObjectChanged={anagObjChanged}
                  verifyDone={handleVerifyColorChanged}
                />
              )
            : null}
          {panelName === "Animal Details"
            ? popCategoryId === 21247 && (
                <FeedLot
                  id={popId}
                  yearValue={popYear}
                  isCopy={copyFlag}
                  animalCategory={popCategory}
                  animalCategoryId={popCategoryId}
                  anagObject={anagObj}
                  anagObjectChanged={anagObjChanged}
                  verifyDone={handleVerifyColorChanged}
                />
              )
            : null}
          {panelName === "Animal Details"
            ? (popCategoryId === 21250 ||
                popCategoryId === 21253 ||
                popCategoryId === 21255) && (
                <GrazingCattlePopulation
                  id={popId}
                  yearValue={popYear}
                  isCopy={copyFlag}
                  animalCategory={popCategory}
                  animalCategoryId={popCategoryId}
                  groupId={popGroupId}
                  anagObject={anagObj}
                  anagObjectChanged={anagObjChanged}
                  verifyDone={handleVerifyColorChanged}
                />
              )
            : null}
          {panelName === "Animal Details"
            ? popCategoryId === 21267 && (
                <SheepDMIPopulation
                  id={popId}
                  yearValue={popYear}
                  isCopy={copyFlag}
                  animalCategory={popCategory}
                  animalCategoryId={popCategoryId}
                  groupId={popGroupId}
                  anagObject={anagObj}
                  anagObjectChanged={anagObjChanged}
                  verifyDone={handleVerifyColorChanged}
                />
              )
            : null}

          {panelName === "Gestation & Lactation Details"
            ? popCategoryId === 21281 && (
                <GoatGestLact
                  id={popId}
                  yearValue={popYear}
                  animalCategory={popCategory}
                  animalCategoryId={popCategoryId}
                  groupId={popGroupId}
                  anagObject={anagObj}
                  anagObjectChanged={anagObjChanged}
                  verifyDone={handleVerifyColorChanged}
                  gestExists={hasGest}
                  lactExists={hasLact}
                />
              )
            : null}

          {/* {panelName === "Animal Details"
            ? popCategoryId === 21268 && (
                <SheepGoatPopulation
                  id={popId}
                  yearValue={popYear}
                  animalCategory={popCategory}
                  animalCategoryId={popCategoryId}
                  groupId={popGroupId}
                  anagObject={anagObj}
                  anagObjectChanged={anagObjChanged}
                />
              )
            : null} */}

          {panelName === "Animal Details"
            ? popCategoryId === 21281 && (
                <GoatPopulation
                  id={popId}
                  yearValue={popYear}
                  isCopy={copyFlag}
                  animalCategory={popCategory}
                  animalCategoryId={popCategoryId}
                  groupId={popGroupId}
                  anagObject={anagObj}
                  anagObjectChanged={anagObjChanged}
                  verifyDone={handleVerifyColorChanged}
                />
              )
            : null}

          {panelName === "Animal Details"
            ? (popCategoryId === 21280 ||
                popCategoryId === 21290 ||
                popCategoryId === 21291 ||
                popCategoryId === 21295 ||
                popCategoryId === 21296 ||
                popCategoryId === 21297 ||
                popCategoryId === 21298) && (
                <GrazingCattlePopulation
                  id={popId}
                  yearValue={popYear}
                  isCopy={copyFlag}
                  animalCategory={popCategory}
                  animalCategoryId={popCategoryId}
                  groupId={popGroupId}
                  anagObject={anagObj}
                  anagObjectChanged={anagObjChanged}
                  verifyDone={handleVerifyColorChanged}
                />
              )
            : null}

          {panelName === "Animal Details"
            ? (popCategoryId === 21283 || popCategoryId === 21284) && (
                <Swine
                  id={popId}
                  yearValue={popYear}
                  isCopy={copyFlag}
                  anagObject={anagObj}
                  animalCategory={popCategory}
                  animalCategoryId={popCategoryId}
                  verifyDone={handleVerifyColorChanged}
                />
              )
            : null}

          {panelName === "Animal Details"
            ? (popCategoryId === 21271 ||
                popCategoryId === 21273 ||
                popCategoryId === 21274 ||
                popCategoryId === 21275) && (
                <Poultry
                  id={popId}
                  yearValue={popYear}
                  isCopy={copyFlag}
                  animalCategory={popCategory}
                  animalCategoryId={popCategoryId}
                  anagObject={anagObj}
                  anagObjectChanged={anagObjChanged}
                  verifyDone={handleVerifyColorChanged}
                />
              )
            : null}

          {panelName === "Housing"
            ? (popCategoryId === 21271 ||
                popCategoryId === 21273 ||
                popCategoryId === 21274 ||
                popCategoryId === 21275) && (
                <PoultryHousing
                  id={popId}
                  yearValue={popYear}
                  animalCategory={popCategory}
                  animalCategoryId={popCategoryId}
                  anagObject={anagObj}
                  anagObjectChanged={anagObjChanged}
                  verifyDone={handleVerifyColorChanged}
                />
              )
            : null}
          {panelName === "Housing"
            ? !(
                popCategoryId === 21250 ||
                popCategoryId === 21253 ||
                popCategoryId === 21255 ||
                popCategoryId === 21271 ||
                popCategoryId === 21273 ||
                popCategoryId === 21274 ||
                popCategoryId === 21275 ||
                popCategoryId === 21280 ||
                popCategoryId === 21290 ||
                popCategoryId === 21291 ||
                popCategoryId === 21295 ||
                popCategoryId === 21296 ||
                popCategoryId === 21297 ||
                popCategoryId === 21298
              ) && (
                <AnimalHousing
                  id={popId}
                  yearValue={popYear}
                  groupName={popHerd}
                  animalCategory={popCategory}
                  animalCategoryId={popCategoryId}
                  groupId={popGroupId}
                  anagObject={anagObj}
                  popFlag={dairyFlag}
                  setPopFlag={setDairyFlag}
                  fdlotPopFlag={feedlotFlag}
                  setFdlotPopFlag={setFeedlotFlag}
                  sheepPopFlag={sheepFlag}
                  setSheepPopFlag={setSheepFlag}
                  housingChanged={housingEdited}
                  verifyDone={handleVerifyColorChanged}
                />
              )
            : null}

          {panelName === "Feed Characteristics"
            ? (popCategoryId === 21260 ||
                popCategoryId === 21261 ||
                popCategoryId === 21262) && (
                <AnimalFeeds
                  id={popId}
                  yearValue={popYear}
                  animalCategory={popCategory}
                  animalCategoryId={popCategoryId}
                  groupId={popGroupId}
                  anagObject={anagObj}
                  anagObjectChanged={anagObjChanged}
                  popFlag={dairyFlag}
                  setPopFlag={setDairyFlag}
                  verifyDone={handleVerifyColorChanged}
                />
              )
            : null}
          {panelName === "Feed Characteristics"
            ? (popCategoryId === 21250 ||
                popCategoryId === 21253 ||
                popCategoryId === 21255) && (
                <GrazingCattleFeeds
                  id={popId}
                  yearValue={popYear}
                  animalCategory={popCategory}
                  animalCategoryId={popCategoryId}
                  groupId={popGroupId}
                  anagObject={anagObj}
                  anagObjectChanged={anagObjChanged}
                  grPopFlag={grazingFlag}
                  setGrPopFlag={setGrazingFlag}
                  verifyDone={handleVerifyColorChanged}
                />
              )
            : null}
          {panelName === "Feed Characteristics"
            ? popCategoryId === 21267 && (
                <SheepDMIFeed
                  id={popId}
                  yearValue={popYear}
                  animalCategory={popCategory}
                  animalCategoryId={popCategoryId}
                  groupId={popGroupId}
                  anagObject={anagObj}
                  anagObjectChanged={anagObjChanged}
                  verifyDone={handleVerifyColorChanged}
                />
              )
            : null}

          {panelName === "Feed Characteristics"
            ? popCategoryId === 21281 && (
                <GoatFeed
                  id={popId}
                  yearValue={popYear}
                  animalCategory={popCategory}
                  animalCategoryId={popCategoryId}
                  groupId={popGroupId}
                  anagObject={anagObj}
                  anagObjectChanged={anagObjChanged}
                  verifyDone={handleVerifyColorChanged}
                />
              )
            : null}

          {/* {panelName === "Feed Characteristics"
            ? (popCategoryId === 21268 || popCategoryId === 21281) && (
                <SheepGoatFeeds
                  id={popId}
                  yearValue={popYear}
                  animalCategory={popCategory}
                  animalCategoryId={popCategoryId}
                  groupId={popGroupId}
                  anagObject={anagObj}
                  anagObjectChanged={anagObjChanged}
                />
              )
            : null} */}
          {/* {panelName === "Weight"
            ? (popCategoryId === 21268 || popCategoryId === 21281) && (
                <SheepGoatWeight
                  id={popId}
                  yearValue={popYear}
                  animalCategory={popCategory}
                  animalCategoryId={popCategoryId}
                  groupId={popGroupId}
                  anagObject={anagObj}
                  anagObjectChanged={anagObjChanged}
                />
              )
            : null} */}
          {/* {panelName === "Gestation & Lactation Details"
            ? (popCategoryId === 21268 || popCategoryId === 21281) && (
                <SheepGoatGestLact
                  id={popId}
                  yearValue={popYear}
                  animalCategory={popCategory}
                  animalCategoryId={popCategoryId}
                  groupId={popGroupId}
                  anagObject={anagObj}
                  anagObjectChanged={anagObjChanged}
                />
              )
            : null} */}
          {/* {panelName === "Wool Production Details"
            ? popCategoryId === 21268 && (
                <SheepUnknowDMIWool
                  id={popId}
                  yearValue={popYear}
                  animalCategory={popCategory}
                  animalCategoryId={popCategoryId}
                  groupId={popGroupId}
                  anagObject={anagObj}
                  anagObjectChanged={anagObjChanged}
                />
              )
            : null} */}
          {panelName === "Manure"
            ? !(
                popCategoryId === 21250 ||
                popCategoryId === 21253 ||
                popCategoryId === 21255 ||
                popCategoryId === 21280 ||
                popCategoryId === 21290 ||
                popCategoryId === 21291 ||
                popCategoryId === 21295 ||
                popCategoryId === 21296 ||
                popCategoryId === 21297 ||
                popCategoryId === 21298
              ) && (
                <AnimalManure
                  id={popId}
                  yearValue={popYear}
                  animalCategory={popCategory}
                  animalCategoryId={popCategoryId}
                  groupId={popGroupId}
                  barns={barnIds}
                  housingComplete={hComplete}
                  anagObject={anagObj}
                  anagObjectChanged={anagObjChanged}
                  verifyDone={handleVerifyColorChanged}
                  housingChanged={housingEdited}
                />
              )
            : null}
        </AccordionDetails>
      </Accordion>
    </CFBox>
  );
}
AnimalPanelsCard.defaultProps = {
  popYear: PropTypes.number,
  popCategory: PropTypes.string.isRequired,
  panelName: PropTypes.string.isRequired,

  popCategoryId: PropTypes.number.isRequired,
  popId: PropTypes.number,
  anagObj: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  anagObjChanged: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  expandToggle: PropTypes.func.isRequired,
  updateLocalPopup: PropTypes.func.isRequired,
};
