// MUI
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts";
import { Grid, Typography } from "@mui/material";
import colors from "assets/theme/base/colors";

// React
import { useState, useEffect } from "react";
import CFGraphBox from "components/CFGraphBox";

function AnimalAgGraph({ group, displayedScenarios }) {
  // Chart display settings
  const chartSetting = {
    tooltip: { trigger: "item" },
    xAxis: [
      {
        scaleType: "band",
        dataKey: "scenario",
        label: "Scenario",
        labelStyle: { fontSize: 16, fontWeight: "bold" },
        tickLabelStyle: {
          fontSize: 16,
        },
      },
    ],
    yAxis: [
      {
        label: "Emissions (metric tonnes CO\u2082 - eq per year)",
      },
    ],
    legend: {
      direction: "row",
      position: {
        vertical: "bottom",
        horizontal: "middle",
      },
      // padding: 5,
    },
    margin: { top: 50, right: 75, left: 75, bottom: 150 },
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-33px, 0px)",
        fontWeight: "bold",
      },
      [`.${axisClasses.bottom} .${axisClasses.label}`]: {
        transform: "translate(0px, 40px)",
      },
      // "--ChartsLegend-rootOffsetX": "2em",
      // "--ChartsLegend-rootSpacing": "1em",
    },
  };

  // Format chart values and stack labels
  const valueFormatter = (value) => {
    const roundedVal = Math.round((value + Number.EPSILON) * 10) / 10;
    return `${roundedVal.toLocaleString("en-US")} (tonnes CO\u2082 equiv.)`;
  };
  function labelFormatter(value, total) {
    const roundedTotal = Math.round((total + Number.EPSILON) * 10) / 10;
    return `${value} (Total: ${roundedTotal.toLocaleString("en-US")})`;
  }

  // Generate dataset for chart (dataset and series)
  function GenerateDataset() {
    const tempData = [];
    const tempSeries = [];

    // Data set for baseline scenario
    const baseline = {
      totalMethane: 0,
      totalNitrousOxide: 0,
      scenario: "",
    };

    group.baselineScenario.emissionsCategories.forEach((subGroup) => {
      subGroup.emissionsSubcategories.forEach((subcategory) => {
        switch (subcategory.name) {
          case "Methane":
            baseline.totalMethane += subcategory.value;
            break;
          case "Nitrous oxide/Manure": // has total nitrogen for some reason
            baseline.totalNitrousOxide += subcategory.value;
            break;
          default:
            break;
        }
      });
    });

    baseline.scenario = labelFormatter(
      "Baseline",
      baseline.totalMethane + baseline.totalNitrousOxide
    );
    tempData.push(baseline);

    // Map through scenario list, data object for each scenario
    group.scenarios.forEach((scenario) => {
      if (displayedScenarios.includes(scenario.id)) {
        const scenarioObj = {
          totalMethane: 0,
          totalNitrousOxide: 0,
          scenario: "",
        };

        scenario.emissionsCategories.forEach((subGroup) => {
          subGroup.emissionsSubcategories.forEach((subcategory) => {
            switch (subcategory.name) {
              case "Methane":
                scenarioObj.totalMethane += subcategory.value;
                break;
              case "Nitrous oxide/Manure": // has total nitrogen for some reason
                scenarioObj.totalNitrousOxide += subcategory.value;
                break;
              default:
                break;
            }
            scenarioObj.scenario = labelFormatter(
              scenario.name,
              scenarioObj.totalMethane + scenarioObj.totalNitrousOxide
            );
          });
        });

        tempData.push(scenarioObj);
      }
    });

    // One series for each emission category
    const totalMethane = {
      dataKey: "totalMethane",
      stack: "Methane",
      label: "Methane",
      color: colors.graphColors.animalAg.methane,
    };
    const totalNitrousOxide = {
      dataKey: "totalNitrousOxide",
      stack: "Nitrous Oxide",
      label: "Nitrous Oxide",
      color: colors.graphColors.animalAg.nitrousOxide,
    };
    tempSeries.push(totalMethane);
    tempSeries.push(totalNitrousOxide);

    return [tempData, tempSeries];
  }
  const [dataset, setDataset] = useState(() => {
    if (group) {
      const [chartData, chartSeries] = GenerateDataset();
      return chartData;
    }

    return [];
  });
  const [dataSeries, setDataSeries] = useState(() => {
    if (group) {
      const [chartData, chartSeries] = GenerateDataset();
      return chartSeries;
    }

    return [];
  });
  useEffect(() => {
    if (group) {
      const [chartData, chartSeries] = GenerateDataset();

      setDataset(chartData);
      setDataSeries(chartSeries);
    }
  }, [group, displayedScenarios]);

  return (
    <Grid
      container
      direction="column"
      pt={6}
      pb={2}
      id="graphDownload_animalAg"
    >
      {/* Chart Title */}
      <Grid item container direction="column" alignItems="center" xs={1}>
        <Typography variant="h5">
          Total GHG Emissions (metric tonnes CO&#8322; -eq per year):{" "}
          {group.name}
        </Typography>
      </Grid>
      {/* Chart */}
      <Grid item container direction="column" alignItems="center" xs={10}>
        <CFGraphBox>
          <BarChart
            dataset={dataset}
            series={dataSeries.map((series) => ({
              ...series,
              highlightScope: {
                highlighted: "series",
                faded: "global",
              },
              valueFormatter,
            }))}
            grid={{ horizontal: true }}
            sx={{ width: "100%" }}
            {...chartSetting}
          />
        </CFGraphBox>
      </Grid>
    </Grid>
  );
}
export default AnimalAgGraph;
