// React
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// MUI
import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";

import ArrowCircleDownTwoToneIcon from "@mui/icons-material/ArrowCircleDownTwoTone";

import Tooltip from "@mui/material/Tooltip";

import CFTypography from "components/CFTypography";
import CFBox from "components/CFBox";
import CFInput from "components/CFInput";

import colors from "assets/theme/base/colors";
import CFButton from "components/CFButton";

import { post } from "utils/api";
import { popupError } from "utils/generic";
import { GET_FEEDS, UPDATE_FEEDS } from "constants/api";
import CFLookupPicker from "components/CroplandsComponents/CFLookupPicker/CFLookupPicker";
import CFLoading from "components/CFLoading";
import UserGuidePopup from "components/CFUserGuidePopup/UserGuidePopup";
import FeedCalculator from "./FeedCalculator";
import { getTextInput, getAdditiveUnits } from "./AnimalAgCommonFunc";

// Constants

export default function AnimalFeeds({
  id,
  yearValue,
  animalCategory,
  animalCategoryId,
  groupId,
  anagObject,
  anagObjectChanged,
  popFlag,
  setPopFlag,
  verifyDone,
}) {
  const dispatch = useDispatch();

  const { info } = colors;

  const empty = { id: 0, name: "select" };

  const [dmiCount, setDmiCount] = useState([]);
  const [crudeProteinCount, setCrudeProteinCount] = useState([]);
  const [ndfPercentCount, setNdfPercentCount] = useState([]);
  const [fatCount, setFatCount] = useState([]);
  const [ashCount, setAshCount] = useState([]);
  const [dosageCount, setDosageCount] = useState([]);

  const [calculatorOpen, setCalculatorOpen] = useState(false);

  const [feedName, setFeedName] = useState([]);
  const [populateFeeds, setPopulateFeeds] = useState(false);

  const [clearFeedsName, setClearFeedsName] = useState(false);
  const [saveSuccessMsg, setSaveSuccessMsg] = useState(false);

  const [enableFeedSave, setEnableFeedSave] = useState(false);

  const [loading, setLoading] = useState(false);
  const [additiveTypeList, setAdditiveTypeList] = useState([]);

  const defaultMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [monthsArr, setMonthsArr] = useState(defaultMonths);
  const setUnitDefaults = () => {
    const unitsMonthsArr = [];
    monthsArr.map((month) => unitsMonthsArr.push([`units${month}`]));
    const unitsNewObj = {};

    unitsMonthsArr.forEach((mon) => {
      unitsNewObj[mon] = "";
    });

    return unitsNewObj;
  };
  const [additiveUnits, setAdditiveUnits] = useState(setUnitDefaults);
  const setDefaults = () => {
    const addTypeMonthsArr = [];
    monthsArr.map((month) => addTypeMonthsArr.push([`additiveType${month}`]));
    const addTypeNewObj = {};

    addTypeMonthsArr.forEach((mon) => {
      addTypeNewObj[mon] = { id: 212450, name: "Select" };
    });

    return addTypeNewObj;
  };

  const [additiveTypeVal, setAdditiveTypeVal] = useState(setDefaults);

  const setAdditiveType = (mon, val) => {
    setAdditiveTypeVal({
      ...additiveTypeVal,
      [`additiveType${mon}`]: { id: val.id, name: val.name },
    });
    const addUnits = getAdditiveUnits(val.id);
    if (val.id === 215203) {
      setDosageCount({
        ...dosageCount,
        [`dosage${mon}`]: 0,
      });
    }

    setAdditiveUnits({ ...additiveUnits, [`units${mon}`]: addUnits });
  };

  const handleDmiChange = (e) => {
    setClearFeedsName(true);
    setDmiCount({
      ...dmiCount,
      [e.target.name]: e.target.value,
    });
  };

  function openFeedCalculator() {
    setCalculatorOpen(true);
    // window.location.href = "/managementEntry";
  }

  const feedExists = () => {
    let feedDataExists = false;
    const hasDmi = Object.entries(dmiCount).some((x) => x[1] > 0);
    const hasCp = Object.entries(crudeProteinCount).some((x) => x[1] > 0);
    const hasNdf = Object.entries(ndfPercentCount).some((x) => x[1] > 0);
    const hasEE = Object.entries(fatCount).some((x) => x[1] > 0);
    const hasAsh = Object.entries(ashCount).some((x) => x[1] > 0);
    if (animalCategoryId === 21262) {
      feedDataExists = hasDmi && hasCp && hasNdf && hasEE;
    } else if (animalCategoryId === 21260 || animalCategoryId === 21261) {
      feedDataExists = hasDmi && hasCp && hasEE && hasAsh;
    }
    setEnableFeedSave(feedDataExists);
  };
  const copyDmi = () => {
    if (monthsArr.length > 0) {
      setClearFeedsName(true);
      const janDmi = dmiCount[`dmi${monthsArr[0]}`];

      const dmiMonthsArr = [];
      monthsArr.map((month) => dmiMonthsArr.push([`dmi${month}`]));
      const dmiNewObj = {};
      dmiMonthsArr.forEach((mon) => {
        dmiNewObj[mon] = janDmi;
      });

      setDmiCount({ ...dmiCount, ...dmiNewObj });
    }
  };
  const handleCrudeProteinChange = (e) => {
    setClearFeedsName(true);
    const value = getTextInput(e.target.value);
    setCrudeProteinCount({
      ...crudeProteinCount,
      [e.target.name]: value,
    });
  };
  const copyCrudeProtein = () => {
    if (monthsArr.length > 0) {
      setClearFeedsName(true);
      const janCrudeProtein = getTextInput(
        crudeProteinCount[`crudeProtein${monthsArr[0]}`]
      );
      const crudeProteinMonthsArr = [];
      monthsArr.map((month) =>
        crudeProteinMonthsArr.push([`crudeProtein${month}`])
      );
      const crudeProteinNewObj = {};
      crudeProteinMonthsArr.forEach((mon) => {
        crudeProteinNewObj[mon] = janCrudeProtein;
      });

      setCrudeProteinCount({ ...crudeProteinCount, ...crudeProteinNewObj });
    }
  };
  const handleNdfPercentChange = (e) => {
    setClearFeedsName(true);
    const value = getTextInput(e.target.value);
    setNdfPercentCount({
      ...ndfPercentCount,
      [e.target.name]: value,
    });
  };
  const handlePopulateFeeds = () => {
    setClearFeedsName(false);
    setPopulateFeeds(true);
  };
  const copyNdfPercent = () => {
    if (monthsArr.length > 0) {
      setClearFeedsName(true);
      const janNdfPercent = getTextInput(
        ndfPercentCount[`ndfPercent${monthsArr[0]}`]
      );
      const ndfPercentMonthsArr = [];
      monthsArr.map((month) =>
        ndfPercentMonthsArr.push([`ndfPercent${month}`])
      );
      const ndfPercentNewObj = {};
      ndfPercentMonthsArr.forEach((mon) => {
        ndfPercentNewObj[mon] = janNdfPercent;
      });

      setNdfPercentCount({ ...ndfPercentCount, ...ndfPercentNewObj });
    }
  };
  const handleFatChange = (e) => {
    setClearFeedsName(true);
    const value = getTextInput(e.target.value);
    setFatCount({
      ...fatCount,
      [e.target.name]: value,
    });
  };
  const copyFat = () => {
    if (monthsArr.length > 0) {
      setClearFeedsName(true);
      const janFat = getTextInput(fatCount[`fat${monthsArr[0]}`]);
      const fatMonthsArr = [];
      monthsArr.map((month) => fatMonthsArr.push([`fat${month}`]));
      const fatNewObj = {};
      fatMonthsArr.forEach((mon) => {
        fatNewObj[mon] = janFat;
      });

      setFatCount({ ...fatCount, ...fatNewObj });
    }
  };

  const handleAshChange = (e) => {
    const value = getTextInput(e.target.value);
    setClearFeedsName(true);
    setAshCount({
      ...ashCount,
      [e.target.name]: value,
    });
  };

  const copyAshChange = () => {
    if (monthsArr.length > 0) {
      setClearFeedsName(true);
      const janAsh = getTextInput(ashCount[`ash${monthsArr[0]}`]);
      const ashMonthsArr = [];
      monthsArr.map((month) => ashMonthsArr.push([`ash${month}`]));
      const ashNewObj = {};
      ashMonthsArr.forEach((mon) => {
        ashNewObj[mon] = janAsh;
      });

      setAshCount({ ...ashCount, ...ashNewObj });
    }
  };

  const copyFeedAdditives = () => {
    if (monthsArr.length > 0) {
      const fdAdditiveNewObj = setDefaults();
      const additiveMonths = [];
      const dosageMonths = [];
      const unitsMonths = [];
      const unitsNewObj = {};
      const dosageNewObj = {};
      const janUnits = additiveUnits[`units${monthsArr[0]}`];
      monthsArr.map((month) => additiveMonths.push([`additiveType${month}`]));

      monthsArr.map((month) => unitsMonths.push([`units${month}`]));
      monthsArr.map((month) => dosageMonths.push([`dosage${month}`]));

      additiveMonths.forEach((mon) => {
        fdAdditiveNewObj[mon].id =
          additiveTypeVal[`additiveType${monthsArr[0]}`]?.id;
        fdAdditiveNewObj[mon].name =
          additiveTypeVal[`additiveType${monthsArr[0]}`]?.name;
      });

      setAdditiveTypeVal({ ...additiveTypeVal, ...fdAdditiveNewObj });

      unitsMonths.forEach((mon) => {
        unitsNewObj[mon] = janUnits;
      });

      setAdditiveUnits({ ...additiveUnits, ...unitsNewObj });
      if (additiveTypeVal[`additiveType${monthsArr[0]}`]?.id === 215203) {
        dosageMonths.forEach((mon) => {
          dosageNewObj[mon] = 0;
        });
        setDosageCount({ ...dosageCount, ...dosageNewObj });
      }
    }
  };
  const handleDosageChange = (e) => {
    setDosageCount({
      ...dosageCount,
      [e.target.name]: e.target.value,
    });
  };
  const copyDosages = () => {
    if (monthsArr.length > 0) {
      const janDosageCount = getTextInput(dosageCount[`dosage${monthsArr[0]}`]);
      const dosageMonthsArr = [];
      monthsArr.map((month) => dosageMonthsArr.push([`dosage${month}`]));
      const dosagnewObj = {};
      dosageMonthsArr.forEach((mon) => {
        dosagnewObj[mon] = janDosageCount;
      });

      setDosageCount({ ...dosageCount, ...dosagnewObj });
    }
  };
  const fetchFeedFactors = () => {
    setLoading(true);

    const popObj = {
      popid: id,
    };
    post(GET_FEEDS, popObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const json = res.data;
          const dmiMonthsJson = [];
          const crudeProteinMonthsJson = [];
          const ndfPercentMonthsJson = [];
          const fatMonthsJson = [];
          const ashMonthsJson = [];
          const addtveTypeMonthsJson = [];
          const dosageMonthsJson = [];
          const unitsMonthsJson = [];
          const feedNamesJson = [];
          const dmiNewJson = {};
          const crudeProteinNewJson = {};
          const ndfPercentNewJson = {};
          const fatNewJson = {};
          const ashNewJson = {};
          const feedNamesNewJson = {};
          const addTypeNewJson = setDefaults();
          const unitsNewJson = setUnitDefaults();
          const dosageNewJson = {};
          let dryMatterExists = false;
          let crudeProteinExists = false;
          let neuDetFiberExists = false;
          let etherExtractExists = false;
          let ashValExists = false;

          setMonthsArr(res.data.popMonths);

          monthsArr.map((month) => dmiMonthsJson.push([`dmi${month}`]));
          monthsArr.map((month) =>
            crudeProteinMonthsJson.push([`crudeProtein${month}`])
          );
          monthsArr.map((month) =>
            ndfPercentMonthsJson.push([`ndfPercent${month}`])
          );
          monthsArr.map((month) => fatMonthsJson.push([`fat${month}`]));
          monthsArr.map((month) => ashMonthsJson.push([`ash${month}`]));
          monthsArr.map((month) =>
            addtveTypeMonthsJson.push([`additiveType${month}`])
          );
          monthsArr.map((month) => dosageMonthsJson.push([`dosage${month}`]));
          monthsArr.map((month) => unitsMonthsJson.push([`units${month}`]));

          dmiMonthsJson.forEach((mon) => {
            const dmiMonthName = mon.toString().replace("dmi", "");
            dmiNewJson[mon] = json.fdTableViewData.find(
              (dmi) => dmi.month.toString() === dmiMonthName.toString()
            ).dmi;
          });
          setDmiCount({ ...dmiCount, ...dmiNewJson });
          dryMatterExists = json.fdTableViewData.some((el) => el.dmi > 0);

          crudeProteinMonthsJson.forEach((mon) => {
            const crudeProteinMonthName = mon
              .toString()
              .replace("crudeProtein", "");
            crudeProteinNewJson[mon] = json.fdTableViewData.find(
              (cp) => cp.month.toString() === crudeProteinMonthName.toString()
            ).cp;
          });
          setCrudeProteinCount({
            ...crudeProteinCount,
            ...crudeProteinNewJson,
          });
          crudeProteinExists = json.fdTableViewData.some((el) => el.cp > 0);
          ndfPercentMonthsJson.forEach((mon) => {
            const ndfPercentMonthName = mon
              .toString()
              .replace("ndfPercent", "");
            ndfPercentNewJson[mon] = json.fdTableViewData.find(
              (ndf) => ndf.month.toString() === ndfPercentMonthName.toString()
            ).ndf;
          });

          setNdfPercentCount({ ...ndfPercentCount, ...ndfPercentNewJson });
          neuDetFiberExists = json.fdTableViewData.some((el) => el.ndf > 0);
          fatMonthsJson.forEach((mon) => {
            const fatMonthName = mon.toString().replace("fat", "");
            fatNewJson[mon] = json.fdTableViewData.find(
              (fat) => fat.month.toString() === fatMonthName.toString()
            ).ee;
          });
          setFatCount({ ...fatCount, ...fatNewJson });
          etherExtractExists = json.fdTableViewData.some((el) => el.ee > 0);
          ashMonthsJson.forEach((mon) => {
            const ashMonthName = mon.toString().replace("ash", "");
            ashNewJson[mon] = json.fdTableViewData.find(
              (ash) => ash.month.toString() === ashMonthName.toString()
            ).ash;
          });
          setAshCount({
            ...ashCount,
            ...ashNewJson,
          });
          ashValExists = json.fdTableViewData.some((el) => el.ash > 0);
          monthsArr.map((month) => feedNamesJson.push([`feedLabel${month}`]));
          feedNamesJson.forEach((mon) => {
            const feedMonthName = mon.toString().replace("feedLabel", "");
            feedNamesNewJson[mon] = json.fdTableViewData.find(
              (fdName) => fdName.month.toString() === feedMonthName.toString()
            ).feedName;
          });
          setAdditiveTypeList(json.additiveList);
          setFeedName({
            ...feedName,
            ...feedNamesNewJson,
          });

          addtveTypeMonthsJson.forEach((mon) => {
            const addTypeMonthName = mon.toString().replace("additiveType", "");
            const addId = json.fdTableViewData.find(
              (addtve) =>
                addtve.month.toString() === addTypeMonthName.toString()
            ).additiveTypeId;
            addTypeNewJson[mon].id = addId;
            unitsNewJson[`units${addTypeMonthName}`] = getAdditiveUnits(addId);
            addTypeNewJson[mon].name = json.fdTableViewData.find(
              (addtve) =>
                addtve.month.toString() === addTypeMonthName.toString()
            ).additiveTypeName;
          });

          setAdditiveTypeVal({ ...additiveTypeVal, ...addTypeNewJson });

          setAdditiveUnits({ ...additiveUnits, ...unitsNewJson });

          dosageMonthsJson.forEach((mon) => {
            const dosageMonthName = mon.toString().replace("dosage", "");
            dosageNewJson[mon] = json.fdTableViewData.find(
              (dsge) => dsge.month.toString() === dosageMonthName.toString()
            ).dosage;
          });

          setDosageCount({
            ...dosageCount,
            ...dosageNewJson,
          });

          if (animalCategoryId === 21262) {
            if (
              dryMatterExists &&
              crudeProteinExists &&
              neuDetFiberExists &&
              etherExtractExists
            ) {
              verifyDone("green");
            } else {
              verifyDone("grey");
            }
          } else if (animalCategoryId === 21260 || animalCategoryId === 21261) {
            if (
              dryMatterExists &&
              crudeProteinExists &&
              etherExtractExists &&
              ashValExists
            ) {
              verifyDone("green");
            } else {
              verifyDone("grey");
            }
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const updateFeeds = () => {
    setLoading(true);

    const animalTypesObj = {
      dmiObj: dmiCount,
      crudeProteinObj: crudeProteinCount,
      ndfPercentObj: ndfPercentCount,
      fatObj: fatCount,
      ashObj: ashCount,
      fdAddtveObj: additiveTypeVal,
      dsgeObj: dosageCount,
      feedsName: clearFeedsName,
      popid: id,
    };
    post(UPDATE_FEEDS, animalTypesObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          setSaveSuccessMsg(true);

          verifyDone("green");
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    setPopulateFeeds(false);
    fetchFeedFactors();
  }, [populateFeeds]);

  useEffect(() => {
    setPopFlag(false);
    fetchFeedFactors();
  }, [popFlag]);

  useEffect(() => {
    feedExists();
  }, [dmiCount, crudeProteinCount, ndfPercentCount, fatCount, ashCount]);

  return (
    <CFBox width="100%">
      {loading && <CFLoading local />}
      {/* Main form */}
      <CFBox width="100%">
        {monthsArr.length > 0 && (
          <Grid container direction="row" xs={12} sm={12} md={12}>
            <Grid item xs={12} sm={12} md={12}>
              <CFTypography
                sx={{
                  fontSize: "0.8em",

                  color: info.dullFocus,

                  marginBottom: "0.7em",
                }}
              >
                Enter the typical monthly feed characteristics for this animal
                category. If you do not know the required feed characteristics
                use the feed calculator. Use the arrows to copy data across
                months.
              </CFTypography>
            </Grid>
          </Grid>
        )}
        {monthsArr.length > 0 && (
          <Grid container direction="row" xs={12} sm={12} md={12}>
            <Grid item xs={2} sm={2} md={2}>
              <CFButton
                sx={{
                  backgroundColor: colors.info.dullFocus,
                  color: "white.main",
                  float: "left",
                }}
                onClick={() => openFeedCalculator()}
              >
                Feed Calculator
              </CFButton>
            </Grid>
          </Grid>
        )}
        <Grid container direction="row" xs={12} sm={12} md={12}>
          <Grid container direction="row">
            <Grid item xs={1}>
              <Stack direction="column">
                {monthsArr.map((month) => (
                  <CFBox
                    sx={{
                      backgroundColor: colors.background.header,
                      padding: "0.43em",
                      marginTop: month === monthsArr[0] ? "3em" : "0.78em",
                      boxShadow: "0px 1px 3px 0px #bbb",
                      marginBottom: ".75em",
                    }}
                  >
                    <CFTypography
                      sx={{
                        fontSize: "15px",
                        color: info.dullFocus,
                        fontWeight: "bold",
                        padding: "10px",
                      }}
                    >
                      {month}
                    </CFTypography>
                  </CFBox>
                ))}
              </Stack>
            </Grid>

            {monthsArr.length > 0 &&
            (animalCategoryId === 21260 ||
              animalCategoryId === 21261 ||
              animalCategoryId === 21262) ? (
              <Grid item xs={1.5} sm={1.5} md={1.5}>
                <Stack direction="column" alignItems="center" gap={1}>
                  <CFBox style={{ display: "flex" }}>
                    <CFTypography
                      sx={{
                        fontSize: "12px",
                        color: info.dullFocus,
                        fontWeight: "bold",
                        marginBottom: "2em",
                        marginTop: "0.5em",
                      }}
                    >
                      Dry Matter Intake
                    </CFTypography>
                    <Tooltip title="Copy data across months">
                      <ArrowCircleDownTwoToneIcon
                        onClick={() => copyDmi()}
                        id="copyDMI"
                        color="primary"
                        fontSize="medium"
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </CFBox>

                  {monthsArr.map((month) => (
                    <Stack direction="row" alignItems="center" gap={1}>
                      <CFBox
                        style={{ width: "6em", borderColor: "black" }}
                        sx={{
                          backgroundColor: colors.background.header,
                          padding: "10px",
                          marginLeft: "1em",
                          display: "flex",
                          marginBottom: "1em",
                        }}
                      >
                        <CFInput
                          sx={{
                            fieldset: {
                              borderColor: info.dullFocus,
                              borderStyle: "solid",
                              borderWidth: "2px",
                              marginBottom: "0.5em",
                            },
                          }}
                          style={{ width: "4em" }}
                          color="primary"
                          id={`dmiCount${month}`}
                          name={`dmi${month}`}
                          defaultValue="0"
                          value={dmiCount[`dmi${month}`]}
                          onChange={handleDmiChange}
                          placeholder=""
                          // inputProps={{ disabled: value }}
                          fullWidth
                        />
                        <CFTypography
                          sx={{
                            fontSize: "8px",
                            color: info.dullFocus,
                            fontWeight: "bold",
                            marginBottom: "0.5em",
                            marginTop: "0.5em",
                          }}
                        >
                          (lbs/day)
                        </CFTypography>
                      </CFBox>
                    </Stack>
                  ))}
                </Stack>
              </Grid>
            ) : null}

            {monthsArr.length > 0 &&
            (animalCategoryId === 21260 ||
              animalCategoryId === 21261 ||
              animalCategoryId === 21262) ? (
              <Grid item xs={1.5} sm={1.5} md={1.5}>
                <Stack direction="column" alignItems="center" gap={1}>
                  <CFBox style={{ display: "flex" }}>
                    <CFTypography
                      sx={{
                        fontSize: "12px",

                        color: info.dullFocus,
                        fontWeight: "bold",

                        marginBottom: "2em",
                        marginTop: "0.5em",
                      }}
                    >
                      Crude Protein
                    </CFTypography>
                    <Tooltip title="Copy data across months">
                      <ArrowCircleDownTwoToneIcon
                        onClick={() => copyCrudeProtein()}
                        id="copyCP"
                        color="primary"
                        fontSize="medium"
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </CFBox>

                  {monthsArr.map((month) => (
                    <Stack direction="row" alignItems="center" gap={1}>
                      <CFBox
                        style={{ width: "6em" }}
                        sx={{
                          backgroundColor: colors.background.header,
                          padding: "10px",
                          marginLeft: "1em",
                          display: "flex",
                          marginBottom: "1em",
                        }}
                      >
                        <CFInput
                          sx={{
                            fieldset: {
                              borderColor: info.dullFocus,
                              borderStyle: "solid",
                              borderWidth: "2px",
                            },
                          }}
                          style={{ width: "4em" }}
                          type="number"
                          InputProps={{
                            inputProps: { min: 0, max: 100 },
                          }}
                          color="primary"
                          id={`crudeProteinCount${month}`}
                          name={`crudeProtein${month}`}
                          value={crudeProteinCount[`crudeProtein${month}`]}
                          defaultValue="0"
                          onChange={handleCrudeProteinChange}
                          // inputProps={{ disabled: value }}
                          placeholder=""
                          fullWidth
                        />
                        <CFTypography
                          sx={{
                            fontSize: "8px",
                            color: info.dullFocus,
                            fontWeight: "bold",
                            marginBottom: "0.5em",
                            marginTop: "0.5em",
                            padding: "3px",
                          }}
                        >
                          (%)
                        </CFTypography>
                      </CFBox>
                    </Stack>
                  ))}
                </Stack>
              </Grid>
            ) : null}

            {monthsArr.length > 0 && animalCategoryId === 21262 ? (
              <Grid item xs={1.5} sm={1.5} md={1.5}>
                <Stack direction="column" alignItems="center" gap={1}>
                  <CFBox style={{ display: "flex" }}>
                    <CFTypography
                      sx={{
                        fontSize: "12px",
                        color: info.dullFocus,
                        fontWeight: "bold",
                        marginBottom: "0.5em",
                        marginTop: "0.5em",
                      }}
                    >
                      Neutral Detergent Fiber (NDF)
                    </CFTypography>

                    <Tooltip title="Copy data across months">
                      <ArrowCircleDownTwoToneIcon
                        onClick={() => copyNdfPercent()}
                        id="copyNDF"
                        color="primary"
                        fontSize="medium"
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </CFBox>
                  {monthsArr.map((month) => (
                    <Stack direction="row" alignItems="center" gap={1}>
                      <CFBox
                        style={{ width: "6em" }}
                        sx={{
                          backgroundColor: colors.background.header,
                          padding: "10px",
                          marginLeft: "1em",
                          display: "flex",
                          marginBottom: "1em",
                        }}
                      >
                        <CFInput
                          sx={{
                            padding: "10px",
                            fieldset: {
                              borderColor: info.dullFocus,
                              borderStyle: "solid",
                              borderWidth: "2px",
                            },
                          }}
                          style={{ width: "4em" }}
                          type="number"
                          InputProps={{
                            inputProps: { min: 0, max: 100 },
                          }}
                          color="primary"
                          id={`ndfPercentCount${month}`}
                          name={`ndfPercent${month}`}
                          defaultValue="0"
                          value={ndfPercentCount[`ndfPercent${month}`]}
                          onChange={handleNdfPercentChange}
                          // inputProps={{ disabled: value }}
                          placeholder=""
                          fullWidth
                        />
                        <CFTypography
                          sx={{
                            fontSize: "8px",
                            color: info.dullFocus,
                            fontWeight: "bold",
                            marginBottom: "0.5em",
                            marginTop: "0.5em",
                            padding: "3px",
                          }}
                        >
                          (%)
                        </CFTypography>
                      </CFBox>
                    </Stack>
                  ))}
                </Stack>
              </Grid>
            ) : null}

            {monthsArr.length > 0 && (
              <Grid item xs={1.5} sm={1.5} md={1.5}>
                <Stack direction="column" alignItems="center" gap={1}>
                  <CFBox style={{ display: "flex" }}>
                    <CFTypography
                      sx={{
                        fontSize: "12px",
                        color: info.dullFocus,
                        fontWeight: "bold",

                        marginBottom: "2em",
                        marginTop: "0.5em",
                      }}
                    >
                      Fat (EE)
                    </CFTypography>
                    <Tooltip title="Copy data across months">
                      <ArrowCircleDownTwoToneIcon
                        onClick={() => copyFat()}
                        id="copyfat"
                        color="primary"
                        fontSize="medium"
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </CFBox>

                  {monthsArr.map((month) => (
                    <Stack direction="row" alignItems="center" gap={1}>
                      <CFBox
                        style={{ width: "6em" }}
                        sx={{
                          backgroundColor: colors.background.header,
                          padding: "10px",
                          marginLeft: "1em",
                          display: "flex",
                          marginBottom: "1em",
                        }}
                      >
                        <CFInput
                          sx={{
                            padding: "10px",
                            fieldset: {
                              borderColor: info.dullFocus,
                              borderStyle: "solid",
                              borderWidth: "2px",
                            },
                          }}
                          style={{ width: "4em" }}
                          type="number"
                          InputProps={{
                            inputProps: { min: 0, max: 100 },
                          }}
                          color="primary"
                          id={`fatCount${month}`}
                          name={`fat${month}`}
                          defaultValue="0"
                          value={fatCount[`fat${month}`]}
                          onChange={handleFatChange}
                          placeholder=""
                          // inputProps={{ disabled: value }}
                          fullWidth
                        />
                        <CFTypography
                          sx={{
                            fontSize: "8px",
                            color: info.dullFocus,
                            fontWeight: "bold",
                            marginBottom: "0.5em",
                            marginTop: "0.5em",
                            padding: "3px",
                          }}
                        >
                          (%)
                        </CFTypography>
                      </CFBox>
                    </Stack>
                  ))}
                </Stack>
              </Grid>
            )}

            {monthsArr.length > 0 &&
            (animalCategoryId === 21260 || animalCategoryId === 21261) ? (
              <Grid item xs={1.5} sm={1.5} md={1.5}>
                <Stack direction="column" alignItems="center" gap={1}>
                  <CFBox style={{ display: "flex" }}>
                    <CFTypography
                      sx={{
                        fontSize: "12px",
                        color: info.dullFocus,
                        fontWeight: "bold",

                        marginBottom: "2em",
                        marginTop: "0.5em",
                      }}
                    >
                      Ash
                    </CFTypography>
                    <Tooltip title="Copy data across months">
                      <ArrowCircleDownTwoToneIcon
                        onClick={() => copyAshChange()}
                        id="copyfat"
                        color="primary"
                        fontSize="medium"
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </CFBox>

                  {monthsArr.map((month) => (
                    <Stack direction="row" alignItems="center" gap={1}>
                      <CFBox
                        style={{ width: "6em" }}
                        sx={{
                          backgroundColor: colors.background.header,
                          padding: "10px",
                          marginLeft: "1em",
                          display: "flex",
                          marginBottom: "1em",
                        }}
                      >
                        <CFInput
                          sx={{
                            padding: "10px",
                            fieldset: {
                              borderColor: info.dullFocus,
                              borderStyle: "solid",
                              borderWidth: "2px",
                            },
                          }}
                          style={{ width: "4em" }}
                          type="number"
                          InputProps={{
                            inputProps: { min: 0, max: 100 },
                          }}
                          color="primary"
                          id={`ashCount${month}`}
                          name={`ash${month}`}
                          defaultValue="0"
                          value={ashCount[`ash${month}`]}
                          onChange={handleAshChange}
                          // inputProps={{ disabled: value }}
                          placeholder=""
                          fullWidth
                        />
                        <CFTypography
                          sx={{
                            fontSize: "8px",
                            color: info.dullFocus,
                            fontWeight: "bold",
                            marginBottom: "0.5em",
                            marginTop: "0.5em",
                            padding: "3px",
                          }}
                        >
                          (%)
                        </CFTypography>
                      </CFBox>
                    </Stack>
                  ))}
                </Stack>
              </Grid>
            ) : null}
            {monthsArr.length > 0 && animalCategoryId === 21262 ? (
              <Grid item xs={2.5} sm={2.5} md={2.5}>
                <Stack direction="column" alignItems="center" gap={1}>
                  <CFBox style={{ display: "flex" }}>
                    <CFTypography
                      sx={{
                        fontSize: "12px",
                        color: info.dullFocus,
                        fontWeight: "bold",
                        marginBottom: "2em",
                        marginTop: "0.5em",
                      }}
                    >
                      Feed Additive Type
                    </CFTypography>
                    <Tooltip title="Copy data across months">
                      <ArrowCircleDownTwoToneIcon
                        onClick={() => copyFeedAdditives()}
                        id="copyAddtves"
                        color="primary"
                        fontSize="medium"
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                    <UserGuidePopup
                      content="Feed additive impacts to emissions should not be summed as there are not
      sufficient data to conclude if combined practices would be effective. Feed
      additive impacts to emissions past the duration of the literature/studies
      cited (60-180 days) is unknown; therefore, emission reductions should not
      be considered in perpetuity. While studies exist showing the potential to
      reduce emissions, it is important to note that the drugs mentioned do not
      claim, nor may they claim, emissions reductions. Use of nitrates can
      contribute to higher probability of animal fatalities and should only be
      done under the supervision of a trained and certified nutritionist."
                      userGuideLabel="Feed Additives"
                      variant="h4"
                      sx={{ padding: ".1em" }}
                    />
                  </CFBox>
                  {monthsArr.map((month) => (
                    <Stack direction="row" alignItems="center" gap={1}>
                      <CFBox
                        sx={{
                          backgroundColor: colors.background.header,
                          padding: "10px",
                          marginLeft: "1em",
                          display: "flex",
                          marginBottom: "1.05em",
                        }}
                      >
                        <CFLookupPicker
                          key={`additiveTypeVal${month}`}
                          name={`additiveType${month}`}
                          // required
                          label="Select Additive Type"
                          style={{
                            width: "9em",
                          }}
                          value={additiveTypeVal[`additiveType${month}`]}
                          onChange={(event, newValue) => {
                            setAdditiveType(month, newValue);
                          }}
                          options={additiveTypeList}
                        />
                      </CFBox>
                    </Stack>
                  ))}
                </Stack>
              </Grid>
            ) : null}
            {monthsArr.length > 0 && animalCategoryId === 21262 ? (
              <Grid item xs={2.2} sm={2.2} md={2.2}>
                <Stack direction="column" alignItems="center" gap={1}>
                  <CFBox style={{ display: "flex" }}>
                    <CFTypography
                      sx={{
                        fontSize: "12px",
                        color: info.dullFocus,
                        fontWeight: "bold",
                        marginBottom: "2em",

                        marginTop: "0.5em",
                      }}
                    >
                      Dosage
                    </CFTypography>
                    <Tooltip title="Copy data across months">
                      <ArrowCircleDownTwoToneIcon
                        onClick={() => copyDosages()}
                        id="copyDosage"
                        color="primary"
                        fontSize="medium"
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </CFBox>
                  {monthsArr.map((month) => (
                    <Stack direction="row" alignItems="center" gap={1}>
                      <CFBox
                        sx={{
                          backgroundColor: colors.background.header,
                          padding: "10px",
                          marginLeft: "1em",
                          display: "flex",
                          marginBottom: "1em",
                        }}
                        style={{ width: "9.5em" }}
                      >
                        <CFInput
                          sx={{
                            padding: "10px",
                            fieldset: {
                              borderColor: info.dullFocus,
                              borderStyle: "solid",
                              borderWidth: "2px",
                            },
                          }}
                          style={{ width: "6em" }}
                          type="number"
                          InputProps={{
                            inputProps: { min: 0, max: 100 },
                          }}
                          color="primary"
                          id={`dosageCount${month}`}
                          name={`dosage${month}`}
                          defaultValue="0"
                          value={dosageCount[`dosage${month}`]}
                          onChange={handleDosageChange}
                          // inputProps={{ disabled: value }}
                          disabled={
                            additiveTypeVal[`additiveType${month}`]?.id ===
                            215203
                          }
                          placeholder=""
                          fullWidth
                        />
                        <CFTypography
                          sx={{
                            fontSize: "8px",
                            color: info.dullFocus,
                            fontWeight: "bold",
                            marginBottom: "0.5em",
                            marginTop: "0.5em",
                            padding: "3px",
                          }}
                          id={`additiveUnits${month}`}
                          key={`units${month}`}
                        >
                          {additiveUnits[`units${month}`]}
                        </CFTypography>
                      </CFBox>
                    </Stack>
                  ))}
                </Stack>
              </Grid>
            ) : null}

            <Grid container py={2} px={2} justifyContent="space-around">
              <Grid item xs={12} sm={12} md={12}>
                <CFButton
                  type="submit"
                  color="progress"
                  sx={{
                    float: "right",
                  }}
                  onClick={() => {
                    updateFeeds();
                  }}
                  disabled={!enableFeedSave}
                >
                  Save
                </CFButton>
              </Grid>
            </Grid>
            {saveSuccessMsg && (
              <Grid container justifyContent="space-around">
                <Grid item xs={2} sm={2} md={2} alignItems="center">
                  <CFBox id="feedSuccessMessage">
                    <CFTypography
                      sx={{
                        fontSize: "15px",
                        color: "green",

                        marginBottom: "0.5em",
                        marginTop: "0.5em",
                      }}
                    >
                      Save was successful.
                    </CFTypography>
                  </CFBox>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        <FeedCalculator
          isOpen={calculatorOpen}
          setIsOpen={setCalculatorOpen}
          // fetchAllProjects={fetchAllProjects}

          Id={id}
          YearVal={yearValue}
          AnimalCat={animalCategory}
          AnimalCatId={animalCategoryId}
          AnimalGroupId={groupId}
          animalObj={anagObject}
          allMonths={monthsArr}
          populateFeedsClicked={handlePopulateFeeds}
        />
      </CFBox>
    </CFBox>
  );
}
